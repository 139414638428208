// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {apiUsers} from "../../Api/_ApiUsers";
import Grid from "@material-ui/core/Grid";
import { Box, Flex } from 'reflexbox'
import {Button, Checkbox} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import moment from "moment";
import { MenuItem, Select } from '@material-ui/core';

import ErrorMessage from "../../components/100Include/ErrorMessage";
import {getRoleDisplay} from "../../utils/Utility";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import {getUserInfo} from "../../Redux/Action/authAction";
import {CacheService} from "../../utils/CacheService";
import { apiAuth } from "../../Api/ApiAuth";
import referFriendsIcon from '../../images/refer-friends.png';
import question from '../../images/icons8-question-67.png';
import backIcon from "../../images/back.png";
import { WEBSITE_URL } from '../../config';
import { handleReferral } from '../../utils/Referral';
import '../../css/00Home/_profile.scss';
import TandC from '../../components/TandC';

const engMarketing = <div>
                        I would like to receive Marketing Information (TBC) with&nbsp;
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className='hyperlink'>Link</a>
                    </div>

const cnMarketing = <div>
                        我想通過<a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className='hyperlink'>鏈接</a>接受營銷信息(TBC)
                    </div>

class Profile extends Component {

    state = {
        roles: [],
        isEdit: false,
        user: {
            username: '',
            display_name: '',
            legal_full_name: '',
            nickname: '',
            phone: '',
            policy_number: '',
            referal_code: '',
            email: '',
            profile_image: null,
            password: '',
            re_password: '',
            receive_marketing_information: null,
            first_four_digit_id: '',
            age: '',
        },
        agreeTerms: false,
        files: [],
        fileUpload: null,
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
        openChangePwdDialog: false,
        roleValidateMsg: null,
        page: this.props.page,
        mobileNumberError: false,
        policyNumberError: false,
        emailAddressError: false,
        nicknameError: false,
        referalCodeError: false,
        agreeTermsError: false,
    };

    componentDidMount() {
        if (this.props.auth && this.props.auth.userInfo) {
            this.setState({isEdit: true})
            this.getUserDetail();
        }
        if (this.props.page === 'register') {
            let params = (new URL(document.location)).searchParams;
            let referralCode = params.get('referralCode');
            if (referralCode) {
                this.setState({user: {...this.state.user, referal_code: referralCode}})
            }
        }
    }

    getTitle = (title) => {
        if (title) {
            switch (title) {
                case 'edit-profile': {
                    return 'editProfile';
                }
                case 'register': {
                    return 'register'
                }
                default: {
                    return '';
                }
            }     
        }
    }
    
    getUserDetail = () => {
        const {t, i18n} = this.props;
        let params = {
            // $expand: "profile_image"
        }
        apiUsers.getUserDetail(this.props.auth.userInfo.username, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    const userDetail = obj.data;
                    this.props.setBreadcrumbP({
                        breadcrumbData: [{
                            title: t('UserManagement:profile'),
                            link: '/' + i18n.language + '/profile'
                        }]
                    });
                    this.setState({
                        user: this.prepareData(userDetail)
                    });
                }
            } else {
                // this.props.history.push('/' + i18n.language + '/login');
            }
        })
    }

    prepareData = data => {
        const item = {...data};
        item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
        item.role_display = getRoleDisplay(item.role, item.access_rights);
        item.password = 'password'
        item.re_password = 'password'
        return item;
    };
    // BUTTON FUNCTION

    handleCloseSnackbar = () => {
        this.setState({openSnackbar: false});
    };

    back = () => {
        const { history, i18n, auth } = this.props;
        if (auth.auth) {
            history.push('/' + i18n.language + '/index');
        } else {
            history.push('/' + i18n.language + '/landing');
        }
    }

    eCb = (obj) => {
        // console.log("eCb : ", obj);
    }

    _updateUserAsync = (value) => {
        let regex = /^\s+|\s+$/g;
        let updateUserBody = {
            "legal_full_name": value.legal_full_name,
            "nickname": value.nickname,
            "phone": value.phone,
            "receive_marketing_information": value.receive_marketing_information,
            "username": value.phone.replace(regex, ''),
            "email": value.email.replace(regex, ''),
        }
        this.callAPIUpdateUser(this.props.auth.userInfo.username, updateUserBody);
    }

    callAPIUpdateUser = (email, body) => {
        const { i18n, history } = this.props;
        apiUsers.updateUser(email, body).then(obj => {
            if (obj.status === 200) {
                this._getCurrentUserInformation();
                setTimeout(() => {
                    history.push('/' + i18n.language + `/index`);
                }, 1000)
            } else {
                this.setState({MessageContent: obj.data.error ? obj.data.error : 'Something went wrong, please try again.'})
            }
        });
    }

    _getCurrentUserInformation = () => {
        const params = null;
        apiAuth.getCurrentUserInformation(params).then(obj => {
            if (obj) {
                this.props.getUserInfoP(obj.data);
                CacheService.saveProfileData(obj.data)
            }
        })
    }

    _submitUserForm = (value) => {
        const { page } = this.state;
        const { t } = this.props;
        if (page === 'register') {
            if (typeof (value) !== 'undefined') {
                if (!value.agreeTerms) {
                    this.setState({ agreeTermsError: true });
                    // alert(t("UserManagement:termsAndConditionsMustBeAgreedBeforeProceeding"))
                } else {
                    let age = value.age;
                    if (age === t(`UserManagement:64AndAbove`)) {
                        age = 64;
                    } else if (age === t(`UserManagement:below50`)) {
                        age = 49;
                    }
                    value.age = age;
                    apiAuth.getClientCredentials().then((res) => {
                        this._register(value, res.data.access_token);
                    })
                }
            }
        } else {
            if (this.state.isEdit) {
                this._updateUserAsync(value);
            }
        }
    }

    filterErrorMessage = (errorMessage) => {
        if (errorMessage.includes('policyNumberHasAlreadyBeenUsedInOtherAccount')) {
            this.setState({ policyNumberError: true });
        } else if (errorMessage.includes('mobileNoHasAlreadyBeenUsedInOtherAccount')) {
            this.setState({ mobileNumberError: true });
        } else if (errorMessage.includes('emailAddressHasAlreadyBeenUsedInOtherAccount')) {
            this.setState({ emailAddressError: true });
        } else if (errorMessage.includes('nicknameHasAlreadyBeenUsedInOtherOccount')) {
            this.setState({ nicknameError: true });
        } else if (errorMessage.includes('referalCodeEnteredIsNotRegistered')) {
            this.setState({ referalCodeError: true });
        }
    }

    clearError = () => {
        this.setState({
            policyNumberError: false,
            mobileNumberError: false,
            emailAddressError: false,
            nicknameError: false,
            referalCodeError: false,
            agreeTermsError: false,
            MessageContent: '',
        });
    }

    _register = (values, access_token) => {
        const { i18n, history, t } = this.props;
        const cb = (obj) => {
            // console.log("cb : ", obj);

            switch (obj.status) {

                case 500:
                    window.scrollTo(0, 0);
                    this.setState({ MessageContent: obj.body.error ? t(`UserManagement:${obj.body.error}`) : 'Something went wrong, please try again.' });
                    this.filterErrorMessage(obj.body.error);
                    break;

                case 201:
                    history.push('/' + i18n.language + `/register-success`, { username: values.phone });
                    break;

                default: console.log('what');
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const body = {
            username: values.email,
            legal_full_name: values.legal_full_name,
            age: values.age,
            nickname: values.nickname,
            phone: values.phone,
            password: values.password,
            email: values.email,
            policy_number: values.policy_number,
            referal_code: values.referal_code ? values.referal_code : null,
            receive_marketing_information: values.receive_marketing_information,
            first_four_digit_id: values.first_four_digit_id
        }
        apiAuth.register(body, access_token, cb, eCb);
    }

    handleChange = event => {
        this.setState({
            roles: event.target.value,
            roleValidateMsg: event.target.value.length === 0 ? 'Role is required' : null
        });
    };

    // CHANGE PASSWORD
    openChangePwd = () => {
        this.setState({openChangePwdDialog: true});
    };
    handleCloseChangePwd = () => {
        this.setState({openChangePwdDialog: false});
    };

    _submitChangePwdForm = (value) => {
        if (value.role === 1) {
            const body = {"new_password": value.new_password}
            apiUsers.changePassword(this.state.user.username, body).then(obj => {
                if (obj.status === 204) {
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: 'Change password successfully.',
                        variantSnackbar: 'success',
                        openChangePwdDialog: false
                    })
                } else {
                    const msg = obj && obj.data.error ? obj.data.error : 'Cannot change user password';
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: msg,
                        variantSnackbar: 'error'
                    })
                }
            });
        } else {
            const body = {
                "old": {
                    "password": value.old_password
                },
                "new": {
                    "password": value.new_password
                }
            };
            apiUsers.changePasswordUser(this.state.user.username, body).then(obj => {
                if (obj.status === 204) {
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: 'Change password successfully.',
                        variantSnackbar: 'success',
                        openChangePwdDialog: false
                    })
                } else {
                    const msg = obj && obj.data.error.endsWith("is not authorized.") ? 'Incorrect old password, please try again.' : 'Cannot change user password';
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: msg,
                        variantSnackbar: 'error'
                    })
                }
            });
        }
    }

    // FORM CONFIG
    formConfiguration = ({values, errors, touched, handleChange, setFieldValue}) => {
        const { t, i18n } = this.props;
        const { page, MessageContent, policyNumberError, mobileNumberError, emailAddressError, nicknameError, referalCodeError, agreeTermsError } = this.state;
        const marketing = i18n.language === 'en-US' ? engMarketing : cnMarketing;
        const isChinese = i18n.language === 'zh-HK';
        return values && (
            <Form id="userForm" onChange={this.clearError}>
                <Grid container xm={12} alignItems="center">
                    {/* {MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={MessageContent}
                        />
                    </Grid>
                    } */}

                    <Grid container direction="column" justify="center" alignItems="center" className="mt20">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:legalFullName")}
                        </Grid>
                        <Grid item xs={12} className={errors.legal_full_name && touched.legal_full_name ? "mpf-error-form-item" : "mpf-form-item"}>
                            <Grid item xs={12} style={{ fontSize: '18px' }}>
                                {page === 'register' ? <Field name="legal_full_name" type="text" placeholder="" maxLength="100" /> : values.legal_full_name}
                            </Grid>
                            <Grid item xs={12}>
                                {errors.legal_full_name && touched.legal_full_name && <ErrorMessage message={errors.legal_full_name} />}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:nickname")}
                        </Grid>
                        <Grid item xs={12} className={errors.legal_full_name && touched.legal_full_name ? "mpf-error-form-item" : "mpf-form-item"}>
                            <Grid item xs={12}>
                                <Field name="nickname" type="text" placeholder="" maxLength="20"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.nickname && touched.nickname && <ErrorMessage message={errors.nickname} />}
                            </Grid>
                            {MessageContent && nicknameError && <Grid item xs={12}>
                                <ErrorMessage message={MessageContent} />
                            </Grid>}
                        </Grid>
                    </Grid>

                    {page === 'register' && <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:password")}
                        </Grid>
                        <Grid item xs={12} className={errors.password && touched.password ? "mpf-error-form-item" : "mpf-form-item"}>
                            <Grid item xs={12}>
                                <Field name="password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.password && touched.password && <ErrorMessage message={errors.password} />}
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:age")}
                        </Grid>
                        <Grid item xs={12} className={`${page === 'register' && 'age-item'} ${errors.age && touched.age ? "mpf-error-form-item" : "mpf-form-item"}`}>
                            <Grid item xs={12} style={{ fontSize: '18px' }}>
                                {page === 'register' ? 
                                    <Field name="age" type="text">
                                        {/* Render the select box with ages from 50 to 60 */}
                                        {({ field }) => (
                                            <Select
                                                {...field}
                                                fullWidth
                                                label={t(`UserManagement:pleaseSelect`)}
                                                value={field.value || `${t(`UserManagement:pleaseSelect`)} ------------------------------`}
                                                // Assuming the value of the select box is stored in "field.value"
                                                renderValue={field.value !== "" ? () => field.value : () => `${t(`UserManagement:pleaseSelect`)} ------------------------------`}
                                            >
                                                <MenuItem style={{border: "1px solid lightgray" ,borderWidth: "0 0 1px 0"}} value={t(`UserManagement:below50`)}>{t(`UserManagement:below50`)}</MenuItem>
                                                {Array.from({ length: 14 }, (_, index) => 50 + index).map((age) => (
                                                    <MenuItem style={{border: "1px solid lightgray" ,borderWidth: "0 0 1px 0"}} key={age} value={age}>
                                                        {age}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem style={{border: "1px solid lightgray" ,borderWidth: "0 0 1px 0"}} value={t(`UserManagement:64AndAbove`)}>{t(`UserManagement:64AndAbove`)}</MenuItem>
                                            </Select>
                                        )}
                                    </Field>
                                : values.age < 50 ? t(`UserManagement:below50`) : (values.age > 63 ? t(`UserManagement:64AndAbove`) : values.age)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            {errors.age && touched.age && <ErrorMessage message={errors.age} />}
                        </Grid>
                    </Grid>



                    {page === 'register' && <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:firstFourDigitId")}
                        </Grid>
                        <Grid item xs={12} className={errors.first_four_digit_id && touched.first_four_digit_id ? "mpf-error-form-item" : "mpf-form-item"}>
                            <Grid item xs={12}>
                                <Field name="first_four_digit_id" type="text" placeholder={`${t("Common:General.eg")} A123`} maxLength="4"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.first_four_digit_id && touched.first_four_digit_id && <ErrorMessage message={errors.first_four_digit_id} />}
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:mobileNo")}
                        </Grid>
                        <Grid item xs={12} className={errors.phone && touched.phone ? "mpf-error-form-item" : "mpf-form-item"}>
                            <Grid item xs={12} style={{ fontSize: '18px' }}>
                                {page === 'register' ? <Field name="phone" type="tel" placeholder="" maxLength="8"/> : values.phone}
                            </Grid>
                            <Grid item xs={12}>
                                {errors.phone && touched.phone && <ErrorMessage message={errors.phone} />}
                            </Grid>
                            {MessageContent && mobileNumberError && <Grid item xs={12}>
                                <ErrorMessage message={MessageContent} />
                            </Grid>}
                        </Grid>
                    </Grid>

                    <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item">
                            {t("UserManagement:email")}
                        </Grid>
                        <Grid item xs={12} className={errors.email && touched.email ? "mpf-error-form-item" : "mpf-form-item"}>
                            <Grid item xs={12} style={{ fontSize: '18px' }}>
                                {page === 'register' ? <Field name="email" type="text" placeholder="" maxLength="100" /> : values.email}
                            </Grid>
                            <Grid item xs={12}>
                                {errors.email && touched.email && <ErrorMessage message={errors.email} />}
                            </Grid>
                            {MessageContent && emailAddressError && <Grid item xs={12}>
                                <ErrorMessage message={MessageContent} />
                            </Grid>}
                        </Grid>
                    </Grid>


                    <Grid container direction="column" justify="center" alignItems="center" className="mt40">
                        <Grid item xs={12} className="mpf-form-item" style={{ marginBottom: page === 'register' && '0.5rem' }}>
                            <div style={{ marginBottom: page === 'register' && '0.5rem' }}>{t("UserManagement:manulifePolicyNumber")}</div>
                            {page === 'register' && <div>
                                <a href={`https://pension.manulife.com/file/CAuserguide_${isChinese ? 'CH' : 'EN'}`} target="_blank" rel="noopener noreferrer" className='hyperlink bold link'>{t("UserManagement:clickHere")}</a>
                                <span>{!isChinese && ` `}{t("UserManagement:toLearnMoreHowToCheckMPFMemberAccount")}</span>
                            </div>}
                        </Grid>
                        <Grid item xs={12} className={errors.policy_number && touched.policy_number ? "mpf-error-form-item" : "mpf-form-item"} style={{ fontSize: '18px' }}>
                            <Grid item xs={12}>
                                {page === 'register' ? <Field name="policy_number" type="text" placeholder={`${t("Common:General.eg")} 1234567-8`} maxLength="50"/> : values.policy_number}
                            </Grid>
                            <Grid item xs={12}>
                                {errors.policy_number && touched.policy_number && <ErrorMessage message={errors.policy_number} />}
                            </Grid>
                            {MessageContent && policyNumberError && <Grid item xs={12}>
                                <ErrorMessage message={MessageContent} />
                            </Grid>}
                        </Grid>
                    </Grid>

                    {page === 'register' &&  <Grid container direction="column" justify="flex-end" alignItems="center" className="mt40">
                        <Grid container alignItems={"center"} item xs={12} className="mpf-form-item">
                            {t("UserManagement:referalCode")}
                            {/* <img style={{ height: "20px", width: "20px", marginLeft: "10px" }} src={question} alt={"question"} /> */}
                        </Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center" className="mpf-form-item">
                            <Flex flexDirection="column" flex={1}>
                                <Flex flex={1} style={{ fontSize: '18px' }}>
                                    {page === 'register' ? <Field name="referal_code" type="text" maxLength="50"/> : values.policy_number}
                                </Flex>
                                {page === 'register' && <Flex>
                                    {errors.referal_code && touched.referal_code && <ErrorMessage message={errors.referal_code} />}
                                </Flex>}
                                {MessageContent && referalCodeError && <Grid item xs={12}>
                                <ErrorMessage message={MessageContent} />
                            </Grid>}
                            </Flex>
                            {/* {page !== 'register' && <Button onClick={()=>{handleReferral(values.policy_number, i18n.language)}} style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Flex alignItems="center" justifyContent="flex-start" mr="0.5rem">
                                    <img className="refer-friends-icon" src={referFriendsIcon} alt="refer-friends" />
                                </Flex>
                                <Flex style={{ color: 'black', fontSize: '0.8rem' }}>
                                    Refer to friends
                                </Flex>
                            </Button>} */}
                        </Grid>
                    </Grid>}

                    {page === 'register' && <Flex mt={30} padding="1rem" style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#c0c0c0' }}>
                        <Flex flexDirection="column" height="200px" overflowY="auto">
                            {/* <Flex fontWeight={"bold"} fontSize={16} marginBottom="0.5rem">
                                {t("UserManagement:mpfTermsAndCondition")}
                            </Flex> */}
                            <Flex>
                                <div style={{ fontSize: 13 }}>
                                    <TandC />
                                   {/* <Box>
                                       {terms.map((term, i) => (
                                        <Flex key={`term-${i}`} mt="0.2rem" mr="0.5rem">
                                            <Flex flex={1}>{i + 1}.</Flex>
                                            <Flex flex={17}>
                                                {term}
                                            </Flex>
                                        </Flex>
                                        ))}
                                        <Flex mt="1rem">
                                            <Flex flex={1}>
                                                {''}
                                            </Flex>
                                            <Flex flex={17}>
                                                <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className='hyperlink'>Disclaimer</a>
                                            </Flex>
                                        </Flex>
                                    </Box> */}
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>}

                    {page === 'register' && <Flex mt={30} width="100%" flexDirection="column">
                        <Flex justifyContent="flex-start" alignIterms="center">
                            {t("UserManagement:tradePromotionCompetitionLicenceNo")}
                        </Flex>
                    </Flex>}

                    {page === 'register' && <Flex mt={30} width="100%" flexDirection="column">
                        <Flex justifyContent="flex-start" alignIterms="center">
                            <Checkbox disableTouchRipple checked={values.agreeTerms} style={{ backgroundColor: 'transparent', color: 'black', marginRight: '1rem', padding: 0 }} onChange={e => {setFieldValue('agreeTerms', !values.agreeTerms)}} />
                            <Flex alignItems="center" justifyContent="flex-start">
                                {t("UserManagement:iAgreeToTheTermsAndConditions")}
                            </Flex>
                        </Flex>
                        {agreeTermsError && <Grid item xs={12}>
                            <ErrorMessage message={t("UserManagement:termsAndConditionsMustBeAgreedBeforeProceeding")} />
                        </Grid>}
                    </Flex>}

                    {/*<Flex mt={30} width="100%">*/}
                    {/*    <Flex justifyContent="center" alignIterms="center">*/}
                    {/*        <Checkbox checked={values.receive_marketing_information} style={{ color: 'black', marginRight: '1rem', padding: 0 }} onChange={e => {setFieldValue('receive_marketing_information', !values.receive_marketing_information)}} />*/}
                    {/*        <Flex alignItems="center" justifyContent="center">*/}
                    {/*            <Flex alignItems="center" justifyContent="center">*/}
                    {/*                <p className="checkboxLabel">*/}
                    {/*                    {marketing}*/}
                    {/*                </p>*/}
                    {/*            </Flex>*/}
                    {/*        </Flex>*/}
                    {/*    </Flex>*/}
                    {/*</Flex>*/}

                    <Flex mt="2rem" className="button-wrapper" justifyContent="center">
                        <Button type="submit" className="primary-button m0" style={{ maxWidth: 500 }}>{page === 'register' ? t("Common:Button.register") : t("Common:Button.update")}</Button>
                    </Flex>
                </Grid>
            </Form>
        )
    }

    formChangePwdConfiguration = ({values, errors, touched, handleChange}) => {
        const { t } = this.props;
        return values && (
            <Form id="changePwdForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.ChangePwdMessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.ChangePwdMessageContent}
                        />
                    </Grid>
                    }

                    {
                        values.role === 2 &&
                        <Grid container direction="row" justify="center" alignItems="flex-start">
                            <Grid item xs={3} className="form-item">
                                {t("UserManagement:currentPassword")}
                            </Grid>
                            <Grid item xs={9} className="form-item">
                                <Grid item xs={12}>
                                    <Field name="old_password" type="password" placeholder="" maxLength="100"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {(errors.old_password && touched.old_password ? <ErrorMessage
                                        message={errors.old_password}/> : t("Common:Form.validator.required"))}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={3} className="form-item">
                            {t("UserManagement:newPassword")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {(errors.new_password && touched.new_password ? <ErrorMessage
                                    message={errors.new_password}/> : t("Common:Form.validator.required"))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={3} className="form-item">
                            {t("UserManagement:rePassword")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="re_new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.re_new_password && touched.re_new_password ? <ErrorMessage
                                    message={errors.re_new_password}/> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this.handleCloseChangePwd()
                        }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, user, agreeTerms, openSnackbar, variantSnackbar, messageSnackbar, openChangePwdDialog, page } = this.state;
        const { t } = this.props;
        const inputUser = {...user, isEdit: isEdit};
        const Schema = Yup.object().shape({
            legal_full_name: Yup.string().matches(/^[a-zA-Z ]+$/, t("UserManagement:pleaseInputCorrectLegalFullName")).required(t("UserManagement:legalFullNameIsRequired")),
            nickname: Yup.string().required(t("UserManagement:nicknameIsRequired")).matches(/^[a-zA-Z0-9-]+$/, t("UserManagement:pleaseInputCorrectNickname")).min(6, t("UserManagement:sorryYourNicknameMustBeBetweenSixAndTwentyCharactersLong")).max(20, t("UserManagement:sorryYourNicknameMustBeBetweenSixAndTwentyCharactersLong")),
            phone: Yup.string().matches(/^[4-9][0-9]{7}/, t("UserManagement:pleaseInputValidMobileNo")).required(t("UserManagement:mobileNoIsRequired")).min(8, t("UserManagement:pleaseInputValidMobileNo")).max(8, t("UserManagement:pleaseInputValidMobileNo")),
            // phone: Yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, t("UserManagement:pleaseInputValidMobileNo")).required(t("UserManagement:mobileNoIsRequired")).min(8, t("UserManagement:pleaseInputValidMobileNo")).max(8, t("UserManagement:pleaseInputValidMobileNo")),
            email: Yup.string().required(t("UserManagement:emailIsRequired")).email(t("UserManagement:pleaseInputCorrectEmailAddress")),
            password: page === 'register' ? Yup.string().required(t("UserManagement:passwordIsRequired")).min(4, t("UserManagement:sorryYourPasswordMustBeAtLeastFourCharactersLong")) : '',
            policy_number: page === 'register' ? Yup.string().matches(/^[1-9][0-9]{6}[-][0-9A-za-z]+$/,t("UserManagement:pleaseInputValidPolicyNumber")).required(t("UserManagement:policyNumberIsRequired")) : '',
            // referal_code: page === 'register' ? Yup.string().matches(/[a-z0-9]{10,}/,t("UserManagement:pleaseInputValidReferralCode")) : '',
            first_four_digit_id: page === 'register' ? Yup.string().matches(/^[A-Za-z][A-Za-z0-9][0-9]{2}/, t("UserManagement:pleaseInputValidFirstFourDigitsOfIdCard")).required(t("UserManagement:firstFourDigitsOfIdCardIsRequired")) : '',
            age: Yup.string().required(t("UserManagement:ageIsRequired"))
        })

        const SchemaChangePwd = Yup.object().shape({
            new_password: Yup.string().required("New Password is required"),
            re_new_password: Yup.string().when("new_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        const SchemaChangePwdUser = Yup.object().shape({
            old_password: Yup.string().required("Current Password is required"),
            new_password: Yup.string().required("New Password is required"),
            re_new_password: Yup.string().when("new_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        const pathname = this.props.location.pathname;
        const urlArray = pathname.split("/");
        const title = urlArray[2];
        if (!['edit-profile', 'register'].includes(title)) {
            window.location.reload();
        }

        return (
            user && (
                <div className='register_container'>
                    <Flex className="main__container head-container" flexDirection="column">
                        {/* <div className="sub_container"> */}
                            <Flex flex={1} justifyContent="start" alignItems="center" className="header-title" mt={70}>
                                <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                                <Flex ml="0.5rem">
                                    {t(`UserManagement:${this.getTitle(title)}`)}
                                </Flex>
                            </Flex>
                            <Flex justifyContent="center">
                                <Formik
                                    enableReinitialize
                                    initialValues={{...inputUser, agreeTerms}}
                                    validationSchema={Schema}
                                    onSubmit={this._submitUserForm}
                                    component={this.formConfiguration}
                                />
                            </Flex>

                            <Dialog
                                open={openChangePwdDialog}
                                onClose={this.handleCloseChangePwd}
                                aria-labelledby="form-dialog-title"
                                disableBackdropClick={true}
                            >
                                <DialogTitle id="form-dialog-title">SET NEW PASSWORD</DialogTitle>
                                <DialogContent>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            old_password: '',
                                            new_password: '',
                                            re_new_password: '',
                                            role: inputUser.role
                                        }}
                                        validationSchema={inputUser.role === 2 ? SchemaChangePwdUser : SchemaChangePwd}
                                        onSubmit={this._submitChangePwdForm}
                                        component={this.formChangePwdConfiguration}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={openSnackbar}
                                autoHideDuration={2000}
                                onClose={this.handleCloseSnackbar}
                            >
                                <MySnackbarContentWrapper
                                    onClose={this.handleCloseSnackbar}
                                    variant={variantSnackbar}
                                    message={messageSnackbar}
                                />
                            </Snackbar>
                        {/* </div> */}
                    </Flex>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile)));
