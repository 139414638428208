// Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from 'query-string'
import {COMMON_DATE_FORMAT} from "../../constants/common";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiMessage} from "../../Api/_ApiMessage";

// Define column names
const rows = [
    {id: 'name', numeric: false, disablePadding: false, label: 'WeChat Msg'},
    {id: 'trigger_', numeric: true, disablePadding: false, label: 'Triggered'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'lastmoddate', numeric: false, disablePadding: false, label: 'Update Date', type: 'date'}
];

const rowsPerPageDefault = 10;
const orderByDefault = 'lastmoddate';
const orderDefault = 'desc';

class SendWeChat extends Component {

    state = {
        data: null,
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault
    };


    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'auction-management') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault)
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params = {
            '$orderby': `${orderBy} ${order}`,
            '$top': rowsPerPage,
            '$skip': skipAmount,
        };

        apiMessage.getWechatTemplates(params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('ClientManagement:breadcrumb'),
                                    link: '/' + i18n.language + '/client-management'
                                },
                                {
                                    title: t('ClientManagement:sendWeChatMsg') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/send-wechat'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        })
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.wechat_template_id,
                update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
            }))
        ) : [];
    };

    _okButtonAction = (ids) => {
        console.log('Send to: ', ids)
        const {i18n} = this.props;
        if (ids.length === 1) {
            const body = {
                "wechat_template": ids[0],
            };

            apiMessage.sendWechatToAll(body).then(obj => {
                if (obj.status === 200) {
                    const msgDsp = {
                        messageSnackbar: 'Wechat message is sent successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    //Add msg
                    this.props.addMessageP(msgDsp);
                    this.props.history.push('/' + i18n.language + '/client-management?previous=true')
                } else {
                    const error = obj.data && obj.data.error ? obj.data.error : 'Send wechat message failed';
                    //Add msg
                    this.props.addMessageP({
                        messageSnackbar: error,
                        variantSnackbar: 'error',
                        key: new Date().getTime(),
                    });
                }
            })
        }
    }

    _cancelButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client-management?previous=true');
    }

    render() {
        const {data, amountOfData, currentPage, rowsPerPage, orderBy, order} = this.state;
        return (
            data &&
            <div>
                <BaseListPage
                    pageId={'wechat-management'}
                    mainClass={'wechat-page'}
                    primaryKey={'wechat_template_id'}
                    headerContent={'Please select a WeChat Msg'}
                    displaySearchBar={false}
                    displayActionBar={false}

                    displayBottomFunction={true}
                    okButton={true}
                    okButtonText={'Send'}
                    okButtonAction={this._okButtonAction}

                    cancelButton={true}
                    cancelButtonText={'Back'}
                    cancelButtonAction={this._cancelButtonAction}

                    getDataDynamic={this._getDataDynamic}
                    currentPage={currentPage}
                    rows={rows}
                    data={data}
                    displaySelectBox={true}
                    orderDefault={order}
                    orderByDefault={orderBy}
                    rowsPerPageDefault={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20]}
                    amountOfData={amountOfData}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SendWeChat)));
