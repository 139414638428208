import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiAuction = {
    getAuctions: (params) => {
        return api.get('auction_slots', params, null)
    },
    getAuctionDetail: (id, params) => {
        return api.get(`auction_slots/${id}`, params, null)
    },
    addAuction: (body) => {
        return api.post(`auction_slots`, body)
    },
    updateAuction: (id, body) => {
        return api.put(`auction_slots/${id}`, body)
    },
    updateAuctionStatus: (id, body) => {
        return api.put(`auction_slot_status_update/${id}`, body)
    },
    deleteAuction: (id, params) => {
        return api.delete(`auction_slots/${id}`, params);
    },
    submitSealedBid: (platform, id, body) => {
        return api.post(`${getBaseUrl(platform)}/submit_final_sealed_bid/${id}`, body);
    },
    downloadSealedBidInfo: (platform, id, option) => {
        return api.getCSVFile(`${getBaseUrl(platform)}/download_sealed_bid_info/${id}`, { responseType: 'blob', ...option });
    },
    uploadSealedBidInfo: (platform, id, body) => {
        return api.postMultipart(`${getBaseUrl(platform)}/upload_sealed_bid_info/${id}`, body, {});
    },
    downloadSealedBidAwardInfo: (platform, id, option) => {
        return api.getCSVFile(`${getBaseUrl(platform)}/download_sealed_bid_info/${id}?awarded=true`, { responseType: 'blob', ...option });
    },
    completeSealedBidAward: (platform, id, body) => {
        return api.post(`${getBaseUrl(platform)}/complete_sealed_bid_award/${id}`, body);
    },
    getSuppliers: (params) => {
        return api.get('suppliers', params, null);
    },
    getLots: (platform, params) => {
        return api.get(`${getBaseUrl(platform)}/auctions`, params, null);
    },
};
