import {api} from './_ApiFactoryWithHeader';

export const apiTradeHistories = {

    getUserTradeHistories: (param) => api.get('trade_histories', param),

    createTradeHistory: (param) => api.post('trade_histories', param),
    
    cancelTradeHistory: (id, body) => api.put(`cancel_instruction/${id}`, body),
};
