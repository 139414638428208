// Essential for all components
import React, {Component, Fragment} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import moment from "moment";
import {get, map, sortBy} from 'lodash-es';

import {apiStore} from "../../Api/_ApiStore";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";

class StoreView extends Component{

    state = {
        store: {
            store_id: null,
            name: '',
            store_type: '',
            location: '',
            address: '',
            lat: null,
            long: null,
            permanent: null,
            active: null,
            start_time: null,
            end_time: null,
            lastmoddate: null,
        }
    };

    componentDidMount() {
        this.getStoreDetail();
    }

    getStoreDetail = () => {
        if(this.props.id) {
            apiStore.getStoreDetail(this.props.id, {
                $expand: 'store_type,store_time_slots'
            }).then(obj => {
                if(obj && obj.status === 200 && obj.data) {
                    const store = obj.data;
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: 'Store',
                                    link: null
                                },
                                {
                                    title: store.name,
                                    link: null
                                }
                            ]
                        }
                    );
                    let store_time_slots = get(store, 'store_time_slots');
                    if (store_time_slots && store_time_slots.length > 0) {
                        store_time_slots = sortBy(store_time_slots, ['start_time', 'end_time']);
                        store.store_time_slots = store_time_slots;
                    }
                    this.setState({
                        store: store
                    })
                } else {
                    console.log("eCb : ", obj);
                }
            })
        } else {
            this.props.setBreadcrumbP(null);
        }
    }

    goToEditPage = () => {
        const { i18n } = this.props;
        this.props.history.push(`/${i18n.language}/store/${this.props.id}`);
    }

    back = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/store-management?previous=true');
    }


    render(){
        const {t} = this.props;
        const {store} = this.state;
        return(
            <div>
                <div className="main__container flex-center-item">
                    <div className="full-width">
                        <Grid container xm={12} alignItems="center">
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="primary-button" onClick={() => { this.goToEditPage() }}>{t("Common:Button.edit")}</Button>
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                收集點名稱
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.name}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                類型
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {get(store, ['store_type', 'name'])}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Location
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.location}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                地址
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.address}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Latitude
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.lat}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Longitude
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.long}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                長期?
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.permanent ? 'Yes' : 'No'}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                狀態
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {store.active ? 'Active' : 'Inactive'}
                            </Grid>
                            {map(get(store, 'store_time_slots'), (storeTimeSlot, index) => {
                                return <Grid container direction="row" justify="center" alignItems="flex-start" key={get(storeTimeSlot, 'store_time_slot_id')}>
                                    <hr/>
                                    <Grid item xs={2} className="form-item">
                                        {index + 1}. 開始時間
                                    </Grid>
                                    <Grid item xs={4} className="form-item">
                                        { storeTimeSlot.start_time == null ? '' : moment(storeTimeSlot.start_time).format('YYYY-MM-DD HH:mm:ss')}
                                    </Grid>
                                    <Grid item xs={2} className="form-item">
                                        結束時間
                                    </Grid>
                                    <Grid item xs={4} className="form-item">
                                        { storeTimeSlot.end_time == null ? '' : moment(storeTimeSlot.end_time).format('YYYY-MM-DD HH:mm:ss')}
                                    </Grid>
                                </Grid>
                            })}
                            {/* <Grid item xs={2} className="form-item">
                                開始時間
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                { store.start_time == null ? '' : moment(store.start_time).format('YYYY-MM-DD')}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                結束時間
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                { store.end_time == null ? '' : moment(store.end_time).format('YYYY-MM-DD')}
                            </Grid> */}
                            <Grid item xs={2} className="form-item">
                                Update Date
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                { store.lastmoddate == null ? '' : moment(store.lastmoddate).format('YYYY-MM-DD')}
                            </Grid>

                            <Grid item xs={2} className="form-item">
                            </Grid>
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="primary-button" onClick={() => { this.back() }}>Back</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreView)));