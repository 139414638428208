import { api } from "./_ApiFactoryWithHeader";


export const apiTenant = {
    getTenantList: (params, cancelToken) => {
        const url = 'tenants';
        return api.get(url, params, null, cancelToken);
    },
    getTenantDetail: (id, params, cancelToken) => {
        const url = 'tenants/' + id;
        return api.get(url, params, null, cancelToken);
    },
    updateTenant: (id, body) => {
        const url = 'tenants/' + id;
        return api.put(url, body);
    },
    addTenant: (body) => {
        const url = 'tenants';
        return api.post(url, body);
    },
    deleteTenant: (id) => {
        const url = 'tenants/' + id;
        return api.delete(url);
    },

    getTenantTypeList: (params, cancelToken) => {
        const url = 'tenant_types';
        return api.get(url, params, null, cancelToken);
    },
    getTenantTypeDetail: (id, params, cancelToken) => {
        const url = 'tenant_types/' + id;
        return api.get(url, params, null, cancelToken);
    },

    getTenantMemberList: (params, cancelToken) => {
        const url = 'tenant_members';
        return api.get(url, params, null, cancelToken);
    },
    getTenantMemberDetail: (id, params, cancelToken) => {
        const url = 'tenant_members/' + id;
        return api.get(url, params, null, cancelToken);
    },
    updateTenantMember: (id, body) => {
        const url = 'tenant_members/' + id;
        return api.put(url, body);
    },
    fullUpdateTenantMember: (id, body, params) => {
        let url = 'tenant_members/' + id;
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    addTenantMember: (body, params) => {
        let url = 'tenant_members';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    deleteTenantMember: (id) => {
        const url = 'tenant_members/' + id;
        return api.delete(url);
    },

    getTenantMemberRoleList: (params, cancelToken) => {
        const url = 'tenant_member_roles';
        return api.get(url, params, null, cancelToken);
    },
    getTenantMemberRoleDetail: (id, params, cancelToken) => {
        const url = 'tenant_member_roles/' + id;
        return api.get(url, params, null, cancelToken);
    },

    getTenantMemberStoreList: (params, cancelToken) => {
        const url = 'tenant_member_stores';
        return api.get(url, params, null, cancelToken);
    },
    getTenantMemberStoreDetail: (id, params, cancelToken) => {
        const url = 'tenant_member_stores/' + id;
        return api.get(url, params, null, cancelToken);
    },

    getTenantConfigurationList: (params, cancelToken) => {
        const url = 'tenant_configurations';
        return api.get(url, params, null, cancelToken);
    },
    getTenantConfigurationDetail: (id, params, cancelToken) => {
        const url = 'tenant_configurations/' + id;
        return api.get(url, params, null, cancelToken);
    },
    updateConfigurationTenant: (id, body) => {
        const url = 'tenant_configurations/' + id;
        return api.put(url, body);
    },

};