// @material-ui
import { createMuiTheme } from '@material-ui/core/styles';

import {
    SCREEN_SIZE_SM,
    SCREEN_SIZE_MD,
    SCREEN_SIZE_LG
} from '../../../config';

export const theme = createMuiTheme({
    // typography: {
    //     useNextVariants: true,
    // },
    typography: {
        fontFamily: [
        'Arial',
        'NotoSansCJKtc'
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: SCREEN_SIZE_SM,
            md: SCREEN_SIZE_MD,
            lg: SCREEN_SIZE_LG,
            xl: 1920
        }
    },
    // ripple: {
    //     color: 'red',
    // },
    palette: {
        primary: {
            main_feature: '#d81b60',
            main: '#d81b60',
            light: '#ff5c8d',
            dark: '#a00037',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#dc3545',
            light: '#ff6b70',
            dark: '#a3001e',
            contrastText: '#ffffff',
        },
    },
});