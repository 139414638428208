// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
// Styling
import Grid from '@material-ui/core/Grid';
import {Button} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
// Api
import {apiAuth} from '../../Api/ApiAuth';
// Redux
import {connect} from 'react-redux';
import {getUserInfo, login, logout, register} from '../../Redux/Action/authAction';
// Utils
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {get} from 'lodash-es';
// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import {setAccessToken, setForceLogout, setforceLogoutMsg} from "../../Api/_ApiFactoryWithHeader";
import {CacheService} from "../../utils/CacheService";

class IndexPage extends Component {

    render() {
        const { t } = this.props;
        return <div>
            {'{placeholder text in landing page}'}
        </div>;
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    logoutP: data => dispatch(logout(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
    registerP: data => dispatch(register(data))
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(IndexPage)));
