// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Box, Flex } from 'reflexbox'
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import bannerImg from "../../images/banner.jpg";
import forwardIcon from "../../images/forward.png";

const chineseGameDescription = '強積金成立至今已踏入第二十三周年。強積金一詞雖然對香港大眾而言並不陌生，但當中又有多少人能真正了解強積金的意義並加以善用?我們希望透過本遊戲令參加者多了解強積金，更希望參加者能積極參與其中，明白定期重新配置其強積金投資比重和分散投資的重要性，以尋求更佳投資回報。'
const englishGameDescription = 'It has been 23 years since the Mandatory Provident Fund, better known as MPF, launched in 2000. While MPF isn’t a stranger to the majority of the population in Hong Kong, how many of us really know about our MPF? Participate in our game and expand your knowledge on MPF! Unlock the importance of regular re-allocation and diversification to maximize investment returns.'

const chineseDisclaimer = [
    '本遊戲的所有貨幣都是遊戲用途的虛擬貨幣',
    '本遊戲的所有強積金投資指示，只會在遊戲中執行，並不會反映在參加者現實的強積金投資戶口',
    '為了提升用家嘅遊戲體驗，我們作出了一些跟現實強積金投資的簡化改動。本遊戲會在不同方面跟現實投資存在差異，其中包括部分基金利息的計算方法。',
    '投資涉及風險，過往之表現不能作為將來表現之指引，成份基金價格可跌可升。'
]

const englishDisclaimer = [
    'All currency in this game is virtual currency specifically designed for game only.',
    'All MPF investment instructions in this game will only be executed in the game and will not be reflected in the actual MPF investment account of the participant',
    'To enhance the gaming experience, we have made some adjustments to make it distinct from the reality of MPF investment including the changes in interest calculation for certain funds.',
    'Investment involves risks. Past performance is not indicative of future performance. The price of constituent funds may fall as well as rise.'
]

class BeforeLogin extends Component {

    hadleClick = () => {
        window.open("https://www.google.com", '_blank');
    }

    render() {
        const { t, i18n } = this.props;
        const disclaimer = i18n.language === 'en-US' ? englishDisclaimer : chineseDisclaimer;
        const gameDescription = i18n.language === 'en-US' ? englishGameDescription : chineseGameDescription;
        return (                    
            <div>
                <Flex className="main__container">
                    <Flex flex={1} flexDirection="column" marginX={-15}>
                        <Flex flex={1} justifyContent="center" alignItems="center" className="video-container">
                            <img src={bannerImg} width="80%" alt="banner" />
                        </Flex>
                        <Flex fontSize="1.2rem" mt="2rem" flexDirection="column">
                            <Flex>
                                {t("BeforeLogin:whatIsThisCompetitionAbout")}
                            </Flex>
                            <Flex mt="0.5rem" fontSize="1.1rem">
                                {gameDescription}
                            </Flex>
                        </Flex>
                        
                        <Flex fontSize="1.2rem" mt="2rem" flexDirection="column">
                            <Flex>
                                {t("BeforeLogin:timeline")}
                            </Flex>
                            <Flex mt="0.5rem" fontSize="1.1rem">
                                {moment(1672556013000).format('D MMM')} - {moment(1680159213000).format('D MMM, YYYY')} ({t('Common:General.hkt')})
                            </Flex>
                            <Flex flex={1} justifyContent="start" alignItems="center" mt="0.5rem" onClick={this.hadleClick}>
                                <img src={forwardIcon} className="back-icon" alt="forward-icon" />
                                <Flex ml="0.5rem" fontSize="1.1rem">
                                    {t("Common:General.learnMore")}
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex fontSize="1.2rem" mt="2rem" flexDirection="column">
                            <Flex>
                                {t("BeforeLogin:disclaimer")}
                            </Flex>
                            <Flex mt="0.5rem" fontSize="1.1rem">
                                <Box>
                                    {disclaimer.map((term, i) => (
                                        <Flex key={`term-${i}`} mt="0.2rem">
                                            <Flex mr="1rem">•</Flex>
                                            <Flex>
                                                {term}
                                            </Flex>
                                        </Flex>
                                    ))}
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(BeforeLogin)));