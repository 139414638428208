import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { CnLuckyDrawTandC, EngLuckyDrawTandC } from '../../containers/19LuckyDraw/LuckyDrawTandC';

export const DISTable = (props) => {
    const { t } = props;
    const percentage = [100, 93.3, 86.7, 80.0, 73.3, 66.7, 60.0, 53.3, 46.7, 40.0, 33.3, 26.7, 20.0, 13.3, 6.7, 0.0];
    let age = 49;
    return (
        <table style={{ border: '1px solid black', width: '98%', marginBottom: '1rem' }}>
            <tr>
                <td style={{ textAlign: 'center', border: '1px solid black', background: 'lightgray', fontWeight: 'bold' }}>{t("UserManagement:age")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black', background: 'lightgray', fontWeight: 'bold'}}>{t("Fund:CoreAccumulationFund")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black', background: 'lightgray', fontWeight: 'bold' }}>{t("Fund:Age65PlusFund")}</td>
            </tr>
            {percentage.map((number, index) => {
                if (index !== 0) age += 1;
                return (
                    <tr key={index}>
                        <td style={{ textAlign: 'center', border: '1px solid black', backgroundColor: 'lightgray', fontWeight: 'bold' }}>{index === 0 ? t("UserManagement:below50") : age === 64 ? t("UserManagement:64AndAbove") : age}</td>
                        <td style={{ textAlign: 'center', border: '1px solid black'}}>{number}%</td>
                        <td style={{ textAlign: 'center', border: '1px solid black' }}>{percentage[percentage.length-(index+1)]}%</td>
                    </tr>
                )
            })}
        </table>
    )
}

export const EngTandC = (props) => {
    const { t, style } = props
    return <Flex style={style}>
        <ol>
            <li>MPF FUN Game ("Game") is only available to selected customers from Manulife (International) Limited (Incorporated in Bermuda with limited liability) ("Manulife"), who must hold a valid Manulife MPF contribution account ("Selected Customers"). Each Selected Customer can only register to participate once and participation to the Game is voluntary; late registration will not be accepted.</li>
            <li>The registration period for this Game is from 1 October 2023 to 26 October 2023, both dates inclusive ("Registration Period").</li>
            <li>The Game period starts from 1 November 2023 to 31 January 2024, both dates inclusive ("Game Period").</li>
            <li>This Game is organized by Think A Head Limited ("Think A Head"). Directors, officers and employees of Think A Head are not allowed to participate in this campaign for fairness' sake. </li>
            <li>Selected Customers must register an account ("Account") at the dedicated website of the Game within the Registration Period. </li>
            <li>After registration, an email will be sent to the Selected Customer's registered email address for the Account verification. The Account must be verified within the Registration Period. After successful Account verification, Think A Head will verify the Account information in 3 working days. Upon the completion of identity verification, a registration confirmation email with the referral code will be sent to the successfully registered customer ("Participant")'s registered email address. </li>
            <li>Participant can refer colleagues from the same company (i.e. Employees registered under the same employer's MPF scheme) to register and participate in this Game ("Referrer"). The referee ("Referee") must successfully register an Account within the Registration Period and fulfil all of the following requirements ("Successful Referral"):</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>Referrer must be a Participant of this Game;</li>
                <li>Referee must be under the same employer's MPF scheme of the Referrer;</li>
                <li>Referee must register an Account at the dedicated website of the Game and enter Referrer's referral code within the Registration Period; and</li>
                <li>Referee successfully registers as a Participant of the Game.</li>
            </ol>
            <li>First 1000 referrers will be eligible to receive a HK$50 PARKnSHOP eGift Voucher ("Referral Bonus") for each Successful Referral.  Quota is limited and is on a first-come-first-served basis.</li>
            <li>The Referral Bonus will be sent to the eligible Referrer's registered email address.</li>
            <li>On 1 November 2023, each Participant's Account will receive a virtual MPF asset of HK$100,000, which will be allocated to the interest fund in the game account.</li>
            <li>Participants can change their investment instructions (including switching funds and changing investment instructions for future contributions) at any time during the Game Period. Each Participant's Account will be injected a virtual MPF contribution of HK$3,000 on the 3rd wording day of each month (i.e. 3 November, 5 December 2023 and 4 January 2024) during the Game Period and all injected amount will be invested according to its investment instruction for future contributions. In the absence of an investment instruction, all MPF assets in the Account will be invested in the "Default Investment Strategy" by default and the allocation* based on the Participant's age inputted during the Account registration and does not involve automatically reducing investment risk according to members' age throughout the Game.</li>
            <br/>
            <b>*DIS de-risking table</b>
            <DISTable t={t} />
            <li>If the change of investment instructions is received before 4:00 p.m. on a working day, the fund price will be converted on the same day; if the instruction is received after 4:00 p.m., the conversion will be made at the price of the next working day. During the Game Period, instructions submitted before 4:00 pm will be executed on the same trading day. Instructions submitted after 4:00 pm will be executed on the next trading day. If a Participant submits multiple fund switching and future investment changes instructions before 4:00 p.m., only the last fund switching, and future investment change instructions will be executed.</li>
            <li>The fund quotations provided in this Game are the reference prices.</li>
            <li>At any time, the virtual MPF investment portfolio must include at least 2 different constituent funds.</li>
            <li>Participants can accomplish six missions to earn the relevant badges in "My Mission" of the Game website during the Game Period. The first 100 Participants who (i) have completed specific mission(s) and (ii) earn the relevant badges will be eligible for the following rewards ("Mission Reward"):</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>Badge 1 – HK$1,000 virtual MPF assets</li>
                <li>Badge 2 – HK$1,000 virtual MPF assets</li>
                <li>Badge 3 – HK$1,000 virtual MPF assets</li>
                <li>Badge 4 – HK$1,000 virtual MPF assets</li>
                <li>Badge 5 – HK$1,000 virtual MPF assets</li>
                <li>Badge 6 – HK$1,000 virtual MPF assets</li>
                <li>Special reward – First 200 Participants who have successfully completed all missions (Mission 1 to 6) will receive an additional HK$100 PARKnSHOP eGift Voucher.</li>
                <li>In addition, subject to the Terms and Conditions of the Lucky Draw, Participants who have completed first four missions (i.e. Mission 1 to 4) on or before 31 December 2023 will automatically participate in the lucky draw of the Game ("Lucky Draw"). Please refer to the Terms and Conditions of the Lucky Draw for the details.</li>
            </ol>
            <li>Upon receiving the Mission Reward, the Participant must redeem the reward in "My Reward" in the Game website during the Game Period. After clicking "Claim", the virtual MPF assets will be injected into the immediately and the eGift Voucher will be sent to the Participant's registered email address immediately.</li>
            <li>All Mission Reward including virtual MPF assets and e-Gift Voucher cannot be resold or transferred to another person, canceled, withdrawn or exchanged for cash.</li>
            <li>Think A Head is not responsible for and shall have no liability in respect of the quality and availability of the products and services provided by the supplying merchant(s). The use of the e-Gift Vouchers shall be subject to the terms and conditions as stipulated by the supplying merchant(s) of the e-Gift Vouchers from time to time.</li>
            <li>At the end of the Game, grand prizes ("Cash Reward") will be awarded to the top 20 Participants with the highest Account balance as of 31 January 2024. If multiple Participants have the same account balance, Think A Head will list the ranking according to Participants' registration time of the Game. The earlier the completion of the registration during the Registration Period, the higher the ranking, and so on.</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>The first prize winner will receive a Cash Reward equivalent to the total amount of the Participant's Account as of 31 January 2024 and capped at HK$200,000.</li>
                <li>2nd place – HK$10,000 Cash Reward</li>
                <li>3rd place – HK$8,000 Cash Reward</li>
                <li>4th place – HK$6,000 Cash Reward</li>
                <li>5th place – HK$5,000 Cash Reward</li>
                <li>6th to 10th place – HK$2,000 Cash Reward</li>
                <li>11th to 20th place – HK$1,000 Cash Reward</li>
            </ol>
            <li>All rewards will be distributed before 30 March 2024. The list of winners for the Cash Reward (including their full name and the last 4 digits of their mobile phone number) will be announced on the Game website on 7 February 2024 (Hong Kong time).</li>
            <li>Think A Head will notify the winners individually by email on 16 February 2024 (Hong Kong time) through the email address provided at the registration of the Game; winners are required to present their winning notification emails and their valid Hong Kong identity card or passport for identity verification when collecting the prizes.</li>
            <li>Participants shall enter their personal particulars accurately and clearly and hereby warrant that all information submitted is true, accurate and complete. Think A Head reserves the right to revoke any prize awarded to a Participant if any submitted information is false, inaccurate or incomplete.  All information is based on the online registration records. Any faulty, duplicate and incomplete information will result in disqualification. Think A Head will not be liable for winners' inability to receive winning notifications or redemption details, or failure to redeem their prize as a result of any untrue or inaccurate information provided during registration of the Game. </li>
            <li>Cash Rewards are not transferable or exchangeable for other prizes, gift certificates or cash certificates. Think A Head reserves the right to suspend, vary or terminate this Game and amend its terms and conditions at any time without prior notice. In case of any disputes, Think A Head reserves the right of final decision.</li>
            <li>Think A Head shall not be liable for any transmission delay, loss, inaccuracy or incompatibility of the data submitted by Participants due to technical issues such as computers or system failure. All dates and times related to this Game (including but not limited to those for Game participation and prize collection confirmation) are subject to the system records of Think A Head.</li>
            <li>All Participants must agree to and abide by all the rules and terms of this Game, otherwise, will be disqualified.</li>
            <li>In the event of any disputes, Think A Head's decision shall be final and conclusive.</li>
            <li>In case of any discrepancy between the English and Chinese versions of the terms and conditions, the English version shall prevail.</li>
            <li>For any inquiries, please contact Think A Head at info@mpfgame.com.</li>
            <li>This Game is not and should not be considered as an invitation, offer, recommendation to make any investment or solicitation of sales.</li>
            <li>Manulife shall not be responsible or liable for whatever aspects related to the Game.</li>
        </ol>
    </Flex>
}

export const CnTandC = (props) => {
    const { t, style } = props
    return <Flex style={style}>
        <ol>
            <li>MPF積分賽 (「遊戲」) 只限宏利人壽保險(國際)有限公司(於百慕達註冊成立之有限責任公司)(「宏利」) 特選客戶參加，其須持有有效的宏利強積金供款帳戶) (「特選客戶」)。每名特選客戶只限登記參加一次，並屬自願性質參加遊戲。逾期登記恕不接受。</li>
            <li>此遊戲的帳戶登記期由2023年10月1日開始至2023年10月26日，包括首尾兩天(「登記期」)。</li>
            <li>此遊戲將由2023年11月1日開始至2024年1月31日結束，包括首尾兩天(「遊戲期間」)。</li>
            <li>此遊戲由諗頭有限公司（「諗頭」）主辦。受僱於諗頭的董事、高級職員及僱員均不得參加是次活動，以示公允。</li>
            <li>特選客戶需於登記期內前往遊戲所載網站內登記帳戶(「帳戶」)。</li>
            <li>登記帳戶後，特選客戶登記的電郵地址會收到由系統發出關於驗證帳戶的電郵，特選客戶必須於登記期內驗證帳戶。成功驗證帳戶後，諗頭將於三個工作天內核實帳戶的資料。完成核實身份程序後，登記確認電郵及推薦號碼將發送到成功登記帳戶的特選客戶(「參賽者」)登記的電郵地址。</li>
            <li>參賽者可推薦其同公司(即登記在同一僱主的強積金計劃下的僱員)的同事登記參加此遊戲(「推薦人」)。每一位受薦人(「受薦人」) 必須於登記期內成功登記帳戶並符合以下所有要求 (「成功推薦」) ：</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>推薦人必須為此遊戲的參賽者；</li>
                <li>受薦人必須與推薦人屬同一僱主的強積金計劃；</li>
                <li>受薦人必須於登記期內前往遊戲網登記帳戶並輸入推薦人的推薦號碼；及</li>
                <li>受薦人成功登記成為遊戲的參賽者。</li>
            </ol>
            <li>首1000名推薦人於登記期內每成功推薦一位參賽者即有資格獲得價值港幣50元的百佳超級市場電子禮券（"推薦獎賞"）。名額先到先得，額滿即止。</li>
            <li>推薦獎賞將於以電郵形式發送予合資格的推薦人的登記電郵地址。</li>
            <li>於2023年11月1日，每名參賽者的帳戶將獲發港幣100,000元虛擬強積金資產，並分配至遊戲帳戶中的利息基金。</li>
            <li>參賽者可在遊戲期間隨時更改投資指示 (包括轉換基金及更改未來供款的投資指示)。每名參賽者的帳戶將於遊戲期間，在每個月的第3個工作天 (即2023年11月3日，12月5日及2024年1月4日)獲注入港幣3,000元虛擬強積金供款，所注入的金額將根據其未來供款的投資指示進行投資。在沒有投資指示的情況下，帳戶口內的所有強積金資產將會自動投資於「預設投資策略」，並根據參賽者於登記帳戶時所輸入的年齡作配置*，並不涉及在遊戲過程中隨成員年齡自動降低投資風險。</li>
            *預設投資策略降低風險列表
            <DISTable t={t} />
            <li>若於工作日下午４時前收到更改投資指示，會以即日基金價格轉換；若下午４時後才收到指示，則以下一個工作日的價格進行轉換。遊戲期間，下午4點前遞交的指示將在同一交易日執行。 下午4點後遞交的指示將在下一個交易日執行。 如參賽者於下午4時前遞交多項轉換基金及更改未來投資指示，則只會執行最後的轉換基金及更改未來投資指示。 </li>
            <li>是次遊戲提供之基金報價均為參考價格。</li>
            <li>任何時候虛擬強積金投資組合須至少包含2隻不同的成分基金。</li>
            <li>參賽者在遊戲期間於遊戲內的「我的任務」頁面完成六項任務以獲取相關徽章。首 100 名(i)完成指定任務並(ii)獲取相關徽章的參賽者可獲得以下獎賞 (「任務獎賞」)：</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>徽章 1 – 港幣 1,000 元虛擬強積金資產</li>
                <li>徽章 2 – 港幣1,000元虛擬強積金資產</li>
                <li>徽章 3 – 港幣 1,000 元虛擬強積金資產</li>
                <li>徽章 4 – 港幣1,000 元虛擬強積金資產</li>
                <li>徽章 5 – 港幣1,000元虛擬強積金資產</li>
                <li>徽章 6 – 港幣1,000 元虛擬強積金資產</li>
                <li>特別獎賞 – 首200位成功完成所有任務 (即任務一至六) 的參賽者可額外羸取HK$100百佳超級市場電子禮券。</li>
                <li>此外，根據大抽獎的條款及細則，所有於2023年12月31或之前完成首四項任務(即任務一至四) 的參賽者將自動參加遊戲的大抽獎 (「大抽獎」)。詳情請參閱大抽獎的條款及細則。</li>
            </ol>
            <li>獲得任務獎賞後，参賽者須於遊戲內的「我的獎賞」頁面進行兌換。選取「兌換」後，有關的虛擬強積金資產將於隨即注入帳戶內，而電子禮券將隨即以電郵形式發送至参賽者註冊的電郵地址。</li>
            <li>所有任務獎賞包括虛擬強積金資產及電子禮券均不可轉售或轉讓他人、取消、撤回或兌換現金。</li>
            <li>諗頭對有關供應商產品及服務的質素及供應並無責任及槪不負責。電子禮券之使用受電子禮券供應商不時訂明的條款及細則約束。</li>
            <li>於遊戲完結時，截至2024年1月31日帳戶結餘最高的首20名參賽者將獲頒發大獎(「現金獎賞」)。如多名參賽者出現相同帳戶結餘的情況，諗頭將以參賽者登記遊戲帳戶的時間排序，越早於登記期內完成登記， 排名越前，如此類推。「現金獎賞」詳情如下：</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>第 1 名得獎者將獲得截至2024年1月31日參賽者其帳戶總額等值的現金獎賞，上限為港幣200,000元。</li>
                <li>第 2 名 – 獲得港幣10,000元現金獎賞</li>
                <li>第 3 名 – 獲得港幣8,000 元現金獎賞</li>
                <li>第 4 名 – 獲得港幣6,000 元現金獎賞</li>
                <li>第 5 名 – 獲得港幣5,000 元現金獎賞</li>
                <li>第 6至10 名 – 獲得港幣2,000 元現金獎賞</li>
                <li>第 11至20 名 – 獲得港幣1,000 元現金獎賞</li>
            </ol>
            <li>所有獎賞將於2024年3月30日前發放。現金獎賞的得獎名單（包括得獎者全名及手機號碼尾4個數字）將於2024年2月7日（香港時間） 於遊戲網頁內公佈。</li>
            <li>諗頭將於2024年2月16日（ 香港時間 ）透過得獎者登記遊戲時所提供的電郵地址，以電郵個別通知得獎者；得獎者於領獎時必須出示得獎通知電郵及其有效之香港身份證或護照以核實身份。</li>
            <li>參賽者必須準確及清楚地填寫個人資料並保證所提供的一切登記資料均絕對真實、準確及完整。如參賽者提供的資料不真實、不準確或不完整，諗頭有權撤銷參賽者的領獎資格。所有資料均以網上登記為準，任何重複、錯誤或不完整的資料將被視作無效。若於登記遊戲時所填寫之資料不實或不正確，導致得獎者無法收到得獎通知、領獎詳情或獎品，諗頭恕不負責。</li>
            <li>現金獎賞不可轉讓或更換為其他獎品、禮券或現金券。諗頭保留權利隨時暫停、更改或終止此遊戲及更改其條款及細則，並毋須另行通知。如有任何爭議，諗頭保留最終決定權。</li>
            <li>諗頭毋須就任何因電腦或網絡故障等技術問題而引致參賽者所遞交的資料出現傳遞延誤、遺失、錯漏或無法辨識等情況負上任何責任。所有遊戲相關之日期及時間（包括但不限於參加及確認領奬等日期及時間）均以諗頭有限公司的系統記錄為準。</li>
            <li>所有參賽者必須同意及遵守本遊戲之所有規則及條款，否則，將被取消資格。</li>
            <li>如有任何爭議，諗頭所作的決定為最終及不可推翻。</li>
            <li>本條款及細則之中英文文本如有歧異，概以英文本為準。</li>
            <li>如有任何查詢，請電郵至info@mpfgame.com與諗頭聯絡。</li>
            <li>是次遊戲並非及不應被視為進行任何投資的邀請、要約、建議或招覽銷售。</li>
            <li>宏利將不承擔任何與遊戲有關的責任。</li>
        </ol>
    </Flex>
}

const EngPIC = (props) => {
    const { style } = props
    return <Flex flexDirection="column" style={style}>
        <Flex fontSize={16} mt="1rem" fontWeight="bold">PRIVACY POLICY STATEMENT</Flex>
        <Flex>
            Think A head Limited ("the Company") recognize their responsibilities in relation to the collection, holding, processing or use of personal data under the Personal Data (Privacy) Ordinance. Personal data will be collected only for lawful and relevant purposes and all practicable steps will be taken to ensure that personal data held by the Company is accurate. The Company will take all practicable steps to ensure security of the personal data and to avoid unauthorized or accidental access, erasure or other use. The Company will not provide your personal data to third parties for direct marketing or other unrelated purposes without your consent. The Personal Information Collection Statement set out below also forms part of this Privacy Policy Statement.
        </Flex>

        <Flex fontSize={16} mt="1rem" fontWeight="bold">PERSONAL INFORMATION COLLECTION STATEMENT ("PICS")</Flex>
        <Flex>
            The provision of your personal data is voluntary. Please note that if you do not provide the Company with the required personal data, the Company may not be able to complete the registration for you to enroll into the MPF Fun Game and MPF Fun Game Lucky Draw (the "Campaign").
        </Flex>

        <Flex mt="1rem">Purpose:</Flex>
        <Flex>When you register this Campaign, the Company will collect your personal data (including  English Name, first four digits of Hong Kong Permanent Identity Card number, email address, mobile number, age and Manulife MPF Member Account  Number) where these data collected will solely serve the purpose in contacting participants for the matters relevant to the campaign, game account verification, announcing the winners list and identity verification during the prize redemption . All information collected will be used for the Campaign only, and will be disposed within three months after the Event ends. The Company will not use your personal data for direct marketing purposes at any time.</Flex>

        <Flex mt="1rem">Transfer of personal data:</Flex>
        <Flex>Personal data will be kept confidential and the Company will not transfer your personal data to any other parties.</Flex>

        <Flex mt="1rem">Access and correction of personal data:</Flex>
        <Flex>Under the Personal Data (Privacy) Ordinance, you have the right to ascertain whether the Company holds your personal data, to obtain a copy of the data, to correct any data that is inaccurate, and to ascertain the Company's policies and practices in relation to personal data. You may also request the Company to inform you of the type of personal data held by it.</Flex>
        <Flex>Requests for access and correction or for information regarding policies and practices and types of data held should be addressed in writing to: Data Protection Officer, Think A Head Limited, 15/F, Ray Centre, 88 Hung To Road, Kwun Tong, Hong Kong, Email: info@mpfgame.com.</Flex>
        <Flex>This Privacy Policy Statement and the Personal Information Collection Statement have been translated into Chinese. If there is any inconsistency or ambiguity between the English version and the Chinese version, the English version shall prevail.</Flex>
    </Flex>
}

const CnPIC = (props) => {
    const { style } = props
    return <Flex flexDirection="column" style={style}>
        <Flex fontSize={16} mt="1rem" fontWeight="bold">私隱政策聲明</Flex>
        <Flex>
            諗頭有限公司（以下合稱“本公司”）明白其在《個人資料（私隱）條例》下就個人資料的收集、持有、處理或使用所負有的責任。本公司僅將為合法和相關的目的收集個人資料，並將採取一切切實可行的步驟，確保本公司所持個人資料的準確性和安全性，及避免發生未經授權或者因意外而擅自取得、刪除或另行使用個人資料的情況。本公司不會在沒有您的同意，把閣下的個人資料提供予第三方作直接促銷用途或其他沒有關係的用途。下列的個人資料收集聲明是私隱政策聲明的一部分。
        </Flex>

        <Flex fontSize={16} mt="1rem" fontWeight="bold">個人資料收集聲明</Flex>
        <Flex>
            閣下的個人資料為自願提供。敬請注意，如果閣下不向本公司提供所需的個人資料，閣下將無法完成MPF 積 FUN 賽登記，並將無法參與MPF 積 FUN 賽大抽獎（「本活動」）。
        </Flex>

        <Flex mt="1rem">目的：</Flex>
        <Flex>當閣下註冊參加MPF 積 FUN 賽，本公司將收集您的個人資料(包括英文姓名、香港永久性居民身份證號碼首四位字元、電郵地址、手提電話號碼、年齡及宏利強積金成員帳戶號碼號碼)，而所收集資料之主要用途為就本活動事宜聯絡參賽者、驗證遊戲帳戶、公布本活動之得獎者及於領獎時核實身份之用。任何所收集之個人資料只會用於本活動，並會於本活動完結後三個月內銷毀。本公司不會使用閣下的個人資料以作推介、提供和營銷本公司的產品／服務。</Flex>

        <Flex mt="1rem">個人資料的移轉：</Flex>
        <Flex>個人資料將予以保密，本公司並不會將閣下個人資料移轉至任何一方。</Flex>

        <Flex mt="1rem">個人資料的查閱和更正：</Flex>
        <Flex>根據《個人資料（私隱）條例》，閣下有權查明本公司是否持有閣下的個人資料，獲取該資料的副本，更正任何不準確的資料，以及查明本公司有關個人資料的政策及常規。閣下可以要求本公司告知閣下本公司所持個人資料的種類。</Flex>
        <Flex>查閱和更正的要求，或有關獲取政策、常規及所持的資料種類的資料，均應以書面形式發送至：香港香港九龍觀塘鴻圖道88號志聯中心15樓，諗頭有限公司，個人資料保護主任收，電郵：info@mpfgame.com。</Flex>
        <Flex>此中文私隱政策聲明和個人資料收集聲明為英文版本譯本。如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。</Flex>
    </Flex>
}

class TandC extends Component {

    render() {
        const { i18n, t } = this.props;
        return (                  
            <Flex flexDirection="column">
                <Flex fontWeight={"bold"} fontSize={16} marginBottom="0.5rem">
                    {t("UserManagement:mpfTermsAndCondition")}
                </Flex>
                <Flex flex={1} justifyContent="center" marginLeft={-15}>
                    {i18n.language === 'en-US' ? <EngTandC t={t} /> : <CnTandC t={t} />}
                </Flex>
                {i18n.language === 'en-US' ? <EngPIC /> : <CnPIC />}
                <Flex fontSize={16} mt="1rem" fontWeight="bold">{t("UserManagement:luckyDrawTermsAndConditions")}</Flex>
                <Flex flexDirection="column">
                    {i18n.language === 'en-US' ? <EngLuckyDrawTandC style={{ marginLeft: -15 }} /> : <CnLuckyDrawTandC style={{ marginLeft: -15 }} />}
                </Flex>
            </Flex>
        );
    }
}

export default withTranslation()(withRouter(TandC));
