// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { get, map, reduce } from 'lodash-es';
import { Button } from '@material-ui/core';
import { AgGridReact } from '@ag-grid-community/react';
import {saveAs} from 'file-saver';
import Bluebird from 'bluebird';
import JSZip from 'jszip';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import Container from '@material-ui/core/Container';
import Snackbar from "@material-ui/core/Snackbar";
import { getDatasource, dateValueFormatter, booleanValueFormatter, defaultColumnDef } from '../../utils/AgGridUtils';

import {apiUsers} from "../../Api/_ApiUsers";
import {CacheService} from '../../utils/CacheService';
import { apiTenant } from '../../Api/_ApiTenant';
import { apiQrCode } from '../../Api/_ApiQrCode';

var CONCURRENT_QR_CODE_GENERATION = window.navigator.hardwareConcurrency;
if (!Number.isSafeInteger(CONCURRENT_QR_CODE_GENERATION) || CONCURRENT_QR_CODE_GENERATION < 4) {
    CONCURRENT_QR_CODE_GENERATION = 4;
}

function generate_qr_code_image(qrcode) {
    var canvas = window.document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    window.w69b.qr.encoding.drawOnCanvas(qrcode, canvas, 1);
    return canvas;
}

function get_canvas_blob(canvas) {
    return new Promise(function(resolve, reject) {
        try {
            window.w69b.imgtools.getCanvasAsBlob(canvas, resolve);
        } catch (e) {
            reject(e);
        }
    });
}

async function generate_qrcode(texts, nameWithoutExtArray) {
    if (texts.length > 0) {
        var zip = new JSZip();
        await Bluebird.map(texts, function(text, index) {
            return get_canvas_blob(generate_qr_code_image(text)).then(function(blob) {
                zip.file(nameWithoutExtArray[index] + '.png', blob);
                return null;
            });
        }, {
            concurrency: CONCURRENT_QR_CODE_GENERATION
        });

        var zip_blob = await zip.generateAsync({
            type: 'blob'
        });
        var now = new Date();
        var fileSaver = saveAs(zip_blob, 'qrcode_' + now.toISOString() + '.zip', true);
    }
}

const ID_FIELD_NAME = 'tenant_member_id';

const columnDefCache = {};
function getColumnDefs(language) {
    let columnDefs = columnDefCache[language];
    if (!columnDefs) {
        columnDefs = [{
            field: 'display_name',
            headerName: '用戶顯示名稱',
            checkboxSelection: true,
            cellRenderer: function(params) {
                return <Link push to={'/' +
                    encodeURIComponent(language) +
                    '/tenant-member-management/' +
                    encodeURIComponent(get(params, ['data', ID_FIELD_NAME]))}
                >
                    {params.value}
                </Link>;
            }
        }, {
            field: 'tenant_member_role',
            headerName: 'Role',
            valueFormatter: function(params) {
                return get(params, ['value', 'name'], '');
            }
        }, {
            field: 'tenant_member_stores',
            headerName: 'Location',
            valueFormatter: function(params) {
                return get(params, ['value', 'length'], 0);
            }
        }, {
            field: 'qr_code',
            headerName: 'QR Code'
        }, {
            field: 'activated',
            headerName: 'Scanned',
            valueFormatter: booleanValueFormatter
        }, {
            field: 'active',
            headerName: 'Active',
            valueFormatter: booleanValueFormatter
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        columnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

const UNLINK_ID_FIELD_NAME = 'tenant_member_id';

const unlinkColumnDefCache = {};
function getUnlinkColumnDefs(language) {
    let columnDefs = unlinkColumnDefCache[language];
    if (!columnDefs) {
        columnDefs = [{
            field: 'display_name',
            headerName: '用戶顯示名稱',
            checkboxSelection: true,
        }, {
            field: 'tenant_member_role',
            headerName: 'Role',
            valueFormatter: function(params) {
                return get(params, ['value', 'name'], '');
            }
        }, {
            field: 'tenant_member_stores',
            headerName: 'Location',
            valueFormatter: function(params) {
                return get(params, ['value', 'length'], 0);
            }
        }, {
            field: 'user',
            headerName: '電子郵件',
            valueFormatter: function(params) {
                return get(params, ['value', 'email'], '');
            }
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        unlinkColumnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

function getRowId(params) {
    return params.data[ID_FIELD_NAME];
}

function getUnlinkRowId(params) {
    return params.data[UNLINK_ID_FIELD_NAME];
}

class TenantMemberManagement extends Component {

    constructor(props) {
        super(props);
        const tenantId = localStorage.getItem('asTenantId');

        this.state = {
            selectedRowCount: 0,
            amountOfJoinedUsers: 0,
            dataSource: getDatasource('tenant_members', {
                tenant: tenantId,
                $expand: 'tenant_member_role',
            }),
            unlinkDataSource: getDatasource('tenant_members', {
                tenant: tenantId,
                'user[null]': false,
                $expand: 'tenant_member_role,user',
            }, {
                success: (dataSlice, totalCount) => {
                    this.setState({
                        'amountOfJoinedUsers': totalCount
                    });
                }
            })
        };
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.handleAddClicked = this.handleAddClicked.bind(this);
        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
        this.handleDownloadQrCodeClicked = this.handleDownloadQrCodeClicked.bind(this);

        this.handleUnlinkGridReady = this.handleUnlinkGridReady.bind(this);
        this.handleUnlinkClicked = this.handleUnlinkClicked.bind(this);
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    handleSelectionChanged(evt) {
        const selectedRowCount = evt.api.getSelectedNodes().length;
        this.setState({
            selectedRowCount
        });
    }

    handleAddClicked() {
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/tenant-member');
    }

    handleEditClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!get(selectedRowNodes, 'length')) {
            return;
        }
        const id = get(selectedRowNodes, [0, 'data', ID_FIELD_NAME]);
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/tenant-member/' + encodeURIComponent(id));
    }

    handleDeleteClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!get(selectedRowNodes, 'length')) {
            return;
        }
        const ids = map(selectedRowNodes, ['data', ID_FIELD_NAME]);

        Bluebird.map(ids, id => {
            return apiTenant.deleteTenantMember(id);
        }, {
            concurrency: 4
        }).then(() => {
            this.setState({
                openSnackbar: true,
                messageSnackbar: 'Delete user(s) successfully',
                variantSnackbar: 'success'
            });
            this.gridApi.setDatasource(this.state.dataSource);
            this.unlinkGridApi.setDatasource(this.state.unlinkDataSource);
        });
    }

    handleDownloadQrCodeClicked() {
        if (this.gridApi) {
            const selectedData = this.gridApi.getSelectedRows();
            if (selectedData && selectedData.length > 0) {
                const displayNameMap = reduce(selectedData, (result, value, key) => {
                    result[value.qr_code] = value.display_name;
                    return result;
                }, {});
                apiQrCode.getList({
                    'qr_code_id[in]': map(selectedData, 'qr_code').join(),
                    $select: 'qr_code_id,qr_code_content'
                }).then(res => {
                    if (res.status === 200) {
                        const dataList = res.data;
                        generate_qrcode(
                            map(dataList, 'qr_code_content'),
                            map(dataList, datum => displayNameMap[datum.qr_code_id])
                        );
                    }
                });
            }
        }
    }

    handleUnlinkGridReady(params) {
        this.unlinkGridApi = params.api;
        this.unlinkGridColumnApi = params.columnApi;
    }

    handleUnlinkClicked() {
        if (!this.unlinkGridApi) {
            return;
        }
        const selectedRowNodes = this.unlinkGridApi.getSelectedNodes();
        if (!get(selectedRowNodes, 'length')) {
            return;
        }
        const ids = map(selectedRowNodes, ['data', UNLINK_ID_FIELD_NAME]);
        const updateBody = {
            user: null,
            activated: false,
        };

        // TODO: handle unlink user_stores in either server side or client side

        Bluebird.map(ids, id => {
            return apiTenant.updateTenantMember(id, updateBody);
        }, {
            concurrency: 4
        }).then(() => {
            this.setState({
                openSnackbar: true,
                messageSnackbar: '成功取消連結',
                variantSnackbar: 'success'
            });
            this.gridApi.setDatasource(this.state.dataSource);
            this.unlinkGridApi.setDatasource(this.state.unlinkDataSource);
        });
    }

    handleLogout() {
        this.props.logoutP();
    }

    handleCloseSnackbar() {
        this.setState({ openSnackbar: false });
    };

    render() {
        const { selectedRowCount, amountOfJoinedUsers, openSnackbar, variantSnackbar, messageSnackbar } = this.state;
        return <Container>
            <h2>用戶</h2>
            <div className="data-grid-menu">
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddClicked}
                >
                    添加
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleEditClicked}
                    disabled={selectedRowCount !== 1}
                >
                    編輯
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="secondary"
                    onClick={this.handleDeleteClicked}
                    disabled={selectedRowCount === 0}
                >
                    刪除
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleDownloadQrCodeClicked}
                    disabled={selectedRowCount === 0}
                >
                    Download QR Code
                </Button>
            </div>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                rowSelection="multiple"
                onSelectionChanged={this.handleSelectionChanged}
                rowModelType="infinite"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={getColumnDefs(this.props.i18n.language)}
                datasource={this.state.dataSource}
            ></AgGridReact>

            <h2>已連結用戶 ({amountOfJoinedUsers}):</h2>
            <div className="data-grid-menu">
            <Button
                className="data-grid-menu-item"
                variant="contained"
                color="secondary"
                onClick={this.handleUnlinkClicked}
            >
                取消連結
            </Button>
            </div>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleUnlinkGridReady}
                pagination="true"
                rowSelection="multiple"
                rowModelType="infinite"
                getRowId={getUnlinkRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={getUnlinkColumnDefs(this.props.i18n.language)}
                datasource={this.state.unlinkDataSource}
            ></AgGridReact>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={this.handleCloseSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleCloseSnackbar}
                    variant={variantSnackbar}
                    message={messageSnackbar}
                />
            </Snackbar>
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantMemberManagement)));