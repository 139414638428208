import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Flex } from 'reflexbox';
import Container from "@material-ui/core/Container";
import '../../css/00Home/_ladingAfterLogin.scss';
import dropDown from "../../images/dropDown.png";
import i from '../../images/icons8-i-64.png';

class LandingExtend extends Component {
    state = {
        myRank: '',
        myTrend: '',
        myBalance: '',
        myIncrement: '',
        rowData: [],
        tabValue: 0,
        isRegistrationPeriod: null,
        firstMonthRanking: false,
        secondMonthRanking: false,
        thirdMonthRanking: false,
        activeTab: '1',
    }

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab})
        console.log(this.state.activeTab);
    }

    render() {
        const { t, maxWidth } = this.props;
        const { rowData, myRank, myTrend, myBalance, myIncrement, tabValue, isRegistrationPeriod, firstMonthRanking, secondMonthRanking, thirdMonthRanking, activeTab } = this.state;
        return (
            <Flex flex={1} justifyContent="center">
                <Flex className="sub-title" flex={1} flexDirection="column" maxWidth={maxWidth} alignItems="center">
                    <Flex width="100%" justifyContent="space-between" marginBottom="1rem" style={{ backgroundColor: 'white', }}>
                        <Flex onClick={() => this.changeTab('1')} className={`height-title ${this.state.activeTab === '1' ? 'active' : ''}`} width="20%" justifyContent="center" alignItems="center">
                            <span >{t("Landing:balance")}</span>
                        </Flex>
                        <Flex onClick={() => this.changeTab('2')} className={`height-title gain ${this.state.activeTab === '2' ? 'active' : ''}`} flexDirection="column" justifyContent="center" >
                            <Flex fontWeight="bold">
                                {t("Landing:gain1")}
                            </Flex>
                            <Flex>
                                {t("Landing:dateGain1")}
                            </Flex>
                        </Flex>
                        <Flex onClick={() => this.changeTab('3')} className={`height-title gain ${this.state.activeTab === '3' ? 'active' : ''}`} flexDirection="column" justifyContent="center" >
                            <Flex fontWeight="bold">
                                {t("Landing:gain2")}
                            </Flex>
                            <Flex>
                                {t("Landing:dateGain2")}
                            </Flex>
                        </Flex>
                        <Flex className="bank">
                        </Flex>
                    </Flex>

                    {this.state.activeTab === '1'&& (
                        <Container style={{margin: "0 20px", maxWidth: "100%" }}>
                            <Flex className="sub-content">
                                <Flex flexDirection="column" alignItems="start" flex={1}>
                                    <Flex justifyContent="center" alignItems="center" >
                                        <span className="percentage"></span>
                                        69.90%
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center">
                                        <div className="recordTitle1">
                                            <div style={{ paddingTop: "1px" }}>{t("Landing:recordTitle1")}</div>
                                        </div>
                                        <div>
                                            SHK136 · {t("Landing:HKD")}
                                        </div>
                                    </Flex>

                                </Flex>
                                <Flex flexDirection="column" alignItems="end" flex={1}>
                                    <Flex justifyContent="flex-start">
                                        {t("Landing:HKD")} 11,837.79
                                    </Flex>
                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Flex>
                                            528.685 {t("Landing:unit")}
                                        </Flex>
                                        <Flex>
                                            22.391 {t("Landing:unitPrice")}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex className="sub-content">
                                <Flex flexDirection="column" alignItems="start" flex={1}>
                                    <Flex justifyContent="center" alignItems="center" >
                                        <span className="percentage percentage-2"></span>
                                        30.10 %
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center">
                                        <div className="recordTitle2 recordTitle1">
                                            <div style={{ paddingTop: "1px" }}>{t("Landing:recordTitle2")}</div>
                                        </div>
                                        <div>
                                            SHK150 · {t("Landing:HKD")}
                                        </div>
                                    </Flex>

                                </Flex>
                                <Flex flexDirection="column" alignItems="end" flex={1}>
                                    <Flex justifyContent="flex-start">
                                        {t("Landing:HKD")} 5,097.19
                                    </Flex>
                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Flex>
                                            585.547 {t("Landing:unit")}
                                        </Flex>
                                        <Flex>
                                            8.705 {t("Landing:unitPrice")}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex className="sub-content">
                                <Flex flexDirection="column" alignItems="start" flex={1}>
                                    <Flex justifyContent="center" alignItems="center" >
                                        <span className="percentage percentage-3"></span>
                                        --
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center">
                                        <div className="recordTitle3 recordTitle1">
                                            <div style={{ paddingTop: "1px" }}>{t("Landing:recordTitle3")}</div>
                                        </div>
                                        <div>
                                            SHK147 · {t("Landing:HKD")}
                                        </div>
                                    </Flex>

                                </Flex>
                                <Flex flexDirection="column" alignItems="end" flex={1}>
                                    <Flex justifyContent="flex-start">
                                        {t("Landing:HKD")} 0.26
                                    </Flex>
                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Flex>
                                            0.023 {t("Landing:unit")}
                                        </Flex>
                                        <Flex>
                                            11.356 {t("Landing:unitPrice")}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex justifyContent="center" style={{ border: 1, borderStyle: 'solid', borderColor: '#ff7769', width: "100%", padding: "15px 0", marginTop: '2vw', fontWeight: "bold" }}>
                                {t("Landing:switch")}
                            </Flex>
                        </Container>
                    )}

                    {this.state.activeTab === '2' && (
                        <Container style={{margin: "0 20px", maxWidth: "100%" }}>
                            <Flex className="sub-content ">
                                {t("Landing:defaultInvest")}<img style={{ width: '25px', height: '25px', marginLeft: '5px' }} src={i} alt="drop"/>
                            </Flex>
                            <Flex className="sub-content">
                                <Flex flexDirection="column" alignItems="start" flex={1}>
                                    <Flex justifyContent="center" alignItems="center" >
                                        <span className="percentage core"></span>
                                        1.93%
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center">
                                        <div className="recordTitle1 core-content">
                                            <div style={{ paddingTop: "1px" }}>{t("Landing:core")}</div>
                                        </div>
                                        <div>
                                            DIS148 · {t("Landing:HKD")}
                                        </div>
                                    </Flex>

                                </Flex>
                                <Flex flexDirection="column" alignItems="end" flex={1}>
                                    <Flex className="text-danger" justifyContent="flex-start">
                                        - 176.34
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex className="sub-content other-fund">
                                <Flex flexDirection="column" alignItems="start" flex={1}>
                                    {t("Landing:otherFund")}
                                </Flex>
                            </Flex>
                            <Flex className="sub-content">
                                <Flex flexDirection="column" alignItems="start" flex={1}>
                                    <Flex justifyContent="center" alignItems="center" >
                                        <span className="percentage"></span>
                                        34.11%
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center">
                                        <div className="recordTitle1">
                                            <div style={{ paddingTop: "1px" }}>{t("Landing:hang")}</div>
                                        </div>
                                        <div>
                                            SHK145 · {t("Landing:HKD")}
                                        </div>
                                    </Flex>

                                </Flex>
                                <Flex flexDirection="column" alignItems="end" flex={1}>
                                    <Flex className="text-danger" justifyContent="flex-start">
                                        - 4,758.17
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex justifyContent="center" style={{ border: 1, borderStyle: 'solid', borderColor: '#ff7769', width: "100%", padding: "15px 0", marginTop: '2vw', fontWeight: "bold" }}>
                                {t("Landing:switch")}
                            </Flex>
                        </Container>
                    )}


                </Flex>
            </Flex>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({

});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingExtend)));
