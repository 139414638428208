import { Dialog, IconButton, DialogContent, Typography, Button, makeStyles } from "@material-ui/core";
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Flex } from "reflexbox";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            root: {
                "& .MuiPaper-root": {
                    backgroundColor: '#fafafa',
                    margin: 15
                },
            },
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}


const cnDISDescriptions = [
    `預設投資策略（「預設投資策略」）並非一項基金，而是由兩項成分基金組成，即核心累積基金 (D148) 與65歲後基金 (D149) 的策略。參賽者帳戶內的所有強積金供款將根據參賽者於登記帳戶時所輸入的年齡及下列的配置百分比作配置，並不涉及在遊戲過程中隨參賽者年齡自動降低投資風險。`,
    `預設投資策略作為預設投資或投資選擇，均必須依循上述配置百分比作配置。如您投資於預設投資策略，您只可選擇從您的預設投資策略中轉出全部結餘或某一百分比的結餘，但不可分別從兩項成分基金中轉出其個別的結餘。`,
    `由於核心累積基金 (148) 與65歲後基金 (149) 為獨立選項而非預設投資策略下的基金，有關自動降低風險機制並不適用。`
]

const engDISDescriptions = [
    `The Default Investment Strategy ("DIS") is a strategy (not a fund), made up of two constituent funds, namely the Core Accumulation Fund (D148) and the Age 65 Plus Fund (D149). All MPF contributions in the account will be invested in the "Default Investment Strategy" by default and the allocation based on the Participant's age inputted during the Account registration and the allocation percentages below, and does not involve automatically reducing investment risk according to members’ age throughout the Game.`,
    `The DIS, as a default investment or an investment choice, must follow the above allocation percentages and the annual de-risking among the two constituent funds. If you are investing in the DIS, you can only choose to switch out all or a percentage of your balance from the DIS but cannot switch out the balance from each of the two constituent funds separately.`,
    `The automatic de-risking mechanism of DIS does not apply to the Core Accumulation Fund (148) and the Age 65 Plus Fund (149), being individual choices but NOT as part of DIS.`
]

const DISFundTable = (props) => {
    const { t } = props;
    const percentage = [100, 93.3, 86.7, 80.0, 73.3, 66.7, 60.0, 53.3, 46.7, 40.0, 33.3, 26.7, 20.0, 13.3, 6.7, 0.0];
    let age = 49;
    return (
        <Flex flexDirection="column" mb="1rem" fontSize={15}>
            <Flex padding="10px" backgroundColor="white" alignItems="center">
                <Flex flex={0.5} >{t("UserManagement:age")}</Flex>
                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex textAlign="center">{t("ManageFunds:ManulifeMPFCoreAccumulationFund")}</Flex>
                    <Flex>(DIS148)</Flex>
                </Flex>
                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex textAlign="center">{t("ManageFunds:ManulifeMPFAge65PlusFund")}</Flex>
                    <Flex>(DIS149)</Flex>
                </Flex>
            </Flex>
            {percentage.map((number, index) => {
                if (index !== 0) age += 1;
                return (
                    <Flex flexDirection="column">
                        <Flex padding="10px" alignItems="center">
                        <Flex flex={0.5}>{index === 0 ? t("UserManagement:below50") : age === 64 ? t("UserManagement:64AndAbove") : age}</Flex>
                            <Flex flex={1} justifyContent="center">{number}%</Flex>
                            <Flex flex={1} justifyContent="center">{percentage[percentage.length - (index + 1)]}%</Flex>
                        </Flex>
                        <hr />
                    </Flex>
                )
            })}
        </Flex>
    )
}

const DISDialog = (props) => {
    const { t, handleCloseDialog, open, i18n, classes } = props;
    const riskClassDescription = i18n.language === 'zh-HK' ? cnDISDescriptions : engDISDescriptions;
    return (
        <Dialog open={open} classes={{ root: classes.root }}>
            <Flex mt="1.5rem" mb="1rem" ml={15} justifyContent="flex-start" alignItems="center" fontWeight="bold" fontSize="1.25rem">
                {t("ManageFunds:MPFDefaultInvestmentStrategy")}
            </Flex>
            <Flex flexDirection="column" style={{ marginBottom: '1rem', padding: 15 }}>
                <Typography style={{ marginBottom: '1rem', textAlign: 'justify' }}>
                    {riskClassDescription[0]}
                </Typography>
                <DISFundTable t={t} />
                <Typography style={{ marginBottom: '1.5rem', textAlign: 'justify' }}>
                    {riskClassDescription[1]}
                </Typography>
                <Typography style={{ marginBottom: '1.5rem', textAlign: 'justify' }}>
                    {riskClassDescription[2]}
                </Typography>
                <Flex flex={1} className="button-wrapper" marginTop="1rem">
                    <Button onClick={handleCloseDialog} className="primary-button m0 p1rem" style={{ background: '#ec6453', color: 'white', border: 'none' }}>{t("Common:Button.close")}</Button>
                </Flex>
            </Flex>
        </Dialog>
    )
}

export default withStyles(DISDialog);