// Essential for all components
import React, { Component } from 'react';
import moment from "moment";
import get from 'lodash-es/get';
import snakeCase from 'lodash-es/snakeCase';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Files from "react-files";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CheckCircle from '@material-ui/icons/CheckCircle';
import BugReportIcon from '@material-ui/icons/BugReportOutlined';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import { setBreadcrumb } from "../../Redux/Action/breadcrumbAction";
import { addMessage } from '../../Redux/Action/messageAction';
import { getPlatformKey } from '../../Api/_ApiFactoryWithHeader';
import { apiTenant } from "../../Api/_ApiTenant";
import AlertDialog from '../01AuctionManagement/AlertDialog';

const CheckIcon = () => (
    <CheckCircle style={{ fontSize: 40, color: '#FE6B0B', marginRight: 12 }} />
);

const UncheckIcon = () => (
    <RadioButtonUnchecked style={{ fontSize: 40, color: '#EBEDED', marginRight: 12 }} />
);

class TenantUpdate extends Component {

    state = {
        source: '',
        supplier: [],
        sourceValidateMsg: null,
        dateTimeValidate: null,
        isEdit: false,
        alertDialogOpen: false,
        alertDialogType: null,
        loading: false,
        debugDialogOpen: false,
        tenant: {
            tenant_id: '',
            name: '',
            lastmoddate: null,
        },
    };

    componentDidMount() {
        if (this.props.id) {
            this.getTenantDetail();
        }
    }

    getTenantDetail = () => {
        const params = {
            $expand: "tenant_type"
        }
        apiTenant.getTenantDetail(this.props.id, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data && obj.data) {
                    const tenantDetail = obj.data;
                    this.setState({
                        tenant: this.prepareData(tenantDetail),
                    });
                    const userItemBreadcrumb = [{
                        title: `Tenant [${tenantDetail.name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                }
            } else {
                console.log(obj.error)
            }
        })
    }

    prepareData = data => {
        if (data) {
            const item = {...data};
            item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD HH:mm:ss') : '';
            return item;
        }
        return null;
    };

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    goToEditPage = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/tenant/${this.props.id}`);
    }

    back = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/tenant-management?previous=true');
    }

    deleteTenant = () => {
        const { id, addMessageP, history, i18n } = this.props;
        this.setState({ loading: true });
        return apiTenant.deleteTenant(id, null)
            .then(res => {
                if (res.status === 204) {
                    const msgDsp = {
                        messageSnackbar: 'Delete Tenant successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    addMessageP(msgDsp);
                    history.push('/' + i18n.language + '/tenant-management?previous=true')
                    return;
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Delete Tenant failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const { t } = this.props;
        const { tenant, alertDialogOpen, debugDialogOpen, debugEmail, loading } = this.state;
        return (
            tenant && (
                <div>
                    <div className="main__container flex-center-item">
                        <Grid container xm={12} alignItems="center">
                            <Grid container xm={12} direction="row" alignItems="flex-start">
                                <Grid item xs={12} md={6} className="button-wrapper form-item">
                                    <Button
                                        type="button"
                                        className="primary-button m0"
                                        onClick={() => this.goToEditPage()}
                                    >
                                        {t("Common:Button.edit")} Tenant
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} className="button-wrapper form-item flex-end-item">
                                    <Button
                                        type="button"
                                        className="delete-button"
                                        style={{ width: 'auto' }}
                                        onClick={() => this.setState({
                                            alertDialogOpen: true,
                                            alertDialogType: 'delete',
                                        })}
                                    >
                                        {t("Common:Button.delete")} Tenant
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                <Grid item xs={3} className="form-item">
                                    {t("TenantManagement:tenantName")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {tenant.name}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                <Grid item xs={3} className="form-item">
                                    {t("TenantManagement:tenantType")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {get(tenant, ['tenant_type', 'name'])}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="back-button" onClick={() => {
                                    this.back()
                                }}>Back</Button>
                            </Grid>
                        </Grid>
                        <AlertDialog
                            isOpen={alertDialogOpen}
                            title="WARNING"
                            onAction={() => {
                                this.deleteTenant();
                                this.setState({ alertDialogOpen: false });
                            }}
                            onCancel={() => this.setState({ alertDialogOpen: false })}
                            actionButtonText={'Delete'}
                            cancelButtonText="No"
                            actionButtonClass={'delete-button'}
                        >
                            Are youa sure you want to Delete '{tenant.name}'?<br />
                            You can't undo this.
                        </AlertDialog>
                        <Dialog open={debugDialogOpen} onBackdropClick={() => this.setState({ debugDialogOpen: false })} fullWidth>
                            <DialogContent>
                                <DialogContentText color="initial">Enter the testing email:</DialogContentText>
                                <Input value={debugEmail} onChange={(e) => this.setState({ debugEmail: e.target.value })} fullWidth />
                            </DialogContent>
                            <DialogActions style={{ padding: 24, paddingTop: 8 }}>
                                <Grid className="button-wrapper form-item" container justify="space-between">
                                    <Button
                                        type="button"
                                        className="back-button"
                                        onClick={() => this.setState({ debugDialogOpen: false })}
                                        style={{ margin: 0 }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="button"
                                        className="stop-button"
                                        onClick={() => this.submitSealedBidInfo(true, debugEmail)}
                                        disabled={!debugEmail || loading}
                                        style={{ margin: 0 }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                        {loading && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }} />
                        )}
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantUpdate)));
