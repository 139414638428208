import { clone, get, map, isFunction } from 'lodash-es';
import moment from 'moment';
import { api } from '../Api/_ApiFactoryWithHeader';
import { getValAfterSlash } from './PaginationUtils';

function applyAgGridTextFilter(query, filterAttr, filter) {
    "use strict";

    // TODO: escape filter text when using [like] operator on server.
    // TOOD: add support for escape filter text on server.
    // TOOD: add support for notContains filter type on server.
    switch (filter.type) {
        case "equals":
            query[filterAttr] = filter.filter;
            break;
        case "notEqual":
            query[filterAttr + "[ne]"] = filter.filter;
            break;
        case "contains":
            query[filterAttr + "[like]"] = "%" + filter.filter + "%";
            break;
        case "notContains":
            // query[filterAttr + "[nlike]"] = "%" + filter.filter + "%";
            throw new Error('unsupported text filter type "' + filter.type + '" for attribute "' + filterAttr + '"');
            break;
        case "startsWith":
            query[filterAttr + "[like]"] = filter.filter + "%";
            break;
        case "endsWith":
            query[filterAttr + "[like]"] = "%" + filter.filter;
            break;
        default:
            throw new Error('unsupported text filter type "' + filter.type + '" for attribute "' + filterAttr + '"');
            break;
    }
}
function applyAgGridNumberFilter(query, filterAttr, filter) {
    "use strict";

    switch (filter.type) {
        case "equals":
            query[filterAttr] = filter.filter;
            break;
        case "notEqual":
            query[filterAttr + "[ne]"] = filter.filter;
            break;
        case "lessThan":
            query[filterAttr + "[lt]"] = filter.filter;
            break;
        case "lessThanOrEqual":
            query[filterAttr + "[lte]"] = filter.filter;
            break;
        case "greaterThan":
            query[filterAttr + "[gt]"] = filter.filter;
            break;
        case "greaterThanOrEqual":
            query[filterAttr + "[gte]"] = filter.filter;
            break;
        case "inRange":
            query[filterAttr + "[between]"] = filter.filter + "," + filter.filterTo;
            break;
        default:
            throw new Error('unsupported number filter type "' + filter.type + '" for attribute "' + filterAttr + '"');
            break;
    }
}

const agGridDateFilterPattern = "YYYY-MM-DD";
function applyAgGridDateFilter(query, filterAttr, filter) {
    "use strict";

    var dateFrom = filter.dateFrom || filter.date;
    var dateFromTimestamp = moment(dateFrom, agGridDateFilterPattern).valueOf();
    switch (filter.type) {
        case "equals":
            query[filterAttr] = dateFromTimestamp;
            break;
        case "notEqual":
            query[filterAttr + "[ne]"] = dateFromTimestamp;
            break;
        case "lessThan":
            query[filterAttr + "[lt]"] = dateFromTimestamp;
            break;
        case "lessThanOrEqual":
            query[filterAttr + "[lte]"] = dateFromTimestamp;
            break;
        case "greaterThan":
            query[filterAttr + "[gt]"] = dateFromTimestamp;
            break;
        case "greaterThanOrEqual":
            query[filterAttr + "[gte]"] = dateFromTimestamp;
            break;
        case "inRange":
            query[filterAttr + "[between]"] = dateFromTimestamp + "," + moment(filter.dateTo, agGridDateFilterPattern).valueOf();
            break;
        default:
            throw new Error('unsupported date filter type "' + filter.type + '" for attribute "' + filterAttr + '"');
            break;
    }
}
function applyAgGridFilter(query, filterAttr, filter) {
    "use strict";

    switch (filter.filterType) {
        case "text":
            applyAgGridTextFilter(query, filterAttr, filter);
            break;
        case "number":
            applyAgGridNumberFilter(query, filterAttr, filter);
            break;
        case "date":
            applyAgGridDateFilter(query, filterAttr, filter);
            break;
        default:
            throw new Error('unsupported filter "' + filter.filterType + '" for attribute "' + filterAttr + '"');
            break;
    }
}

export const defaultColumnDef = {
    sortable: true,
    resizable: true,
    flex: 1
};

export function getDatasource(baseUrl, baseQueryParams, externalCallbacks) {
    return {
        getRows: function(params) {
            Promise.resolve().then(function() {
                const queryParams = baseQueryParams ? clone(baseQueryParams) : {};
                let startRow = get(params, 'startRow');
                if (startRow != null && startRow > 0) {
                    queryParams.$skip = startRow;
                } else {
                    startRow = 0;
                }
                const endRow = get(params, 'endRow');
                if (endRow != null && endRow > 0) {
                    queryParams.$top = endRow - startRow;
                }

                const sortModel = get(params, 'sortModel');
                if (sortModel && sortModel.length > 0) {
                    queryParams.$orderby = map(sortModel, function(sortAttr) {
                        let orderValue = sortAttr.colId;
                        if (sortAttr.sort === 'desc') {
                            orderValue += ' desc';
                        }
                        return orderValue;
                    });
                }

                // only support provided filters
                const filterModel = get(params, 'filterModel');
                if (filterModel) {
                    for (var filterAttr in filterModel) {
                        if (filterModel.hasOwnProperty(filterAttr)) {
                            applyAgGridFilter(queryParams, filterAttr, filterModel[filterAttr]);
                        }
                    }
                }

                return api.get(baseUrl, queryParams).then((rs) => {
                    if (rs && rs.status === 200) {
                        const dataSum = +(getValAfterSlash(rs.headers['content-range']));
                        const successCallback = get(params, 'successCallback');
                        if (isFunction(successCallback)) {
                            successCallback(rs.data, dataSum);
                        }
                        const callback = get(externalCallbacks, 'success');
                        if (isFunction(callback)) {
                            callback(rs.data, dataSum, rs.headers['content-range']);
                        }
                    } else {
                        throw rs;
                    }
                });
            }).catch((error) => {
                console.error(error);
                const failCallback = get(params, 'failCallback');
                if (isFunction(failCallback)) {
                    failCallback();
                }
                const callback = get(externalCallbacks, 'error');
                if (isFunction(callback)) {
                    callback(error);
                }
            });
        }
    }
}

const datetimeFormat = 'YYYY-MM-DD HH:mm:ss';
export function dateValueFormatter(params) {
    const value = get(params, 'value');
    if (value == null) {
        return '';
    }
    return moment(value).format(datetimeFormat);
}

export function booleanValueFormatter(params) {
    const value = get(params, 'value');
    if (value == null) {
        return '';
    }
    return value ? 'Yes' : 'No';
}
