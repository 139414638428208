// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {apiUsers} from "../../Api/_ApiUsers";
import { Flex } from 'reflexbox'

import {getUserInfo} from "../../Redux/Action/authAction";
import {CacheService} from "../../utils/CacheService";
import { apiAuth } from "../../Api/ApiAuth";
import backIcon from "../../images/back.png"

class NewsDetail extends Component {
    
    state = {
        title: this.props.history.location.state.title,
        description: this.props.history.location.state.description,
        video: this.props.history.location.state.video
    };

    constructor(props) {
        super(props);
        this.myFiles = React.createRef();
    }

    componentDidMount() {
        console.log(this.props.auth)
        if (this.props.auth && this.props.auth.userInfo) {
            this.setState({isEdit: true})
            this.getUserDetail();
        }
    }
    
    getUserDetail = () => {
        const {t, i18n} = this.props;
        let params = {
            // $expand: "profile_image"
        }
        apiUsers.getUserDetail(this.props.auth.userInfo.username, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    const userDetail = obj.data;
                    this.props.setBreadcrumbP({
                        breadcrumbData: [{
                            title: t('UserManagement:profile'),
                            link: '/' + i18n.language + '/profile'
                        }]
                    });
                    this.setState({
                        user: this.prepareData(userDetail)
                    });
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        })
    }

    prepareData = data => {
        const item = {...data};
        return item;
    };

    // BUTTON FUNCTION

    handleCloseSnackbar = () => {
        this.setState({openSnackbar: false});
    };

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _updateUserAsync = (value) => {
        let regex = /^\s+|\s+$/g;
        let updateUserBody = {
            "display_name": value.display_name,
            "username": value.email.replace(regex, ''),
            "email": value.email.replace(regex, ''),
        }
        this.callAPIUpdateUser(this.props.auth.userInfo.username, updateUserBody);
    }

    callAPIUpdateUser = (email, body) => {
        apiUsers.updateUser(email, body).then(obj => {
            if (obj.status === 200) {
                this._getCurrentUserInformation();
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Update user successfully',
                    variantSnackbar: 'success'
                })
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update user failed';
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: error,
                    variantSnackbar: 'error'
                });
            }
        });
    }

    _getCurrentUserInformation = () => {
        const params = null;
        apiAuth.getCurrentUserInformation(params).then(obj => {
            if (obj) {
                this.props.getUserInfoP(obj.data);
                CacheService.saveProfileData(obj.data)
            }
        })
    }

    render() {
        const { t } = this.props;
        const { title, description, video } = this.state;
        return (                    
            <div>
                <Flex className="main__container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("Common:MenuItem.newsAndEducation")}
                            </Flex>
                        </Flex>
                        <Flex flex={1} mt="2rem" justifyContent="center" className="video-container">
                            <Flex flex={1} className="video">
                                <iframe width="100%" height="100%" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </Flex>
                        </Flex>
                        <Flex fontSize="1.1rem" mt="1rem">
                            {title}
                        </Flex>
                        <Flex fontSize="1rem" mt="1rem">
                            {description}
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(NewsDetail)));