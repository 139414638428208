// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import {Button, makeStyles, Radio} from "@material-ui/core";

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import { apiTask } from '../../Api/_ApiTask';

const cnFutureInvestmentDescription = "更改尚未分配至您MPF積FUN賽帳戶的所有未來款項投資分配。參閱以下「重要事項」。"
const engFutureInvestmentDescription = "Change investment allocations for all your future monies which have not been allocated to your MPF FUN Game account. See \"Important notes\" below."

const engImportantNotes = [
    `Participants should note that investment markets could fluctuate significantly. Fund prices may go down as well as up. There is no guarantee that, given the time required to implement fund switching instructions, such instructions will achieve your desired results. Please carefully consider your own risk tolerance level and financial circumstances (as well as your own retirement plan) before making any investment choices.`,
    `Normally all instructions received via the MPF FUN Game Website at or before the cut-off time (currently at 4:00 p.m. Hong Kong time) on a dealing day shall be processed within the same dealing day whereas all instructions received after the cut-off time on a dealing day shall normally be processed on the next dealing day.`,
    `The change future investments instruction will be applied to ALL monies received but not yet allocated to the member account on and after the date of processing the latest instruction.`,
    `For fund switching or change future investments instructions received via the MPF FUN Game Website at or before the cut-off time on a dealing day, such instructions can be cancelled online at or before the cut-off time on the same dealing day. For fund switching or change future investments instructions received via the MPF FUN Game  Website after the cut-off time on a dealing day, such instructions can be cancelled online at or before the cut-off time on the next dealing day.`,
    `For instructions received within the prescribed time limit on any dealing day, if more than one instruction of the same instruction type in respect of fund switching instruction (whether "Fund-to-Fund Switch" or, if applicable, "One-time Rebalancing") or change future investments instruction for the same account is received, only the last instruction received shall be processed.`,
    `Fund switching instructions for the same account received will be processed in sequential order according to the submission time. If a fund switching instruction involves switch-in to a fund and the next fund switching instruction involves switch-out from the same fund, the switch-in made by the former instruction will be included in the balance of this fund for processing the said next instruction. If two fund switching instructions involve switch-out from the same fund involving the same type of contributions and they are submitted on the same dealing day before the cut-off time, the latter instruction will override the previous instruction.`,
    `One-time Rebalancing is a fund switching instruction which enables you to reallocate the investment of the total balance of accrued benefits of MPF FUN Game account by specifying the target percentages to the investment choices. The target percentages will be used to determine the target balance for each of the investment choices. The fund switching among all the relevant investment choices will then be carried out based on the difference between the target balance and the current balance of each of the relevant investment choices as at the date of processing. In the event of switching into the Default Investment Strategy (DIS), the redemption proceeds will be invested in the constituent fund(s) of the DIS in the allocation percentage according to the DIS de-risking table under the terms and conditions of MPF FUN Game. In the event of switching out from the DIS, the units to be redeemed from each of the constituent fund(s) of the DIS will be derived from the percentage of the accrued benefits to be switched out from the DIS.`
]
const cnImportantNotes = [
    `參賽者必須注意投資市場可能出現顯著的波動，基金單位價格可跌可升。由於處理有關基金轉換投資指示需要一定的時間，因此未必能夠保證達到您預期的結果。在作出投資選擇前，您必須小心衡量個人可承受風險的程度及財政狀況（包括您的退休計劃）。`,
    `在任何一個交易日截止時間（現時為香港時間下午4時）或之前經由MPF積FUN賽網站收到的所有指示，在正常情況下將於同一個交易日內處理。在任何一個交易日的截止時間後方收到的所有指示，在正常情況下將於下一個交易日處理。`,
    `更改未來投資指示適用於在處理指示當天及以後收到但未經分配至參賽者帳戶的所有款項。`,
    `在任何一個交易日的截止時間或之前已經由MPF積FUN賽網站收到基金轉換或更改未來投資指示，可於同一個交易日的截止時間或之前經網上取消。在任何一個交易日的截止時間後方經由MPF積FUN賽網站收到基金轉換或更改未來投資指示，可於下一個交易日的截止時間或之前經網上取消。`,
    `在任何一個交易日的指定時限內，如收到同一參賽者帳戶下多於一項有關基金轉換指示（「指定基金轉換」或「單次投資組合重組」，如適用） 或更改未來投資指示的同類型電子指示，則只會處理最後一項收到的電子指示。`,
    `收到同一帳戶下的基金轉換電子指示將按遞交時間順序處理。如一項基金轉換指示轉入某項基金，其後另一項基金轉換指示轉出相同的基金，前項指示的轉入將包括在此基金的結餘內以處理該其後另一項指示。`,
    `單次投資組合重組是一種基金轉換指示，使您可就MPF積FUN賽帳戶的總結餘，按指定的投資選擇的目標百分比重新分配。目標百分比將用以釐定每項投資選擇的目標結餘。所有有關投資選擇之間的基金轉換，將按在處理指示當天每項有關投資選擇的目標結餘及現有結餘的差額進行。倘若為轉入預設投資策略，贖回的款項將按照MPF積FUN賽的條款及細則中提及的預設投資策略降低風險列表的配置百分比，投資於預設投資策略的成分基金。倘若從預設投資策略轉出，從預設投資策略的每項成分基金贖回的單位將以轉出預設投資策略的累算權益百分比而釐定。`,
]

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            root: {
                color: '#01a758',
                padding: 0,
                alignItems: 'start',
                "& .MuiSvgIcon-root": {
                    height: 30,
                    width: 30,
                    alignItems: 'start',
                    top: 0,
                },
                '&$checked': {
                    color: '#01a758'
                },
                '&::hover': {
                    opacity: 0
                },
            },
            checked: {}
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

class ManageFunds0 extends Component {

    state = {
        instructionType: 'existing',
    };

    componentDidMount() {
        const { history, i18n, t } = this.props;
        const param = {
            permission_name: 'switch fund'
        }
        apiTask.getTaskPermission(param).then(obj => {
            if (!obj.data[0].is_permitted) {
                alert(t("ManageFunds:switchFundDisallowMessage"));
                history.push('/' + i18n.language + `/index`)
            }
        })
    }

    // BUTTON FUNCTION

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/index');
    }

    handleChange = e => {
        this.setState({ instructionType: e.target.value });

        // const svgIcons = document.getElementsByClassName('PrivateRadioButtonIcon-layer-23');
        // if (svgIcons.length > 0) {
        //     svgIcons[0].classList.add('MuiSvgIcon-checked');
        // }
    }

    handleSubmit = e => {
        const { instructionType } = this.state;
        const { i18n } = this.props;
        if (instructionType === 'existing') {                
            this.props.history.push('/' + i18n.language + '/manage-funds/1', {instructionType: instructionType});
        } else {
            this.props.history.push('/' + i18n.language + '/manage-funds/2', {instructionType: instructionType});
        }
    }

    render() {
        const classes = this.props.classes;
        const { t, i18n } = this.props;
        const { instructionType } = this.state;
        const isChinese = i18n.language === 'zh-HK';
        const futureInvestDesciption = isChinese ? cnFutureInvestmentDescription : engFutureInvestmentDescription;
        const importantNotes = isChinese ? cnImportantNotes : engImportantNotes;
        return (                    
            <div>
                <Flex className="main__container fund-manager">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("ManageFunds:manageFunds")}
                            </Flex>
                        </Flex>
                        <Flex width="100%" justifyContent="center">
                            <Flex flex={1} flexDirection="column" justifyContent="start" className="container" maxWidth={1000}>
                                <Flex flex={1} justifyContent="start" className="section-title large-font-size">
                                    {t("ManageFunds:selectInstructionType")}
                                </Flex>
                                <Flex flex={1} flexDirection="column">
                                    <Flex flex={1} justifyContent="start" className="section-type">                            
                                        {t("ManageFunds:forExistingAssets")}
                                    </Flex>
                                    <Flex flex={1} flexDirection="row" justifyContent="start" className="section-selection">
                                        <Flex minWidth={40}>
                                            <Radio disableRipple checked={instructionType === 'existing'} value="existing" onChange={this.handleChange} className={`${classes.root} ${classes.checked}`} style={{ backgroundColor: 'transparent' }} />
                                        </Flex>
                                        <Flex flexDirection="column">
                                            <Flex  className="selection-title bold">
                                                {`${t("ManageFunds:switchFunds")}`}
                                            </Flex>    
                                            <p className="selection-content small-font-size">
                                                {t("ManageFunds:switchExistingFundDescription")}
                                                {/* <a href={`https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/switch-funds-pf-how-it-works.html`} target="_blank" rel="noopener noreferrer" className='hyperlink bold link'>{t("Common:General.learnMore")}</a> */}
                                            </p>    
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex flex={1} flexDirection="column">
                                    <Flex flex={1} justifyContent="start" className="section-type">                            
                                        {t("ManageFunds:forNewMonies")}
                                    </Flex>
                                    <Flex flex={1} flexDirection="row" justifyContent="start" className="section-selection">
                                        <Flex minWidth={40}>
                                            <Radio disableRipple checked={instructionType === 'new'} value="new" onChange={this.handleChange} className={`${classes.root} ${classes.checked}`} style={{ backgroundColor: 'transparent' }} />
                                        </Flex>
                                        <Flex flexDirection="column">
                                            <Flex  className="selection-title bold">
                                                {t("ManageFunds:changeFutureInvestments")}
                                            </Flex>    
                                            <p className="selection-content small-font-size"> 
                                                {futureInvestDesciption}
                                                {/* <a href={`https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/change-future-investments-pf.html`} target="_blank" rel="noopener noreferrer" className='hyperlink bold link'>{t("Common:General.learnMore")}</a> */}
                                            </p>    
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex className="black-text small-font-size" maxHeight="30vh" overflowY="scroll" backgroundColor="white" padding="1rem" mt="1rem" flexDirection="column" mb="1rem" style={{ border: '1px solid #e0e0e0'}}>
                                    {t("ManageFunds:importantNotes")}
                                    <Flex flexDirection="column" mt="1rem" className="light-black">
                                        {importantNotes.map((note, index) => 
                                            <Flex flexDirection="row">
                                                <Flex flex={1/15} maxWidth={30}>{index+1}.</Flex>
                                                <Flex flex={1}>{note}</Flex>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                                <Flex flex={1} className="button-wrapper">
                                    <Button disabled={!instructionType} onClick={this.handleSubmit} className="primary-button m0 p1rem" style={{ maxWidth: '100%', background: '#ec6453', color: 'white', border: 'none', borderRadius: 'none' }}>{t("Common:Button.continue")}</Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(ManageFunds0))));
