// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { get } from 'lodash-es';
import { AgGridReact } from '@ag-grid-community/react';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import { getDatasource, defaultColumnDef } from '../../utils/AgGridUtils';

import {apiStore} from "../../Api/_ApiStore";

const ID_FIELD_NAME = 'report_type_id';

const columnDefCache = {};
function getColumnDefs(language) {
    let columnDefs = columnDefCache[language];
    if (!columnDefs) {
        columnDefs = [{
            field: 'name',
            headerName: 'Report',
            cellRenderer: function(params) {
                return <Link push to={'/' +
                    encodeURIComponent(language) +
                    '/report/' +
                    encodeURIComponent(get(params, ['data', ID_FIELD_NAME]))
                }
                >
                    {params.value}
                </Link>;
            }
        }, {
            field: 'description',
            headerName: 'Description',
        }];
        columnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

function getRowId(params) {
    return get(params, ['data', ID_FIELD_NAME]);
}

class ReportTypeManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowCount: 0,
            dataSource: getDatasource('report_types'),
        };
    }

    handleLogout() {
        this.props.logoutP();
    }

    render() {
        const { dataSource } = this.state;
        return <Container>
            <h2>Report</h2>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                rowModelType="infinite"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={getColumnDefs(this.props.i18n.language)}
                datasource={dataSource}
            ></AgGridReact>
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportTypeManagement)));