// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox';
import { styled } from '@material-ui/styles';
import moment from 'moment';

import { apiTradeHistories } from '../../Api/_ApiTradeHistories';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import '../../css/10TradeHistory/_transaction.scss';
import { apiFunds } from '../../Api/_ApiFunds';

const HistoryItem = styled(Flex)({
    border: 1,
    borderStyle: 'solid',
    borderColor: '#33A35B',
    color: '#33A35B',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    cursor: 'pointer'
});

const HistoryList = props => {
    const { t, onClick, tradeHistories, dateFormat, locale, cancelTradeHistory } = props;
    return tradeHistories.map((history, index) => {
        let description = '';
        if (history.title === 'futureInvestment') {
            if (history.status === 'success') {
                description = t("TradeHistory:investmentInstructionOfFutureContributions");
            } else {
                description = t("TradeHistory:contributionAllocation");
            }
        } else if (history.title === 'fundSwitch') {
            if (history.instructionHistories.length > 0 && history.instructionHistories[0].is_one_time_rebalance) {
                description = `${t("TradeHistory:fundSwitchingInstruction")} - ${t("ManageFunds:oneTimeRebalancing")}`
            } else {
                description = `${t("TradeHistory:fundSwitchingInstruction")} - ${t("TradeHistory:fundToFundsSwitching")}`
            }
        } else if (history.title === 'virtualCapitalReward') {
            description = t("TradeHistory:virtualCapitalReward");
        } else if (history.title === 'retirementIncomeFundBonusUnit') {
            description = t("TradeHistory:retirementIncomeFundBonusUnit");
        } else if (history.title === 'interest') {
            description = t("TradeHistory:interest");
        }
        return index !== tradeHistories.length - 1 && (
            <Flex key={index} flexDirection={"column"} mb="2rem" fontSize="1rem">
                <Flex flexDirection={"column"} mb="0.8rem">
                    <Flex fontWeight={"bold"}>
                        {t("TradeHistory:transactionSubmit")}
                    </Flex>
                    <Flex color={"gray"}>
                        {moment(history.dateTime).locale(locale).format(dateFormat)}
                    </Flex>
                </Flex>
                <Flex flexDirection={"column"} mb="0.8rem">
                    <Flex fontWeight={"bold"}>
                        {t("TradeHistory:transactionDes")}
                    </Flex>
                    <Flex>
                        <div onClick={() => onClick(history)} style={{ color: '#33A35B', textDecoration: 'underline', 'text-underline-offset': '3px', 'text-decoration-thickness': '1px', cursor: 'pointer', textDecorationColor: '#33A35B'}}>
                            {description}
                        </div>
                    </Flex>
                </Flex>
                <Flex flexDirection={"column"} mb="0.8rem">
                    <Flex fontWeight={"bold"}>
                        {t("TradeHistory:status")}
                    </Flex>
                    <Flex color={"gray"}>
                        {t(`TradeHistory:${history.status}`)}
                    </Flex>
                </Flex>
                {history.status === 'pending' && <Flex flexDirection={"column"} mb="0.8rem">
                    <Flex fontWeight={"bold"}>
                        {t("TradeHistory:request")}
                    </Flex>
                    <Flex>
                        <HistoryItem onClick={()=>cancelTradeHistory(history.tradeHistoryId)}>
                            {t("TradeHistory:cancel")}
                        </HistoryItem>
                    </Flex>
                </Flex>}



                {/*<Flex onClick={() => onClick(history)}>*/}
                {/*    <HistoryItem>*/}
                {/*        {history.title}*/}
                {/*    </HistoryItem>*/}
                {/*    <HistoryItem paddingLeft="1rem" paddingRight="0.5rem">*/}
                {/*        {moment(history.dateTime).locale(locale).format(dateFormat)}*/}
                {/*    </HistoryItem>*/}
                {/*</Flex>*/}
            </Flex>
        )
    });
}

class TransactionLog extends Component {

    state = {
        tradeHistories: [],
    };

    _getUserTradeHistories = () => {
        const body = {
            user: this.props.auth.userInfo.username,
            'fund_type[ne]': 'interest',
            '$expand': 'instruction_histories/switch_in_fund,instruction_histories/switch_out_fund',
            '$orderby': 'createddate desc'
        }
        apiTradeHistories.getUserTradeHistories(body).then(obj => {
            if (obj && obj.status === 200) {
                const allHistories = obj.data.map(item => {
                    const history = {
                        title: item.fund_type,
                        dateTime: item.createddate,
                        instructionHistories: item.instruction_histories,
                        tradeHistoryId: item.trade_history_id,
                        status: item.status
                    };
                    return history;
                })
                this.setState({ tradeHistories: allHistories });
            }
        })
    }

    componentDidMount() {
        this._getUserTradeHistories();
    }

    // BUTTON FUNCTION

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/index');
    }

    handleClick = (history) => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/trade-history/2', { history: history });
    }

    cancelTradeHistory = (tradeHistoryId) => {
        // const params = {
        //     trade_history: tradeHistoryId
        // }
        apiTradeHistories.cancelTradeHistory(tradeHistoryId, {is_cancel_instruction: true}).then(res => {
            if (res) {
                window.location.reload();
                // apiFunds.getInstructionHistories(params).then(obj => {
                //     if (obj && obj.status === 200) {
                //         const instructionHistories = obj.data;
                //         instructionHistories.forEach(instruction => {
                //             const body = {
                //                 status: 'cancelled'
                //             }
                //             apiFunds.cancelInstructionHistory(instruction.instruction_history_id, body)
                //         })
                //     }
                // }).then(()=> window.location.reload());
            }
        })
    }

    render() {
        const { t, i18n } = this.props;
        const { tradeHistories } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const locale = isChinese === 'zh-HK' ? 'zh-hk' : 'en';
        const dateFormat = isChinese ? 'YYYY年M月D日 HH:mm' : 'D MMM, YYYY HH:mm';
        return (
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("TradeHistory:transactionLog")}
                            </Flex>
                        </Flex>
                        <Flex width="100%" justifyContent="center">
                            <Flex flexDirection="column" width="100%" backgroundColor={'white'} maxWidth={700} padding={'20px'}>
                                <HistoryList onClick={this.handleClick} tradeHistories={tradeHistories} dateFormat={dateFormat} locale={locale} t={t} cancelTradeHistory={this.cancelTradeHistory} />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TransactionLog)));
