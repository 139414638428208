import {api} from './_ApiFactoryWithHeader';

export const apiFactory = {
    getList: (params, cancelToken) => api.get('admin/factories', params, null, cancelToken),

    getDetail: (id, params) => api.get(`factories/${encodeURIComponent(id)}`, params, null, null),

    create: (body) => {
        return api.post('factories', body);
    },

    update: (id, body) => {
        return api.put(`factories/${id}`, body);
    },

    delete: (ids) => {
        return api.delete(`factories?factory_id%5Bin%5D=${encodeURIComponent(ids)}`, null, null, null);
    },
};
