import {api} from './_ApiFactoryWithHeader';

export const apiMiniGames = {

    getAllMiniGames: (param) => api.get(`mini_games`, param, null, null),
    
    getMiniGameQuizzess: (miniGameId) => {
        const url = 'mini_game_quizzes';
        const param = {
            'mini_game': miniGameId,
            '$orderby': 'sequence'
        }
        return api.get(url, param, null, null);
    },

    getMiniGameQuizAnswers: (miniGameQuizId) => {
        const url = 'mini_game_quiz_answers';
        const param = {
            'mini_game_quiz': miniGameQuizId,
            '$orderby': 'sequence'
        }
        return api.get(url, param, null, null);
    },

    getUserMiniGames: (param) => api.get(`user_mini_games`, param, null, null),

    completeMiniGame: (param) => api.post(`user_mini_games`, param, null, null)
};
