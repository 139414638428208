// Essential for all components
import React, { Component, useState, useEffect } from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

// Api
import {logout} from "../../Redux/Action/authAction";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close';

import {clearLoginData} from "../../utils/AuthService";
import AppBar from "@material-ui/core/AppBar";
import { Divider, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import manageFundsIcon from '../../images/manage-funds-icon.png';
import miniGameIcon from '../../images/mini-game-icon.png';
import newsEducationIcon from '../../images/news-icon.png';
import rankingIcon from '../../images/ranking-icon.png';
import badgeIcon from '../../images/badge-icon.png';
import rewardIcon from '../../images/reward-icon.png';
import tradeHistoryIcon from '../../images/trade-history-icon.png';
import howToPlayIcon from '../../images/how-to-play-icon.png';
import prizeIcon from '../../images/prize-icon.png';
import referFriendIcon from '../../images/refer-friend-icon.png';
import editProfileIcon from '../../images/edit-profile-icon.png';
import supportIcon from '../../images/support-email.png';
import languageIcon from '../../images/language-icon.png';
import logoutIcon from '../../images/logout-icon.png';
import { apiTask } from '../../Api/_ApiTask';
import { get } from 'lodash-es';
import { handleReferral } from '../../utils/Referral';
import '../../css/00Home/_landingHeader.scss';
import { apiAuth } from '../../Api/ApiAuth';
import { Flex } from 'reflexbox';

const drawerWidth = 260;
const styles = theme => ({
    appBar: {
        // marginLeft: drawerWidth,
        // [theme.breakpoints.up('sm')]: {
        //     width: '100%',
        // },
        // maxWidth: 1300,
        zIndex: 40,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between',
        backgroundColor: 'transparent',
        // fontFamily: 'NotoSansCJKtc-Bold !important'
        position: 'absolute',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    typography: {
        flexGrow: 1,
        align: "center"
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    list: {
        width: '100%',
        height: '100vh'
    },
    listItemText:{
        fontSize:'0.8vw',
    },
    // button: {
    //     backgroundColor: 'black',
    //     height: '50vw',
    //     borderRadius: '4vw',
    //     color: 'white',
    //     borderStyle: 'solid',
    //     fontSize: '1vw',
    //     "&:hover": {
    //         backgroundColor: "black"
    //     }
    // },
});

const listIcon = {
    'funds': manageFundsIcon,
    'games': miniGameIcon,
    'news': newsEducationIcon,
    'ranking': rankingIcon,
    'badges': badgeIcon,
    'rewards': rewardIcon,
    'history': tradeHistoryIcon,
    'play': howToPlayIcon,
    'prizes': prizeIcon,
    'refer': referFriendIcon,
    'profile': editProfileIcon,
    'support': supportIcon,
    'language': languageIcon,
    'logout': logoutIcon,
}

const menuList = [
    {
        title: 'manageFunds',
        icon: 'funds',
        page: 'manage-funds/0',
    },
    {
        title: 'miniGames',
        icon: 'games',
        page: 'mini-game',
    },
    {
        title: 'newsAndEducation',
        icon: 'news',
        page: 'news',
    },
    {
        title: 'myRanking',
        icon: 'ranking',
        page: 'ranking',
    },
    {
        title: 'myBadges',
        icon: 'badges',
        page: 'badge',
    },
    {
        title: 'myRewards',
        icon: 'rewards',
        page: 'reward/1',
    },
    {
        title: 'tradeHistory',
        icon: 'history',
        page: 'trade-history/1',
    },
    {
        title: 'howToPlay',
        icon: 'play',
        page: 'how-to-play',
    },
    {
        title: 'prizes',
        icon: 'prizes',
        page: 'prize',
    },
    {
        title: 'referToFriends',
        icon: 'refer',
        func: 'refer-friends',
    },
    {
        title: 'editProfile',
        icon: 'profile',
        page: 'edit-profile',
    },
    {
        title: 'supportEmailForEnquiries',
        icon: 'support',
        page: 'support',
    },
    {
        title: 'language',
        icon: 'language',
        func: 'change-language',
    },
    {
        title: 'logout',
        icon: 'logout',
        func: 'logout',
    },
]

const MobileDropdownMenu = ({ t, handleClickTitle, handleClickSection, windowWidth }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = (section) => {
        setOpen(!open);
        if (section) {
            handleClickSection(section);
        }
    };

    return (
        <Flex>
            <IconButton onClick={()=>handleToggle()} style={{ color: 'white', padding: windowWidth > 539 ? 10 : 5 }}>
                <MenuIcon />
            </IconButton>
            <Drawer className='dropdownToggle' anchor="top" open={open} onClose={handleToggle}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: 'center', backgroundColor: "#6FC282", minHeight: 56, padding: '0 3vw' }}>
                    <div>
                        <IconButton style={{ color: 'white', padding: 5}}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <div>
                        <IconButton onClick={()=>handleToggle()} style={{ color: 'white', padding: 5 }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className='dropdownTitle' onClick={()=>{handleToggle('introduction')}} style={{ cursor: 'pointer', padding: '1.5vw 2vw' }}>
                    <a href="#introduction" className='dropdownContent' style={{ letterSpacing: '2px', fontSize: '1rem' }}>{t('Common:General.introduction')}</a>
                </div>
                <div className='dropdownTitle' onClick={()=>handleToggle('howToPlay')} style={{ cursor: 'pointer', padding: '1.5vw 2vw' }}>
                    <a href="#howToPlay" className='dropdownContent' style={{ letterSpacing: '2px', fontSize: '1rem' }}>{t('Common:General.howToPlay')}</a>
                </div>
                <div className='dropdownTitle' onClick={()=>handleToggle('prizeDesk')} style={{ cursor: 'pointer', padding: '1.5vw 2vw' }}>
                    <a href="#prizeDesk" className='dropdownContent' style={{ letterSpacing: '2px', fontSize: '1rem' }}>{t('Common:General.prizes')}</a>
                </div>
                <div className='dropdownTitle' onClick={()=>handleToggle('tAndC')} style={{ cursor: 'pointer', padding: '1.5vw 2vw' }}>
                    <a href="#tAndC" className='dropdownContent' style={{ letterSpacing: '2px', fontSize: '1rem' }}>{t('Common:General.tAndC')}</a>
                </div>
                <div style={{height: "100vh"}}>

                </div>
            </Drawer>
        </Flex>
    );
};

class LandingHeader extends Component {
    state = {
        anchorEl: null,
        openMenu: false,
        login: null,
    };

    handleToggle = () => {
        this.setState(state => ({ openMenu: !state.openMenu }));
    };

    handleClose = (event) => {
        this.setState({ openMenu: false });
    };

    goToProfile = () => {
        const { i18n } = this.props;
        this.props.history.push(`/${i18n.language}/edit-profile`);
        this.setState({ openMenu: false });
    };

    logOut = () => {
        this.setState({ openMenu: false, anchorEl: null });
        clearLoginData().then(() => {
            this.props.history.push('/');
        });
        this.props.logoutP();
    };


    handleClick = (item) => {
        const { i18n, history, auth, t } = this.props;
        const { page, func } = item;
        if (page) {
            if (page.includes('manage-fund')) {
                const param = {
                    permission_name: 'switch fund'
                }
                apiTask.getTaskPermission(param).then(obj => {
                    if (obj.data[0].is_permitted) {
                        history.push('/' + i18n.language + `/${page}`)
                    } else {
                        alert(t("ManageFunds:switchFundDisallowMessage"));
                    }
                })
            } else {
                history.push(`/${i18n.language}/${page}`);
            }
        } else {
            if (func === 'logout') {
                this.logOut();
            } else if (func === 'change-language') {
                this.handleChangeLanguage();
            } else if (func === 'refer-friends') {
                const user = get(auth, 'userInfo');
                const policyNumber = get(user, 'policy_number');
                handleReferral(policyNumber, i18n.language);
            }
            this.handleToggle();
        }
    };

    handleChangeLanguage = () => {
        const { i18n, history } = this.props;
        const { pathname, state, search } = get(history, 'location');
        const newPath = pathname.split(i18n.language === 'zh-HK' ? 'zh-HK/' : 'en-US/')[1];
        if (newPath) {
            i18n.changeLanguage(i18n.language === 'zh-HK' ? 'en-US' : 'zh-HK').then(() => {
                this.handleClose();
                this.props.history.push(`/${i18n.language}/${newPath}${search ? search : ''}`, state);
                window.location.reload();
            });
        }
    };

    handleClickTitle = () => {
        const { history, i18n, auth } = this.props;
        const page = auth.auth ? 'index' : '';
        this.setState({ openMenu: false });
        history.push('/' + i18n.language + `/${page}`);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this._getTaskPermission();
    }

    _getTaskPermission = () => {
        const params = {
            'permission_name[in]': 'login'
        }
        return apiTask.getTaskPermission(params).then(obj => {
            if (obj && obj.data.length > 0) {
                obj.data.forEach(task => {
                    if (task.permission_name === 'login') {
                        this.setState({ login: task.is_permitted });
                    }
                })
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    handleClickSection = (section) => {
        const { i18n, history } = this.props;
        history.push(`/${i18n.language}/landing#${section}`)
    }

    list = (t) => {
        const { classes, i18n } = this.props;
        return (
            <div>
                <Toolbar style={{ width: '100%', backgroundColor: '#343c50', justifyContent: 'space-between', height: '100px' }}>
                    <Typography component={'span'} style={{ color: 'white' }}>
                        <div onClick={this.handleClickTitle} style={{cursor: 'pointer'}}>
                            <div>{t("Common:General.mpfFunGame")}</div>
                            <div style={{fontSize: '0.8rem'}}>{t("Common:General.empoweredByThinkAHeadLimited")}</div>
                        </div>
                    </Typography>
                    <Typography style={{ flexGrow: 0 }}>
                        <IconButton onClick={this.goToProfile}>
                            <PersonIcon style={{ color: 'white' }} />
                        </IconButton>
                        <IconButton onClick={this.handleToggle}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Typography>
                </Toolbar>
                <div className={classes.list} role="presentation" onClick={this.handleToggle} onKeyDown={this.handleToggle}>
                    <List>
                        {menuList.map((menuItem, index) => {
                            const Label = () => (menuItem.title === 'language' ?
                                <div>
                                    <span style={{ textDecoration: i18n.language === 'en-US' ? 'underline' : 'none' }}>English</span>&nbsp;/&nbsp;<span style={{ textDecoration: i18n.language === 'zh-HK' ? 'underline' : 'none' }}>中文</span>
                                </div> :
                                t(`Common:MenuItem.${menuItem.title}`));
                            return (
                                <div key={index} onClick={() => this.handleClick(menuItem)}>
                                    <ListItem button>
                                        <img src={listIcon[menuItem.icon]} alt="item-icon" width={20} height={20} style={{ marginLeft: '0.5rem', marginRight: '1rem' }} />
                                        <ListItemText classes={{ primary: classes.listItemText }} primary={<Label />} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            );
                        })}
                    </List>
                </div>
            </div>
        );
    };

    render() {
        const { classes, t, i18n, auth, includeText, history } = this.props;
        const { openMenu, login } = this.state;

        let { windowWidth } = this.state;
        if (!windowWidth) {
            windowWidth = window.innerWidth;
        }

        return (
            <AppBar className={classes.appBar} elevation={0}>
                <Toolbar className="toolbar" style={{ width: '100%', justifyContent: 'center', padding: '0 3vw', alignItems: 'center', height: 70, maxWidth: 1300 }}>
                {/* <Toolbar className="toolbar" style={{ width: '100%', justifyContent: 'space-between', marginTop: '1vw', padding: '0 3vw', fontFamily: 'jf-openhuninn' }}> */}

                    <Typography style={{ display: 'flex', flex: windowWidth > 539 ? 2 : 1, flexDirection: windowWidth > 539 ? 'row' : 'column' }} component="span">
                        {windowWidth > 539 ? (
                            <>
                                <Flex flex={2.5} flexDirection="column" style={{ fontWeight: "bold" }}>
                                {/* <Flex flex={2.5} onClick={this.handleClickTitle} style={{ fontWeight: "bold", cursor: 'pointer', fontFamily: 'jf-openhuninn' }}> */}
                                    <div className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`}>{t('Common:General.mpfFunGame1')}</div>
                                    {/* {i18n.language === 'en-US' && <div style={{ fontSize: windowWidth > 1300 ? '18px' : '1.3vw' }} className="english-title">{t('Common:General.mpfFunGame2')}</div>} */}
                                    {i18n.language === 'en-US' && <div className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`}>{t('Common:General.mpfFunGame2')}</div>}
                                </Flex>
                                {includeText && <Flex flex={ i18n.language === 'en-US' ? 5 : 3 } justifyContent="space-evenly" style={{ position: 'relative', left: i18n.language === 'en-US' ? '-0.5vw' : '-2.25vw' }}>
                                    <Flex onClick={()=>this.handleClickSection('introduction')} style={{ cursor: 'pointer', alignItems: 'center' }}>
                                        <a href="#introduction" className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`} >{t('Common:General.introduction')}</a>
                                    </Flex>
                                    <Flex onClick={()=>this.handleClickSection('howToPlay')} style={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}>
                                        <a href="#howToPlay" className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`} >{t('Common:General.howToPlay')}</a>
                                    </Flex>
                                    <Flex onClick={()=>this.handleClickSection('prizeDesk')} style={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}>
                                        <a href="#prizeDesk" className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`} >{t('Common:General.prizes')}</a>
                                    </Flex>
                                    <Flex onClick={()=>this.handleClickSection('tAndC')} style={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}>
                                        <a href="#tAndC" className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`} >{t('Common:General.tAndC')}</a>
                                    </Flex>
                                </Flex>}
                            </>
                        ) : (
                            <Flex justifyContent="flex-start">
                                <MobileDropdownMenu t={t} handleClickTitle={this.handleClickTitle} handleClickSection={this.handleClickSection} windowWidth={windowWidth} />
                            </Flex>
                        )}
                    </Typography>
                    {windowWidth < 539 && <Flex flexDirection="column" alignItems="center" style={{ fontWeight: "bold" }}>
                        <div className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`}>{t('Common:General.mpfFunGame1')}</div>
                        {i18n.language === 'en-US' && <div className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`}>{t('Common:General.mpfFunGame2')}</div>}
                    </Flex>}
                    <Typography style={{ display: 'flex', flex: windowWidth > 539 ? 0.5 : 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {auth.auth ? (
                            <IconButton onClick={this.goToProfile}>
                                <PersonIcon style={{ color: 'white', fontSize: windowWidth > 539 ? '1.5vw' : '4.5vw'}} />
                            </IconButton>
                        ) : (
                            <IconButton onClick={this.handleChangeLanguage} style={{ padding: windowWidth > 539 ? 10 : 5 }}>
                                <LanguageIcon style={{ color: 'white', fontSize: windowWidth > 1300 ? 30 : windowWidth > 539 ? '2vw' : '4.5vw'}} />
                            </IconButton>
                        )}

                        {auth.auth ? (
                            <div></div>
                            // <IconButton onClick={this.handleToggle}>
                            //     <MenuIcon style={{ color: 'white' }} />
                            // </IconButton>
                        ) : (
                            <Button
                                style={{
                                    display: login ? 'flex' : 'none',
                                    // letterSpacing: '2px',
                                    // fontSize: windowWidth > 1300 ? 15 : windowWidth > 539 ? '1vw' : '2vw',
                                    // height: windowWidth > 539 ? '2.6vw' : '6vw',
                                    minWidth: windowWidth > 539 ? 'auto' : '10vw',
                                    padding: windowWidth > 1300 ? '0.2rem 2rem' : `0.2vw ${windowWidth > 539 ? '3vw' : '3vw'}`,
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '2rem',
                                }}
                                variant="contained"
                                // className='login-page-button'
                                // classes={{ root: classes.button }}
                                onClick={() => {
                                    this.props.history.push(`/${i18n.language}/login`);
                                }}
                            >
                                <div className={`${i18n.language === 'en-US' ? 'english-title' : 'chinese-title'}`}>
                                    {t('LoginRegister:login:Title')}
                                </div>
                            </Button>
                        )}
                        <Drawer anchor="top" open={openMenu} onClose={this.handleToggle}>
                            {this.list(t)}
                        </Drawer>
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }
}

LandingHeader.propTypes = {
    siteType: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles, { withTheme: true })(LandingHeader))));
