import {api} from './_ApiFactoryWithHeader';

export const apiRewards = {

    getAllRewards: (param) => api.get(`rewards`, param, null, null),

    getUserRewards: (param) => api.get(`user_rewards`, param, null, null),

    createUserRewards: (body) => api.post('user_rewards', body),
    
    claimReward: (body) =>  api.put(`claim_reward`, body),

};
