// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import { Flex } from 'reflexbox'

import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png"
import { DISTable } from '../../components/TandC';

const engDescription = [
    <div>The fund prices provided in this game are the reference prices.</div>,
    <div>At any time, the virtual MPF investment portfolio must include <span style={{ color: 'rgb(0,167,88)' }}>at least 2 different constituent funds.</span></div>,
    <div>Participants can accomplish 6 missions to earn the relevant badges in "My Mission" of the Game website. The first 100 Participants who (i) have completed specific mission(s) and (ii) earn the relevant badges will be eligible for HK$1,000 virtual MPF assets after completing each mission. The virtual MPF assets obtained will be allocated to the Interest Fund in the game account.</div>,
    <div>On 1 November 2023, each participant will receive a virtual MPF asset of HK$100,000, which will be allocated to the interest fund in his/her game account.</div>,
    <div>Participants can change their investment instructions anytime. A virtual MPF contribution of HK$3,000 will be injected to each game account on the 3rd working day of each month (i.e. 3 November, 5 December 2023 and 4 January 2024). All injected amount will be invested according to its investment instruction for future contributions.</div>,
    <div>If no investment instruction was received, the virtual MPF contribution in the game account will be invested in the "Default Investment Strategy" by default basing on the Participant's age inputted during account registration for allocation* but without automatic reduction of investment risk according to participants' age throughout the Game.</div>,
    <div>During the Game Period, the monthly reference dividend amount per unit of the Retirement Income Fund is fixed at HK$0.03. For record date, ex-dividend date and distribution date, please refer to the schedule below. The declared interest rate of Interest Fund is fixed at 0.75% per annum.</div>,
    <div>For details, please refer to the terms and conditions.</div>,
]
const cnDescription = [
    <div>是次遊戲提供之基金價格均為參考價格。</div>,
    <div>無論任何時候，虛擬強積金投資組合必須包含<span style={{ color: 'rgb(0,167,88)' }}>至少2隻不同的成分基金</span>。</div>,
    <div>參賽者可於遊戲網頁內的「我的任務」頁面完成6項任務以獲取相關徽章。<span style={{ color: 'rgb(0,167,88)' }}>首 100 名(i)完成指定任務並(ii)獲得相關徽章的參賽者，可就每項任務獲得HK$1,000虛擬強積金資產</span>，而獲發的虛擬強積金資產將分配至遊戲帳戶中的利息基金。</div>,
    <div>於2023年11月1日，每名參賽者將獲HK$100,000虛擬強積金資產，並分配至其遊戲帳戶中的利息基金。</div>,
    <div>參賽者可隨時更改投資指示 。每個遊戲帳戶將於每月第3個工作天(即2023年11月3日，12月5日及2024年1月4日) 獲注入HK$3,000虛擬強積金供款，所注入的金額將根據其未來供款的投資指示進行投資。</div>,
    <div>在沒有投資指示的情況下，遊戲帳戶內的虛擬強積金供款將會自動投資於「預設投資策略」，並根據參賽者於登記帳戶時所輸入的年齡作配置*，但在遊戲過程中不會隨參賽者年齡自動降低投資風險。</div>,
    <div>於遊戲進行期間，退休收益基金的每月每單位參考派息金額為HK$0.03。有關其記錄日、除息日及派息日請參考以下時間表。而利息基金的公佈利率為年利率0.75%。</div>,
    <div>詳情請參閱條款及細則。</div>
]

const RetirementIncomeFundTable = (props) => {
    const { t } = props;
    return (
        <table style={{ border: '1px solid black', width: '98%', marginBottom: '1rem' }}>
            <tr>
                <td style={{ textAlign: 'center', border: '1px solid black', background: 'lightgray', fontWeight: 'bold' }}>{t("HowToPlay:recordDate")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black', background: 'lightgray', fontWeight: 'bold' }}>{t("HowToPlay:exDividendDate")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black', background: 'lightgray', fontWeight: 'bold' }}>{t("HowToPlay:distributionDate")}</td>
            </tr>
            <tr>
                <td style={{ textAlign: 'center', border: '1px solid black' }}>{t("HowToPlay:20November2023")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black' }}>{t("HowToPlay:21November2023")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black' }}>{t("HowToPlay:27November2023")}</td>
            </tr>
            <tr>
                <td style={{ textAlign: 'center', border: '1px solid black' }}>{t("HowToPlay:18December2023")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black' }}>{t("HowToPlay:19December2023")}</td>
                <td style={{ textAlign: 'center', border: '1px solid black' }}>{t("HowToPlay:27December2023")}</td>
            </tr>
            <tr>
                <td colSpan={3} style={{ textAlign: 'center', padding: '2px' }}>{t("HowToPlay:scheduledDatesFor2024")}</td>
            </tr>
        </table>
    )
}


const engEndingDescription = <span>At the end of the game, the <span style={{ color: '#00a759' }}>top 5 participants</span> with the <b>highest account balances</b> will have a chance to win grand prizes!</span>;
const cnEndingDescription = '在遊戲完結時，帳戶結餘最多的首5名參賽者有機會贏取大獎！';

class HowToPlay extends Component {

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/index');
    }

    render() {
        const { t, i18n } = this.props;
        const description = i18n.language === 'en-US' ? engDescription : cnDescription;
        const endingDescription = i18n.language === 'en-US' ? engEndingDescription : cnEndingDescription;
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("HowToPlay:howToPlay")}
                            </Flex>
                        </Flex>
                        <Flex justifyContent="center" width="100%">
                            <Flex maxWidth={1000} width="100%" flexDirection="column">
                                <Flex flex={1} mt="2rem" justifyContent="center" className="video-container">
                                    {/* <Flex flex={1} className="video">
                                        <iframe width="100%" height="100%" src={require("../../video/how-to-play.mp4")} title="How To Play" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </Flex> */}
                                    <video autoPlay={false} style={{ width: '100%', height: '100%'}} controls poster={require('../../images/video-thumbnail.png')}>
                                        <source src={require("../../video/how-to-play.mp4")} type="video/mp4" />
                                    </video>
                                </Flex>
                                <Flex fontSize="1.3rem" mt="2rem" color="rgb(0,167,88)" fontWeight="bold">
                                    {t("HowToPlay:watchTheAboveVideoToLearnHowToPlay")}
                                </Flex>
                                <Flex mt="2rem" flexDirection="column" fontSize="1.2rem">
                                    {description.map((text, index) => <Flex mb="1rem" flexDirection="column">
                                        <Flex flexDirection="row">
                                            <Flex mr="0.5rem" minWidth={20}>{index+1}.</Flex>
                                            <Flex>{text}</Flex>
                                        </Flex>
                                        {index === 5 && <Flex marginTop="2rem" flexDirection="column">
                                            <Flex fontWeight="bold" marginBottom="1rem">*{t("HowToPlay:DISDeriskingTable")}</Flex>
                                            <DISTable t={t} />
                                        </Flex>}
                                        {index === 6 && <Flex marginTop="2rem" flexDirection="column">
                                            <Flex marginBottom="1rem">{t("HowToPlay:scheduledDatesForRetirementIncomeFund")}</Flex>
                                            <RetirementIncomeFundTable t={t} />
                                        </Flex>}
                                    </Flex>)}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(HowToPlay)));