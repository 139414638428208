// export const WEBSITE_URL = 'http://localhost:8080';
export const WEBSITE_URL = 'https://mpf-uat.joyaethercloud.com';
// export const WEBSITE_URL = 'https://mpfgame.com';
export const API_CENTRAL_URL_BASE = WEBSITE_URL + '/mpf-server';
export const API_CENTRAL_URL = API_CENTRAL_URL_BASE + '/';
export const GA_TRACKING_ID = 'UA-xxxxxxxx';

export const SCREEN_SIZE_SM = 768;
export const SCREEN_SIZE_MD = 992;
export const SCREEN_SIZE_LG = 1200;
