import {api} from './_ApiFactoryWithHeader';

export const apiBadges = {
    getAllBadges: (param) => api.get(`badges`, param, null, null),

    getUserBadges: (param) => api.get(`user_badges`, param, null, null),
    
    createUserBadges: (param) => api.post('user_badges', param),

    updateUserBadges: (param) => api.put('user_badges', param),
};
