// Essential for all components
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import '../../css/00Home/_landing.scss';

import { apiAuth } from '../../Api/ApiAuth';
import { apiTask } from '../../Api/_ApiTask';
import { CacheService } from '../../utils/CacheService';
import { clearLoginData } from '../../utils/AuthService';
import { logout } from '../../Redux/Action/authAction';
import { connect } from 'react-redux';
import { Dialog, IconButton, makeStyles } from '@material-ui/core';
import { Flex } from 'reflexbox';
import CloseIcon from '@material-ui/icons/Close';

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            root: {
                height: 'auto',
                backgroundColor: 'transparent',
                "& .MuiDialog-paperWidthSm": {
                    maxWidth: 1000
                },
                "& .MuiPaper-elevation24": {
                    boxShadow: 'none'
                },
                "& .MuiPaper-root": {
                    backgroundColor: 'transparent'
                }
            },
            closeButton: {
                width: 30,
                height: 30,
                color: 'white'
            }
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

const HowToPlayVideoDialog = (props) => {
    const { handleCloseDialog, open, classes } = props;
    return <Dialog open={open} classes={{ root: classes.root }} style={{ flexDirection: 'column' }}>
        <video autoPlay={false} style={{ width: '100%', height: '100%'}} controls poster={require('../../images/video-thumbnail.png')}>
            <source src={require("../../video/how-to-play.mp4")} type="video/mp4" />
        </video>
        <Flex width="100%" justifyContent="center">
            <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
                <CloseIcon />
            </IconButton>
        </Flex>
    </Dialog>
}

const imagePaths = {
    'en-US': {
        bannerSmall: require("../../images/NewLandingPage/banner_main_es.png"),
        bannerMedium: require("../../images/NewLandingPage/banner_main_em.png"),
        bannerLarge: require("../../images/NewLandingPage/banner_main_el.png"),
        registerButtonSmall: require("../../images/NewLandingPage/btn1_es.png"),
        registerButtonMedium: require("../../images/NewLandingPage/btn1_em.png"),
        registerButtonLarge: require("../../images/NewLandingPage/btn1_el.png"),
        dateSmall: require("../../images/NewLandingPage/icon_date_es.png"),
        dateMedium: require("../../images/NewLandingPage/icon_date_em.png"),
        dateLarge: require("../../images/NewLandingPage/icon_date_el.png"),
        ruleSmall: require("../../images/NewLandingPage/icon_rule_es.png"),
        ruleMedium: require("../../images/NewLandingPage/icon_rule_em.png"),
        ruleLarge: require("../../images/NewLandingPage/icon_rule_el.png"),
        ruleButtonSmall: require("../../images/NewLandingPage/btn2_es.png"),
        ruleButtonMedium: require("../../images/NewLandingPage/btn2_em.png"),
        ruleButtonLarge: require("../../images/NewLandingPage/btn2_el.png"),
        prizeSmall: require("../../images/NewLandingPage/icon_prize_es.png"),
        prizeMedium: require("../../images/NewLandingPage/icon_prize_em.png"),
        prizeLarge: require("../../images/NewLandingPage/icon_prize_el.png"),
        luckyDrawSmall: require("../../images/NewLandingPage/icon_draw_es.png"),
        luckyDrawMedium: require("../../images/NewLandingPage/icon_draw_em.png"),
        luckyDrawLarge: require("../../images/NewLandingPage/icon_draw_el.png"),
        luckyDrawButtonSmall: require("../../images/NewLandingPage/btn3_es.png"),
        luckyDrawButtonMedium: require("../../images/NewLandingPage/btn3_em.png"),
        luckyDrawButtonLarge: require("../../images/NewLandingPage/btn3_el.png"),
        termsSmall: require("../../images/NewLandingPage/tnc_es.png"),
        termsMedium: require("../../images/NewLandingPage/tnc_em.png"),
        termsLarge: require("../../images/NewLandingPage/tnc_el.png"),
    },

    'zh-HK': {
        bannerSmall: require("../../images/NewLandingPage/banner_main_s.png"),
        bannerMedium: require("../../images/NewLandingPage/banner_main_m.png"),
        bannerLarge: require("../../images/NewLandingPage/banner_main_l.png"),
        registerButtonSmall: require("../../images/NewLandingPage/btn1_s.png"),
        registerButtonMedium: require("../../images/NewLandingPage/btn1_m.png"),
        registerButtonLarge: require("../../images/NewLandingPage/btn1_l.png"),
        dateSmall: require("../../images/NewLandingPage/icon_date_s.png"),
        dateMedium: require("../../images/NewLandingPage/icon_date_m.png"),
        dateLarge: require("../../images/NewLandingPage/icon_date_l.png"),
        ruleSmall: require("../../images/NewLandingPage/icon_rule_s.png"),
        ruleMedium: require("../../images/NewLandingPage/icon_rule_m.png"),
        ruleLarge: require("../../images/NewLandingPage/icon_rule_l.png"),
        ruleButtonSmall: require("../../images/NewLandingPage/btn2_s.png"),
        ruleButtonMedium: require("../../images/NewLandingPage/btn2_m.png"),
        ruleButtonLarge: require("../../images/NewLandingPage/btn2_l.png"),
        prizeSmall: require("../../images/NewLandingPage/icon_prize_s.png"),
        prizeMedium: require("../../images/NewLandingPage/icon_prize_m.png"),
        prizeLarge: require("../../images/NewLandingPage/icon_prize_l.png"),
        luckyDrawSmall: require("../../images/NewLandingPage/icon_draw_s.png"),
        luckyDrawMedium: require("../../images/NewLandingPage/icon_draw_m.png"),
        luckyDrawLarge: require("../../images/NewLandingPage/icon_draw_l.png"),
        luckyDrawButtonSmall: require("../../images/NewLandingPage/btn3_s.png"),
        luckyDrawButtonMedium: require("../../images/NewLandingPage/btn3_m.png"),
        luckyDrawButtonLarge: require("../../images/NewLandingPage/btn3_l.png"),
        termsSmall: require("../../images/NewLandingPage/tnc_s.png"),
        termsMedium: require("../../images/NewLandingPage/tnc_m.png"),
        termsLarge: require("../../images/NewLandingPage/tnc_l.png"),
    }

};

class Landingpg extends Component {

    state = {
        registration: null,
        openVideoDialog: false,
    }

    componentDidMount = () => {
        const authData = CacheService.getAuthData();
        if (!authData) {
            clearLoginData();
            this.props.logoutP();
        }
        this._getTaskPermission();

        const { location } = this.props;
        const section = location.hash.replace('#', '');
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView();
        }
    }

    _getTaskPermission = () => {
        const params = {
            'permission_name[in]': 'registration'
        }
        return apiTask.getTaskPermission(params).then(obj => {
            if (obj && obj.data.length > 0) {
                obj.data.forEach(task => {
                    if (task.permission_name === 'registration') {
                        this.setState({ registration: task.is_permitted });
                    }
                })
            }
        })
    }

    handleClickTandC = () => {
        const { i18n } = this.props;
        window.open(`/${i18n.language}/lucky-draw-tandc`, '_blank');
    }

    handleClickRegistration = () => {
        const { i18n, history } = this.props;
        history.push(`/${i18n.language}/register`);;
    }

    handleClickLuckyDraw = () => {
        // window.open(`/`, '_blank');
    }

    handleClickHowToPlay = () => {
        const { i18n, history } = this.props;
        history.push(`/${i18n.language}/how-to-play`);;
    }

    handleClickVideoDialog = () => {
        this.setState({ openVideoDialog: true });
    }

    handleCloseVideoDialog = () => {
        this.setState({ openVideoDialog: false });
    }

    render() {
        const { t, i18n, classes } = this.props;
        const { registration, openVideoDialog } = this.state;

        return (
            <div className="landing-content">
                <div class={`page-landing ${i18n.language === 'en-US' ? 'landing-content-eng' : ''}`}>
                    <div class="container main">
                        <img class="media-s" src={imagePaths[i18n.language].bannerSmall} alt="banner-s" />
                        <img class="media-m" src={imagePaths[i18n.language].bannerMedium} alt="banner-m" />
                        <img class="media-l" src={imagePaths[i18n.language].bannerLarge} alt="banner-l"/>
                        {registration && <div class="btn1 pointer" onClick={this.handleClickRegistration}>
                            <img class="media-s" src={imagePaths[i18n.language].registerButtonSmall} alt="register-button-s" />
                            <img class="media-m" src={imagePaths[i18n.language].registerButtonMedium} alt="register-button-m" />
                            <img class="media-l" src={imagePaths[i18n.language].registerButtonLarge} alt="register-button-l" />
                        </div>}
                    </div>

                    <div id="introduction" class="container date">
                        <img class="media-s" src={imagePaths[i18n.language].dateSmall} alt="date-s" />
                        <img class="media-m" src={imagePaths[i18n.language].dateMedium} alt="date-m" />
                        <img class="media-l" src={imagePaths[i18n.language].dateLarge} alt="date-l" />
                    </div>

                    <div id="howToPlay" class="container rule">
                        <img class="media-s" src={imagePaths[i18n.language].ruleSmall} alt="rule-s" />
                        <img class="media-m" src={imagePaths[i18n.language].ruleMedium} alt="rule-m" />
                        <img class="media-l" src={imagePaths[i18n.language].ruleLarge} alt="rule-l" />
                        <div class="btn2 pointer" onClick={this.handleClickVideoDialog}>
                            <img class="media-s" src={imagePaths[i18n.language].ruleButtonSmall} alt="rule-button-s" />
                            <img class="media-m" src={imagePaths[i18n.language].ruleButtonMedium} alt="rule-button-m" />
                            <img class="media-l" src={imagePaths[i18n.language].ruleButtonLarge} alt="rule-button-l" />
                        </div>
                    </div>

                    <div id="prizeDesk" class="container prize">
                        <img class="media-s" src={imagePaths[i18n.language].prizeSmall} alt="prize-s" />
                        <img class="media-m" src={imagePaths[i18n.language].prizeMedium} alt="prize-m" />
                        <img class="media-l" src={imagePaths[i18n.language].prizeLarge} alt="prize-l" />
                    </div>

                    <div class="container draw">
                        <img class="media-s" src={imagePaths[i18n.language].luckyDrawSmall} alt="lucky-draw-s" />
                        <img class="media-m" src={imagePaths[i18n.language].luckyDrawMedium} alt="lucky-draw-m" />
                        <img class="media-l" src={imagePaths[i18n.language].luckyDrawLarge} alt="lucky-draw-l" />
                        <div class="btn3" onClick={this.handleClickLuckyDraw} style={{ opacity: 0.6 }}>
                            <img class="media-s" src={imagePaths[i18n.language].luckyDrawButtonSmall} alt="lucky-draw-button-s" style={{ cursor: 'auto' }} />
                            <img class="media-m" src={imagePaths[i18n.language].luckyDrawButtonMedium} alt="lucky-draw-button-m" style={{ cursor: 'auto' }} />
                            <img class="media-l" src={imagePaths[i18n.language].luckyDrawButtonLarge} alt="lucky-draw-button-l" style={{ cursor: 'auto' }} />
                        </div>
                    </div>

                    <div id="tAndC" class="container tnc">
                        <div class="tnc" onClick={this.handleClickTandC}>
                            <img class="media-s" src={imagePaths[i18n.language].termsSmall} alt="terms-s" />
                            <img class="media-m" src={imagePaths[i18n.language].termsMedium} alt="terms-m" />
                            <img class="media-l" src={imagePaths[i18n.language].termsLarge} alt="terms-l" />
                        </div>
                    </div>
                </div>
                <HowToPlayVideoDialog classes={classes} open={openVideoDialog} t={t} handleCloseDialog={this.handleCloseVideoDialog} handleClick={this.handleClickHowToPlay} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(Landingpg))));
