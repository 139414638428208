// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import {AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import stepCompleted from "../../images/step-completed.png";
import step3Green from "../../images/step-3-green.png";
import quarterly from "../../images/Landingpg/Quarterly Fund fact sheet.png";
import dealingIcon from "../../images/dealing.png";
import backIcon from "../../images/back.png";
import { apiFunds } from '../../Api/_ApiFunds';
import { apiTradeHistories } from '../../Api/_ApiTradeHistories';
import { apiUserAccount } from '../../Api/_ApiUserAccount';
import { apiTask } from '../../Api/_ApiTask';
import BottomDialog from '../../components/BottomDialog/BottomDialog';
import { get } from 'lodash-es';

const engProcessingTimeDescriptions = [
    <span>It generally takes <b>2 dealing days</b> to process your instruction. Instructions received at or before <b>4:00 p.m. Hong Kong time</b> on a dealing day will normally be carried out based on the fund price on the same dealing day (which is generally available on MPF FUN Game’s website after 2 dealing days). </span>,
    // <span>It generally takes <b>2 dealing days</b> to process your instruction. Instructions received at or before <b>4:00 p.m. Hong Kong time</b> on a dealing day will normally be carried out based on the fund price on the same dealing day (which is generally available on <a href="https://www.manulife.com.hk/en/individual/fund-price/hong-kong.html" target="_blank" rel="noopener noreferrer" className='hyperlink bold link' style={{ color: 'black' }}>MPF FUN Game’s website</a>  after 2 dealing days). </span>,
    <span>Since funds are traded through a "forward pricing" mechanism (i.e. the unit price of a fund can only be calculated after the close of market when the prices of underlying investments are available), at the time a fund switching instruction is submitted, the fund price on the same dealing day is not available.</span>
]

const cnProcessingTimeDescriptions = [
    <span>您的指示通常需時<b>兩個交易日</b>處理。在每個交易日<b>香港時間下午4時</b>或之前收到的指示在正常情況下將按同一交易日的基金價格（一般會在兩個交易日後上載於MPF積FUN賽網站）執行。</span>,
    // <span>您的指示通常需時<b>兩個交易日</b>處理。在每個交易日<b>香港時間下午4時</b>或之前收到的指示在正常情況下將按同一交易日的基金價格（一般會在兩個交易日後上載於<a href="https://www.manulife.com.hk/zh-hk/individual/fund-price/hong-kong.html" target="_blank" rel="noopener noreferrer" className='hyperlink bold link' style={{ color: 'black' }}>MPF積FUN賽網站</a>）執行。</span>,
    <span>由於基金買賣是以「未知價」方式進行（即基金單位的價格要待收市後，經過計算基金相關投資的淨資產值後才能釐定），因此在遞交轉換基金指示時，我們未能提供當天交易日的基金價格。</span>
]

const engFutureProcessingTimeDescriptions = [
    <span>Instructions normally take effect on the next dealing day if received at or before <b>4:00 p.m. Hong Kong time</b> on a dealing day. Otherwise, it will be deferred for one dealing day.</span>
]

const cnFutureProcessingTimeDescriptions = [
    <span>在每個交易日<b>香港時間下午4時</b>或之前收到的指示在正常情況下於下一個交易日生效。否則，指示將會順延至再下一個交易日生效。</span>
]

const engAlertMessages = [
    <span style={{ fontWeight: 'bold'}}>You have another instruction pending for processing</span>,
    <span></span>,
    <span>If you proceed with submitting this new instruction, that pending instruction <b>will be overridden and not be carried out</b>.</span>,
    <span>To continue to submit this new instruction, please click "<b>Continue</b>". Otherwise, please click "<b>Cancel</b>".</span>
]
const cnAlertMessages = [
    <span style={{ fontWeight: 'bold'}}>您有另一指示正等待處理</span>,
    <span></span>,
    <span>如繼續遞交此新指示，該等候指示<b>將被取代並且不會執行</b>。</span>,
    <span>如要繼續遞交此新指示，請按「<b>繼續</b>」，否則請按「<b>取消</b>」。</span>
]

const SwitchSection = (props) => {
    const { t, percentage, title, subtitle, fundSwitch, index, instructionType } = props;
    return (
        <Flex flexDirection="column" flex={1}>
            {index === 0 && <Flex flexDirection="column">
                {instructionType === 'existing' && <Flex className="black-text" mt="1rem">
                    {fundSwitch === 'out' ? `${t("ManageFunds:switchOut")}` : `${t("ManageFunds:switchIn")}`}
                </Flex>}
                <Flex className="section-header small-font-size black-text" mt="1rem" fontSize={instructionType === 'existing' ? null : '0.85rem'}>
                    <Flex flex={1} className="stripe">
                        <Flex flex={1} marginY="1rem" flexDirection="column">
                            <Flex>
                                {t("ManageFunds:fundCode")} /
                            </Flex>
                            <Flex>
                                {t("ManageFunds:fundName")}
                            </Flex>
                        </Flex>
                        <Flex flex={1} justifyContent="flex-end" alignItems="center" marginY="1rem" ml={instructionType === 'existing' ? null : 10}>
                            {t("ManageFunds:contribution")} %
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>}
            <Flex mt={index === 0 ? "2rem" : "1rem"} mb="1rem" flexDirection="row" justifyContent="space-between" alignItems="center" className="light-black small-font-size">
                <Flex flex={1} flexDirection="column">
                    {subtitle && <Flex>
                        {t(`Fund:${subtitle}`)}
                    </Flex>}
                    <Flex>
                        {t(`Fund:${title}`)}
                    </Flex>
                </Flex>
                <Flex flex={1} justifyContent="flex-end" alignItems="center">
                    <Flex className="black-text medium-font-size">{percentage}</Flex>
                    <Flex>%</Flex>
                </Flex>
            </Flex>
            <LineBreak fullWidth />
        </Flex>
    )
}

const SwitchFundList = (props) => {
    const { t, switchInFunds, switchOutFund, instructionType } = props;
    if (switchOutFund) {
        return <SwitchSection index={0} t={t} fundSwitch="out" percentage={100} title={switchOutFund.title} subtitle={switchOutFund.subtitle} instructionType={instructionType}/>
    }
    return switchInFunds.map((fund, index) => {
        return <SwitchSection key={index} index={index} t={t} fundSwitch="in" percentage={fund.switchInPercentage} title={fund.title} subtitle={fund.subtitle} instructionType={instructionType} />
    })
}

const LineBreak = (props) => {
    const { text, fullWidth } = props;
    return ( text ?
        <div className="strike" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span>{text}</span>
        </div> :
        <hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} className={fullWidth ? 'full-hr' : ''} />
    )
}

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({});
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

class ManageFunds3 extends Component {
    
    state = {
        instructionType: get(this.props.history, ['location', 'state', 'instructionType'], null),
        switchInFunds: get(this.props.history, ['location', 'state', 'switchInFunds'], []),
        switchOutFund: get(this.props.history, ['location', 'state', 'switchOutFund'], null),
        allFunds: get(this.props.history, ['location', 'state', 'allFunds'], false),
        tradeHistoryDateTime: null,
        tradeHistoryId: null,
        userTotalBalance: null,
        openDialog: false,
        openBottomDialog: false,
        submitting: false,
        submittingDialog: false,
        cancelSwitchOutFund: null
    };

    componentDidMount() {
        const { history, i18n } = this.props;
        const { instructionType, switchInFunds } = this.state;
        if (!instructionType || switchInFunds.length <= 0) {
            history.push('/' + i18n.language + '/manage-funds/0');
        }
    }

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }
    
    _calculateSwitchOutHoldingUnit = (newFund) => {
        const { instructionType, switchOutFund, allFunds, userTotalBalance } = this.state;
        const switchOutHoldingUnit = instructionType === 'existing' ? switchOutFund.unit * newFund.switchInPercentage / 100 : (userTotalBalance * newFund.switchInPercentage / 100) / newFund.unitPriceReference;
        if (instructionType === 'existing' && !allFunds) {
            return switchOutFund.fundId !== newFund.fundId ? switchOutHoldingUnit : -switchOutHoldingUnit;
        } else {
            return switchOutHoldingUnit;
        }
    }

    _calculateSwitchOutAmount = (newFund) => {
        const { instructionType, switchOutFund, allFunds, userTotalBalance } = this.state;
        const switchOutAmount = instructionType === 'existing' ? switchOutFund.amount * newFund.switchInPercentage / 100 : userTotalBalance * newFund.switchInPercentage / 100;
        if (instructionType === 'existing' && !allFunds) {
            return switchOutFund.fundId !== newFund.fundId ? switchOutAmount : -switchOutAmount;
        } else {
            return switchOutAmount;
        }
    }

    _calculateSwitchInFutureAmount = (newFund) => {
        return apiFunds.getFutureAmount().then(obj => {
            const futureAmount = obj.data.int_value;
            const switchOutAmount = futureAmount * newFund.switchInPercentage / 100;
            return switchOutAmount;
        })
    }

    _createInstructionHistory = (switchInFund, isOneTimeRebalance) => {
        const { tradeHistoryId, tradeHistoryDateTime, switchOutFund, instructionType } = this.state;
        const isAfter4PM = this.checkIsAfter4PM();
        let switchInPercentage;
        if (switchInFund) {
            if (!switchOutFund) {
                switchInPercentage = switchInFund.switchInPercentage;
            } else if (switchOutFund.fundCode !== switchInFund.fundCode) {
                switchInPercentage = switchInFund.switchInPercentage;
            } else {
                switchInPercentage = -switchInFund.switchInPercentage;
            }
        } else {
            switchInPercentage = null;
        }
        const body = {
            user: this.props.auth.userInfo.username,
            date: tradeHistoryDateTime,
            trade_history: tradeHistoryId,
            switch_in_fund: switchInFund ? switchInFund.fundId : null,
            switch_out_fund: switchOutFund ? switchOutFund.fundId : null,
            switch_in_percentage: switchInPercentage,
            status: 'pending',
            is_one_time_rebalance: isOneTimeRebalance ? true : false,
            fund_type: instructionType,
            is_next_day_instruction: isAfter4PM
        }
        return apiFunds.createInstructionHistory(body)
    }

    _getUserFundTotalBalance = () => {
        const body = {
            user: this.props.auth.userInfo.username,
            '$expand': 'fund'
            }
        return apiFunds.getUserFunds(body).then(obj => {
            if (obj.data.length !== 0) {
                let totalBalance = 0;
                obj.data.forEach(item => {
                    if (item.holding_unit) {
                        totalBalance += item.holding_unit * item.fund.unit_price_reference;
                    } else {
                        totalBalance += item.amount
                    }
                })
                return totalBalance;
            } else {
                return apiFunds.getFundVersion().then(res => {
                    if (res && res.status === 200) {
                        const version = res.data.int_value;
                        apiUserAccount.getUserAccount(this.props.auth.userInfo.username, version).then(obj2 => {
                            if (obj2 && obj2.status === 200) {
                                return obj2.data[0].total_fund_balance;
                            }
                        })
                    }
                })
            }
        })
    }

    _createFutureFunds = (newFund) => {
        const getFutureSwitchInAmount = Promise.resolve(this._calculateSwitchInFutureAmount(newFund));
        getFutureSwitchInAmount.then(futureSwitchInAmount => {
            this._createInstructionHistory(newFund, false)
        })
    }

    _submitFundInstruction = () => {
        this.setState({ submittingDialog: true });
        const { i18n } = this.props;
        const { switchInFunds, switchOutFund, instructionType, allFunds } = this.state;
        let currentDateTime = moment().valueOf();
        const isAfter4PM = this.checkIsAfter4PM();
        if (moment().day() < 6 && moment().day() > 0) {
            currentDateTime = isAfter4PM ? moment(currentDateTime).add(1, 'days').valueOf() : currentDateTime;
        }
        this.setState({ tradeHistoryDateTime: currentDateTime });
        const tradeHistoryBody = {
            user: this.props.auth.userInfo.username,
            date: currentDateTime,
            fund_type: instructionType === 'existing' ? 'fundSwitch' : 'futureInvestment',
            status: 'pending'
        }
        apiTradeHistories.createTradeHistory(tradeHistoryBody).then(res => {
            if (res && res.status === 201) {
                const tradeHistoryId = res.data.trade_history_id;
                this.setState({ tradeHistoryId: tradeHistoryId });
                const refNo = res.data.ref_no;
                const getUserFundTotalBalance = Promise.resolve(this._getUserFundTotalBalance())
                if (!allFunds && instructionType === 'existing') {
                    const fundNotDuplicated = switchInFunds.every(switchInFund => switchInFund.fundCode !== switchOutFund.fundCode);
                    if (fundNotDuplicated) {
                        this._createInstructionHistory(null, false)
                    }
                }
                getUserFundTotalBalance.then(fundTotalBalance => {
                    this.setState({ userTotalBalance: fundTotalBalance });
                    Promise.all(
                        switchInFunds.map(switchInfund => {
                            const userFundBody = {
                                user: this.props.auth.userInfo.username,
                                'fund': switchInfund.fundId,
                                '$expand': 'fund'
                            }
                            if (instructionType === 'existing') {
                                if (allFunds) {
                                    return this._createInstructionHistory(switchInfund, true)
                                } else {
                                    return apiFunds.getUserFunds(userFundBody).then(obj => {
                                        return this._createInstructionHistory(switchInfund, false)
                                    })
                                }
                            } else {
                                return this._createInstructionHistory(switchInfund, false)
                            }
                            
                        })
                    ).then(promises => {
                        if (promises.every(promise => promise && promise.status === 201)) {
                            this.props.history.push('/' + i18n.language + '/manage-funds/4', { refNo: refNo, switchInFunds: switchInFunds, switchOutFund: switchOutFund, instructionType: instructionType, submissionDateTime: currentDateTime, allFunds: allFunds });
                            this.setState({ submitting: false, submittingDialog: false });
                        }
                    })
                })
            }
        })
    }

    _checkSameDayInstructions = () => {
        const { instructionType, switchOutFund } = this.state;
        let hasSameDateInstruction = false;
        const isAfter4PM = this.checkIsAfter4PM();
        const body = {
            user: this.props.auth.userInfo.username,
            fund_type: instructionType,
            status: 'pending',
            ...(instructionType === 'existing' && switchOutFund && switchOutFund.fundCode && {is_one_time_rebalance: true})
        }
        // if (instructionType === 'existing') {
        //     if (switchOutFund.fundCode) {
        //         body['is_one_time_rebalance'] = true;
        //     }
        // }
        return apiFunds.getInstructionHistories(body).then(obj => {
            if (obj && obj.status === 200 && obj.data.length > 0) {
                return true;
            } else {
                if (switchOutFund) {
                    body['switch_out_fund/fund_code'] = switchOutFund.fundCode;
                    body['is_one_time_rebalance'] = false;
                    return apiFunds.getInstructionHistories(body).then(obj2 => {
                        if (obj2 && obj2.status === 200 && obj2.data.length > 0) {
                            this.setState({ cancelSwitchOutFund: true });
                            return true;
                        }
                    })
                }
            }
            // obj.data.forEach(instruction => {
            //     if (instructionType === 'existing') {
            //         if (moment().day() < 6 && moment().day() > 0) {
            //             if (moment().day() === 1 && !isAfter4PM) {
            //                 if ((moment(instruction.date).isSame(moment().subtract(1, 'day'), 'day') || moment(instruction.date).isSame(moment().subtract(2, 'day'), 'day')) && instruction.status === 'pending') {
            //                     hasSameDateInstruction = true;
            //                 }
            //             } else {
            //                 if (moment(instruction.date).isSame(moment().add(isAfter4PM ? 1 : 0, 'day'), 'day') && instruction.status === 'pending') {
            //                     hasSameDateInstruction = true;
            //                 }
            //             }
            //         } else if (moment().day() === 6) {
            //             if (moment(instruction.date).isSame(moment(), 'day') && instruction.status === 'pending') {
            //                 hasSameDateInstruction = true;
            //             }
            //         } else {
            //             if ((moment(instruction.date).isSame(moment(), 'day') || moment(instruction.date).isSame(moment().subtract(1, 'day'), 'day')) && instruction.status === 'pending') {
            //                 hasSameDateInstruction = true;
            //             }
            //         }
            //     } else {
            //         if (instruction.status === 'pending') {
            //             hasSameDateInstruction = true;
            //         }
            //     }
            // })
            // if (hasSameDateInstruction) {
            //     return true;
            // } else {
            //     return false;
            // }
        })
    }

    checkIsAfter4PM = () => {
        return moment().isSameOrAfter(moment('16:00', 'HH:mm'));
    }

    _cancelSameDayInstructions = () => {
        const { instructionType, switchOutFund, cancelSwitchOutFund } = this.state;
        // let greaterThanDate;
        // let lesserThanDate;
        // const isAfter4PM = this.checkIsAfter4PM();
        // if (instructionType === 'existing') {
        //     if (moment().day() > 0 && moment().day() < 6) {
        //         if (moment().day() === 1 && !isAfter4PM) {
        //             greaterThanDate = moment().subtract(2, 'day').startOf('day').valueOf();
        //             lesserThanDate = moment().endOf('day').valueOf();
        //         //     if ((moment(instruction.date).isSame(moment().subtract(1, 'day'), 'day') || moment(instruction.date).isSame(moment().subtract(2, 'day'), 'day')) && instruction.status === 'pending') {
        //         //         hasSameDateInstruction = true;
        //         //     }
        //         } else {
        //         //     if (moment(instruction.date).isSame(moment().add(isAfter4PM ? 1 : 0, 'day'), 'day') && instruction.status === 'pending') {
        //         //         hasSameDateInstruction = true;
        //         //     }
        //             greaterThanDate = moment().add(isAfter4PM ? 1 : 0, 'day').startOf('day').valueOf();
        //             lesserThanDate = moment().add(isAfter4PM ? 1 : 0, 'day').endOf('day').valueOf();
        //         }
        //     } else if (moment().day() === 6) {
        //         greaterThanDate = moment().startOf('day').valueOf();
        //         lesserThanDate = moment().endOf('day').valueOf();
        //     } else {
        //         greaterThanDate = moment().subtract(1, 'day').startOf('day').valueOf();
        //         lesserThanDate = moment().endOf('day').valueOf();
        //     }
        // }
        const pendingInstructionBody = {
            user: this.props.auth.userInfo.username,
            // ...(instructionType !== 'new' && {
            //     'date[gte]': greaterThanDate,
            //     'date[lte]': lesserThanDate,
            // }),
            status: 'pending',
            fund_type: instructionType === 'existing' ? 'fundSwitch' : 'futureInvestment',
        }
        if (cancelSwitchOutFund) {
            pendingInstructionBody['instruction_histories/switch_out_fund/fund_code'] = switchOutFund.fundCode;
            pendingInstructionBody['instruction_histories/is_one_time_rebalance'] = false;
        }
        apiTradeHistories.getUserTradeHistories(pendingInstructionBody).then(obj => {
            if (obj && obj.status === 200 && obj.data.length > 0) {
                Promise.all(obj.data.map(tradeHistory => {
                    const tradeHistoryId = tradeHistory.trade_history_id;
                    return apiTradeHistories.cancelTradeHistory(tradeHistoryId, { is_cancel_instruction: false });
                })).then(promises => {
                    if (promises.every(promise => promise.status === 200)) {
                        this.setState({ openDialog: false });
                        this._submitFundInstruction();
                    }
                })
            }
        })
        // apiFunds.getInstructionHistories(pendingInstructionBody).then(obj => {
        //     if (obj && obj.status === 200) {
        //         let tradeHistoryId;
        //         Promise.all(
        //             obj.data.map(instruction => {
        //                 tradeHistoryId = instruction.trade_history
        //                 const instructionId = instruction.instruction_history_id;
        //                 const cancelInstructionBody = {
        //                     status: 'cancelled'
        //                 }
        //                 return apiFunds.cancelInstructionHistory(instructionId, cancelInstructionBody);
        //             })).then(() => {
        //                 apiTradeHistories.cancelTradeHistory(tradeHistoryId).then(obj2 => {
        //                     if (obj2 && obj.status === 200) {
        //                         this.setState({ openDialog: false });
        //                         this._submitFundInstruction();
        //                     }
        //                 });
        //             });
        //     }
        // });
    }
    
    handleSubmit = () => {
        this.setState({ submitting: true });
        const { i18n, t } = this.props
        const param = {
            permission_name: 'switch fund'
        }
        apiTask.getTaskPermission(param).then(obj => {
            if (obj && obj.status === 200 && obj.data[0].is_permitted) {
                const checkSameDayInstructions = Promise.resolve(this._checkSameDayInstructions());
                checkSameDayInstructions.then(hasSameDateInstruction => {
                    if (hasSameDateInstruction) {
                        this.setState({ openDialog: true });
                    } else {
                        this._submitFundInstruction();
                    }
                })
            } else {
                alert(t("ManageFunds:switchFundDisallowMessage"));
                this.props.history.push('/' + i18n.language + '/index');
            }
        })
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false, submitting: false });
    }

    handleOpenBottomDialog = () => {
        this.setState({ openBottomDialog: true });
    }

    handleCloseBottomDialog = () => {
        this.setState({ openBottomDialog: false });
    }

    render() {
        const { t, classes, i18n } = this.props;
        const { switchInFunds, switchOutFund, instructionType, openBottomDialog, submitting } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const dialogTitle = isChinese ? '處理時間' : 'Processing time';
        const dialogDescriptions = isChinese ? cnProcessingTimeDescriptions : engProcessingTimeDescriptions;
        const futureDescription = isChinese ? cnFutureProcessingTimeDescriptions : engFutureProcessingTimeDescriptions;
        const alertMessages = isChinese ? cnAlertMessages : engAlertMessages;
        const instruction = instructionType === 'existing' ? 'fundSwitchingInstruction' : 'changeFutureInvestmentsInstruction';
        alertMessages[1] = isChinese ? <span>您於同一交易日已遞交另一「<b>{t(`ManageFunds:${instruction}`)}</b>」。該指示現正等候處理。</span> : <span>You have already submitted another "<b>{t(`ManageFunds:${instruction}`)}</b>" within the same dealing day. That instruction is currently pending for processing.</span>
        const footer =
            <AppBar position="fixed" className={classes.footer}>
                <Flex flex={1} justifyContent="center" className="fund-footer-container">
                    <Flex flex={1} maxWidth={700}>
                        <Flex className="button-wrapper" paddingY="0.5rem">
                            <Button className="transparent-button" style={{ margin: 0, marginRight: 10 }} onClick={this.back}>{t("Common:Button.back")}</Button>
                            <Button className="primary-button" disabled={submitting} style={{ margin: 0, marginLeft: 10 }} onClick={this.handleSubmit}>{t("Common:Button.submit")}</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </AppBar>;

        return (                    
            <div>
                <Flex className="main__container fund-manager" mb="4rem">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title" style={{ paddingBottom: '0'}}>
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("ManageFunds:manageFunds")}
                            </Flex>
                        </Flex>
                        <Flex width="100%" alignItems="center" flexDirection="column">
                            <Flex flex={1} flexDirection="column" justifyContent="start" className="container" marginTop="1rem" maxWidth={1000} width="100%">
                                {/* {instructionType === 'existing' && <Flex mb="1rem">
                                    <img className="step-icon" src={stepCompleted} alt="step-completed" />
                                    <img className="step-icon" src={stepCompleted} alt="step-completed" />
                                    <img className="step-icon" src={step3Green} alt="step-3-green" />
                                </Flex>} */}
                                {instructionType === 'existing' && <Flex mb="1rem" justifyContent="flex-start" fontSize="1rem">
                                    <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                        <img className="step-icon" src={stepCompleted} alt="step-1-green" />
                                        <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                            {t("ManageFunds:step1Green")}
                                        </Flex>
                                    </Flex>

                                    <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                        <img className="step-icon" src={stepCompleted} alt="step-2-white" />
                                        <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                            {t("ManageFunds:step2White")}
                                        </Flex>
                                    </Flex>

                                    <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                        <img className="step-icon" src={step3Green} alt="step-3-white" />
                                        <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                            {t("ManageFunds:step3White")}
                                        </Flex>
                                    </Flex>
                                </Flex>}
                                <Flex flex={1} justifyContent="start" className="section-title black-text medium-font-size" mb="1rem">
                                    {t("ManageFunds:reviewAndSubmit")}
                                </Flex>
                                <Flex style={{ backgroundColor: 'white', width: '100%', padding: '1.5rem 1rem', border: 1, borderStyle: 'solid', borderColor: '#e0e0e0' }}>
                                    <Flex flexDirection="row" justifyContent="space-between">
                                        <img src={dealingIcon} width={25} height={25} alt="dealing-icon"/>
                                        <Flex ml="1rem" className="light-black" fontSize={14}>
                                            {instructionType === 'existing' ? <span>
                                                {dialogDescriptions[0]}
                                                {/* <span className='hyperlink link' onClick={this.handleOpenBottomDialog} style={{ cursor: 'pointer' }}><b>{t("Common:General.seeDetail")}</b></span> */}
                                            </span> :
                                            <p>
                                                {futureDescription[0]}
                                            </p>
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                                
                                {instructionType === 'existing' && <SwitchFundList t={t} switchOutFund={switchOutFund} instructionType={instructionType} />}

                                <SwitchFundList t={t} switchInFunds={switchInFunds} instructionType={instructionType} />

                                {instructionType !== 'existing' && <Flex flex={1} flexDirection="column" mb="2rem">
                                    <hr style={{ backgroundColor: 'black', marginTop: '-1.5rem' }} />

                                    <Flex mt="1rem">
                                        <Flex className="black-text" flex={2}>
                                            {`${t("ManageFunds:total")} (${switchInFunds.length})`}
                                        </Flex>
                                        <Flex flex={1} className="black-text" alignItems="center">
                                            <Flex className="medium-font-size">100</Flex>
                                            <Flex>%</Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>}
                                    
                                <Flex width="100%" className="black-text small-font-size" marginY="1rem" flexDirection="column">
                                    {t("ManageFunds:notes")}:
                                    <Flex flexDirection="column" mt="1rem" className="light-black">
                                        <Flex flexDirection="row">
                                            <Flex flex={1/15} maxWidth={30}>1.</Flex>
                                            <Flex flex={1}>{t('ManageFunds:thisIsAVirtualInvestmentGame')}</Flex>
                                        </Flex>
                                        {/* {instructionType === 'existing' && <Flex flexDirection="row">
                                            <Flex flex={1/15} maxWidth={30}>2.</Flex>
                                            <Flex flex={1}>{t("ManageFunds:fundManagement3Remark2")}</Flex>
                                        </Flex>} */}
                                        <Flex flexDirection="row">
                                            <Flex flex={1/15} maxWidth={30}>{instructionType === 'existing' ? '2.' : '2.'}</Flex>
                                            <Flex flex={1}>{t("ManageFunds:fundManagement3Remark3")}</Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                >
                    <DialogTitle>{t("ManageFunds:attention")}</DialogTitle>
                    <DialogContent>
                        {alertMessages.map((message, index) => <DialogContentText key={index}  style={{ color: 'black' }}>
                            {message}
                        </DialogContentText>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            {t("Common:Button.cancel")}
                        </Button>
                        <Button onClick={this._cancelSameDayInstructions} color="primary" autoFocus>
                            {t("Common:Button.continue")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.submittingDialog}
                >
                    <DialogTitle style={{ width: '50vw' }}>{t("ManageFunds:attention")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("ManageFunds:pleaseDoNotRefreshPageWhileSubmitting")}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <BottomDialog open={openBottomDialog} title={dialogTitle} descriptions={dialogDescriptions} handleCloseDialog={this.handleCloseBottomDialog} t={t} />
                {footer}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(ManageFunds3))));