// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {apiUsers} from "../../Api/_ApiUsers";
import * as sha1 from 'js-sha1';
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {get} from 'lodash-es';

import ErrorMessage from "../../components/100Include/ErrorMessage";
import {getRoleDisplay, getRoleSelectedDisplay} from "../../utils/Utility";
import moment from "moment";
import Files from 'react-files'
import {IMAGE_UPLOAD_TYPE, Roles} from "../../constants/common";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class UserUpdate extends Component {

    state = {
        roles: [],
        isEdit: false,
        user: {
            username: '',
            display_name: '',
            email: '',
            role: '',
            profile_image: null,
            password: '',
            re_password: ''
        },
        files: [],
        fileUpload: null,
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
        openChangePwdDialog: false,
        roleValidateMsg: null
    };

    constructor(props) {
        super(props);
        this.myFiles = React.createRef();
    }

    componentDidMount() {
        if(this.props.id) {
            this.setState({isEdit: true})
            this.getUserDetail();
        } else {
            const { t } = this.props;
            const userItemBreadcrumb = [{
                title: t("UserManagement:addUser"),
                link: null
            }];
            this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
        }
    }

    getUserDetail = () => {
        const { i18n } = this.props;
        let params = {
            $expand: "access_rights,profile_image"
        }
        apiUsers.getUserDetail(this.props.id, params).then( obj => {
            if(obj && !obj.error) {
                if(obj.data) {
                    const userDetail = obj.data;

                    const roles = [];
                    const roleString = get(userDetail, 'role_in_string', '');
                    roleString.split(',').forEach(role => {
                        role = role.trim();
                        if(role) {
                            roles.push(role);
                        }
                    });
                    const userItemBreadcrumb = {
                        title: `Edit [${userDetail.display_name}]`,
                        link: null
                    };
                    this.setBreadcrumbWithUserNumber(userItemBreadcrumb);

                    this.setState( {
                        user: this.prepareData(userDetail),
                        roles: roles
                    });
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        })
    }

    setBreadcrumbWithUserNumber = (userInfoBreadCrumb) => {

        const { t, i18n } = this.props;
        const params= {
            '$top' : 1,
            '$select' : 'display_name'
        };
        apiUsers.getUserList(params).then( (rs) => {
            if(rs && rs.status === 200) {

                const dataSum = rs.headers['content-range']
                

                const totalUserBreadcrumb = {title: t('UserManagement:breadcrumb') + ' (' + rs.data.length + ')',link: '/' + i18n.language + '/user-management'}

                this.props.setBreadcrumbP({breadcrumbData: [totalUserBreadcrumb, userInfoBreadCrumb]});

            } else {
                console.log("eCb : ", rs);
            }
        })
    }


    prepareData = data => {
        const item = {...data};
        item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
        item.role_display = getRoleDisplay(item.role, item.access_rights);
        item.password = 'password'
        item.re_password = 'password'
        return item;
    };

    // FILE CONTROL
    onFilesChange = (files) => {
        this.setState({
            files
        }, () => {
            if(files && files.length > 0 ) {
                const file = files[files.length - 1];
                let reader = new FileReader();
                reader.readAsDataURL(file);
                console.log("test");
                reader.onloadend = () => {
                    var fr = new FileReader();
                    fr.onloadend = () => {
                        const fileName = (file.name).replace(/\s|-/g, '_');
                        console.log("file sha1",sha1(fr.result));
                        const dataForLib = {
                            file_url: `Content-Type:${file.type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}\r\nContent-Disposition:attachment;filename="${encodeURI(fileName)}"\r\nContent-Transfer-Encoding: base64\r\n\r\n${reader.result.split('base64,')[1]}`,
                            file_name: fileName,
                            file_size: parseInt(file.size),
                            sha1_hash: sha1(fr.result)
                        }
                        this.setState({
                            fileUpload: dataForLib
                        });
                    }
                    fr.readAsArrayBuffer(file);
                };
            } else {
                this.setState({
                    fileUpload: null
                });
            }

        })
    }

    onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    filesRemoveOne = (file) => {
        this.myFiles.current.removeFile(file);
    }

    // BUTTON FUNCTION

    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/user-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    roleValid = (roles) => {
        if(roles.length === 0) {
            this.setState({roleValidateMsg: 'Role is required'});
            return false;
        }
        return true
    }

    _addUserAsync = (value) => {
        const { i18n } = this.props;
        const { roles } = this.state;
        if(this.roleValid(roles)) {
            if( !(roles.includes('admin') && roles.length > 1)) {
                const role = roles.includes('admin') ? 1 : 2;
                const email = value.email.trim();
                let body = {
                    "display_name": value.display_name,
                    "username": email,
                    "email": email,
                    "password": value.password,
                    "active": true,
                    "role": role,
                    "role_in_string": roles.join(),
                    "profile_image": this.state.fileUpload
                }

                apiUsers.createUser(body).then(obj => {
                    if (obj.status === 201) {
                        this.setState( {
                            openSnackbar: true,
                            messageSnackbar: 'Add new user successfully',
                            variantSnackbar: 'success'
                        }, () => {
                            setTimeout( () => this.props.history.push('/' + i18n.language + '/user-management?previous=true') , 1500);
                        })
                    } else {
                        const error = obj.data && obj.data.error ? obj.data.error : 'Add new user failed';
                        this.setState( {
                            openSnackbar: true,
                            messageSnackbar: error,
                            variantSnackbar: 'error'
                        });
                    }
                });
            }
        }
    }

    _updateUserAsync = (value) => {
        const { roles } = this.state;
        if(this.roleValid(roles)) {
            if( !(roles.includes('admin') && roles.length > 1)) {
                const role = roles.includes('admin') ? 1 : 2;
                const email = value.email.trim();
                let updateUserBody = {
                    "display_name": value.display_name,
                    "username": email,
                    "email": email,
                    "active": true,
                    "role": role,
                    "role_in_string": roles.join(),
                }

                this.checkUpdateProfileImage(updateUserBody);
            }
        }
    }

    checkUpdateProfileImage = (updateUserBody) => {
        let body = {...updateUserBody};
        if(this.state.fileUpload) {
            console.log(this.state.fileUpload)
            //If upload new file => Create new File => Call user update
            apiUsers.uploadNewProfileImage(this.state.fileUpload).then(obj => {
                console.log(obj)
                if (obj.status === 201) {
                    body['profile_image'] = obj.data.file_id;
                } else {
                    body['profile_image'] = this.state.user.profile_image;
                }
                this.callAPIUpdateUser(this.props.id, body);
            })

        } else {
            // Else update user now
            body['profile_image'] = this.state.user.profile_image;
            this.callAPIUpdateUser(this.props.id, body);
        }
    }

    callAPIUpdateUser = (email, body) => {
        const { i18n } = this.props;
        apiUsers.updateUser(email, body).then(obj => {
            if (obj.status === 200) {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Update user successfully',
                    variantSnackbar: 'success'
                }, () => {
                    setTimeout(() => this.props.history.push('/' + i18n.language + '/user-management?previous=true'), 1500);
                })
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update user failed';
                this.setState( {
                    openSnackbar: true,
                    messageSnackbar: error,
                    variantSnackbar: 'error'
                });
            }
        });
    }

    _submitUserForm = (value) => {
        if(this.state.isEdit) {
            this._updateUserAsync(value);
        } else {
            this._addUserAsync(value);
        }
    }

    handleChange = event => {
        this.setState({ roles: event.target.value, roleValidateMsg: event.target.value.length === 0 ? 'Role is required' : null });
    };

    // CHANGE PASSWORD
    openChangePwd = () => {
        this.setState({ openChangePwdDialog: true });
    };
    handleCloseChangePwd = () => {
        this.setState({ openChangePwdDialog: false });
    };

    _submitChangePwdForm = (value) => {
        const body = {"new_password": value.new_password}
        apiUsers.changePassword(this.state.user.username, body).then(obj => {
            if (obj.status === 204) {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Change password successfully.',
                    variantSnackbar: 'success'
                })
            } else {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Cannot change user password',
                    variantSnackbar: 'error'
                })
            }
        });
    }

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        const roleValidate = this.state.roleValidateMsg ? (
            <ErrorMessage message={this.state.roleValidateMsg} />
        ) : (
            this.state.roles.includes('admin') && this.state.roles.length > 1 ? <ErrorMessage message={'Can select only admin role or user role'} /> : t("Common:Form.validator.required")
        );

        return values && (
            <Form id="userForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:userName")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="display_name" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.display_name && touched.display_name ? <ErrorMessage message={errors.display_name} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:email")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="email" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:password")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                { values.isEdit ?
                                    <Button type="button" className="primary-button" onClick={this.openChangePwd}>Set Password</Button>
                                    : <Field name="password" type="password" placeholder="" maxLength="100"/>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                { values.isEdit ?
                                    null
                                    : (errors.password && touched.password ? <ErrorMessage message={errors.password} /> : t("Common:Form.validator.required"))
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    {!values.isEdit &&
                        <Grid container direction="row" justify="center" alignItems="flex-start" >
                            <Grid item xs={2} className="form-item">
                                {t("UserManagement:rePassword")}
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                <Grid item xs={12}>
                                    <Field name="re_password" type="password" placeholder="" maxLength="100"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {errors.re_password && touched.re_password ? <ErrorMessage message={errors.re_password} /> : t("Common:Form.validator.required")}
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:role")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <FormControl className='full-width'>
                                    <Select
                                        classes={{
                                            root: 'custom-select'
                                        }}
                                        name="roles"
                                        multiple
                                        disableUnderline={true}
                                        value={this.state.roles}
                                        onChange={this.handleChange}
                                        input={<Input id="select-multiple-checkbox" />}
                                        renderValue={selected => getRoleSelectedDisplay(selected)}
                                        MenuProps={MenuProps}
                                    >
                                        {Roles.map(item => (
                                            <MenuItem key={item.value} value={item.value}>
                                                <Checkbox checked={this.state.roles.indexOf(item.value) > -1} />
                                                <ListItemText primary={item.label} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                { roleValidate }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt10">
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:passportPhoto")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            {
                                this.state.files.length > 0
                                    ? <div>
                                        {this.state.files.map((file) =>
                                            <div key={file.id}>
                                                <span><span className='file-remove-item' onClick={this.filesRemoveOne.bind(this, file)}><CloseIcon /></span><span>{file.name}</span></span>
                                            </div>
                                        )}
                                    </div>
                                    : null
                            }
                            <div className="files p-l-20">
                                <Files
                                    ref={this.myFiles}
                                    className='files-dropzone'
                                    onChange={this.onFilesChange}
                                    onError={this.onFilesError}
                                    accepts={IMAGE_UPLOAD_TYPE}
                                    maxFiles={1}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {(values.isEdit && this.state.user.profile_image && this.state.files.length === 0) ? (<span className="pr20">Uploaded</span>) : null}
                                    <Button type="button" className="primary-button">Upload photo</Button>
                                </Files>
                            </div>
                        </Grid>
                    </Grid>
                    {values.isEdit &&
                        <Grid container direction="row" justify="center" alignItems="flex-start" className="pt10">
                            <Grid item xs={2} className="form-item">
                                {t("UserManagement:updatedDate")}
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD') : ''}

                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    formChangePwdConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        return values && (
            <Form id="changePwdForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.ChangePwdMessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.ChangePwdMessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("UserManagement:password")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                { (errors.new_password && touched.new_password ? <ErrorMessage message={errors.new_password} /> : t("Common:Form.validator.required")) }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("UserManagement:rePassword")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="re_new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.re_new_password && touched.re_new_password ? <ErrorMessage message={errors.re_new_password} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button" onClick={() => { if(!errors.re_new_password){this.handleCloseChangePwd()} }}>Save</Button>
                        <Button type="button" className="second-button" onClick={() => { this.handleCloseChangePwd() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, user, openSnackbar, variantSnackbar, messageSnackbar, openChangePwdDialog } = this.state;
        const inputUser = {...user, isEdit: isEdit};
        const Schema = Yup.object().shape({
            display_name: Yup.string().required("Username is required"),
            email: Yup.string().required("Email is required").email("Please input correct email address"),
            password: Yup.string().required("Password is required"),
            re_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        const SchemaChangePwd = Yup.object().shape({
            new_password: Yup.string().required("Password is required"),
            re_new_password: Yup.string().when("new_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        return (
            user && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputUser}
                            validationSchema={Schema}
                            onSubmit={this._submitUserForm}
                            component={this.formConfiguration}
                        />

                        <Dialog
                            open={openChangePwdDialog}
                            onClose={this.handleCloseChangePwd}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">SET NEW PASSWORD</DialogTitle>
                            <DialogContent>
                                <Formik
                                    enableReinitialize
                                    initialValues={{new_password: '', re_new_password: '',}}
                                    validationSchema={SchemaChangePwd}
                                    onSubmit={this._submitChangePwdForm}
                                    component={this.formChangePwdConfiguration}
                                />
                            </DialogContent>
                        </Dialog>

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openSnackbar}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnackbar}
                        >
                            <MySnackbarContentWrapper
                                onClose={this.handleCloseSnackbar}
                                variant={variantSnackbar}
                                message={messageSnackbar}
                            />
                        </Snackbar>
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(UserUpdate)));
