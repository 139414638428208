// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox';
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import { getUserInfo } from "../../Redux/Action/authAction";
import { apiNews } from "../../Api/_ApiNews.js";
import backIcon from "../../images/back.png";
import forwardIcon from "../../images/forward.png";

const DATE_FORMAT = 'MMMM d, YYYY';

const NewsItemList = props => {
    const { t, onClick, newsItems } = props;
    
    return newsItems.map((item) => {
        return (
            <Flex width="100%" key={item.id} alignItems="center" flexDirection="column" backgroundColor="white" marginBottom="2rem" className='video'>
                <iframe width="100%" height="100%" src={item.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <Flex width="100%" flexDirection="column" padding="1rem">
                    <Flex>
                        {item.title}
                    </Flex>
                    <Flex fontWeight="bold" style={{
                        margin: '8px 0'
                    }}>
                        {t("Common:General.from")} {moment(item.startDate).format(DATE_FORMAT)} {t("Common:General.to")} {moment(item.endDate).format(DATE_FORMAT)}
                    </Flex>
                    <Flex fontSize="0.9rem">
                        {item.description}
                    </Flex>
                    <Flex fontSize="1.1rem" alignItems="center"  style={{ margin: '8px 0' }}>
                        <Flex onClick={() => onClick(item.title, item.description, item.video)} style={{ cursor: 'pointer' }}>
                            <img src={forwardIcon} className="back-icon" style={{ width: '25px', height: '25px', marginRight: '8px' }} alt="forward-icon"/>
                            {t("Common:General.readMore")}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        )
    });
}

class News extends Component {

    state = {
        newsItems: [],
    }

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleClick = (title, description, video) => {
        const { i18n, history } = this.props
        history.push('/' + i18n.language + '/news-detail', {title: title, description: description, video: video});
    }

    _getAllNews = () => {
        apiNews.getAllNews({$orderby: 'createddate desc'}).then(obj => {
            const allNews = obj.data.map(item => {
                const news = {
                    id: item.id,
                    title: item.title,
                    description: item.summary,
                    content: item.content,
                    video: item.video_url,
                    startDate: item.start_time,
                    endDate: item.end_time
                };
                return news;
            })
            this.setState({ newsItems: allNews });
        });
    }

    componentDidMount() {
        this._getAllNews();
    }

    render() {
        const { t } = this.props;
        const { newsItems } = this.state;
        return (                    
            <div>
                <Flex className="main__container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("Common:MenuItem.newsAndEducation")}
                            </Flex>
                        </Flex>
                        <Flex mt="1rem" flexDirection="column" flex={1} alignItems="center">
                            <NewsItemList t={t} onClick={this.handleClick} newsItems={newsItems} />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(News)));