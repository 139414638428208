import { get } from 'lodash-es';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Flex } from 'reflexbox';

class RegisterSuccess extends Component {

    componentDidMount() {
        const { history, i18n } = this.props;
        const state = get(history, ['location', 'state']);
        const username = get(state, 'username');
        if (!username) {
            history.push(`/${i18n.language}/landing`)
        }
    }

    render() {
        const { i18n } = this.props;
        return (
            <div className='landing-content' style={{ height: '100%', paddingBottom: 0 }}>
                <div className="main__container" style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', minHeight: '100%' }}>
                    <Flex marginY="3rem">
                        {i18n.language === 'en-US' ? <Flex flexDirection="column">
                            <p>Thank you for registering for the <b>MPF FUN GAME</b>. We are thrilled to have you on board and excited to see how you perform in the competition.</p>
                            <p>We have sent you an email to verify your account for the <b>MPF FUN GAME</b>. Please check your email inbox for the verification message. If you cannot find the email, please check your spam or junk mail folder, as sometimes emails may be filtered there.</p>
                            <p>If you experience any issues or have not received the verification email after checking your spam folder, please contact our support team at info@mpfgame.com for assistance.</p>
                        </Flex> :
                        <Flex flexDirection="column">
                            <p>多謝您登記參加<b>MPF積FUN賽</b>。我們非常高興能有您的參與，期待見證您在比賽中的表現。</p>
                            <p>我們已向您發送了一封電郵，以便驗證您在<b>MPF積FUN賽</b>的帳戶。請查看您的電郵收件箱以獲取驗證信息。如果您未有發現該電郵，請檢查您的垃圾郵件箱，因電郵或會被篩選至該處。</p>
                            <p>如有任何疑問或在檢查垃圾郵件箱後仍未收到驗證郵件，請電郵至 info@mpfgame.com 聯絡我們的支援團隊尋求協助。</p>   
                        </Flex>}
                    </Flex>
                </div>
            </div>
        );
    }
}


export default withTranslation()(withRouter(RegisterSuccess));
