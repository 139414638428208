// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import {AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, makeStyles} from "@material-ui/core";
import { KeyboardArrowDown } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import stepCompleted from "../../images/step-completed.png";
import step2Green from "../../images/step-2-green.png";
import step3White from "../../images/step-3-white.png";
import filterIcon from "../../images/filter.png";
import riskIndex1 from "../../images/risk-index-1.png";
import riskIndex2 from "../../images/risk-index-2.png";
import riskIndex3 from "../../images/risk-index-3.png";
import riskIndex4 from "../../images/risk-index-4.png";
import riskIndex5 from "../../images/risk-index-5.png";
import riskIndex6 from "../../images/risk-index-6.png";
import riskIndex7 from "../../images/risk-index-7.png";
import backIcon from "../../images/back.png";
import { apiFunds } from '../../Api/_ApiFunds';
import BottomDialog from '../../components/BottomDialog/BottomDialog';
import { cloneDeep, get } from 'lodash-es';
import CloseIcon from '@material-ui/icons/Close';
import quarterly from "../../images/Landingpg/Quarterly Fund fact sheet.png";
import step1Green from "../../images/step-1-green.png";
import step2White from "../../images/step-2-white.png";
import { apiTradeHistories } from '../../Api/_ApiTradeHistories';
import RiskDialog from '../../components/RiskClassDialog';
import DISDialog from '../../components/DISDialog';

const engLatestUnitPriceReferenceDescriptions = [
    <span>"Latest unit price reference" is for reference only and not the trading price for processing this instruction. The unit price for trading will depend on the date on which your instruction is processed. Instructions received <b>at or before 4:00 p.m. Hong Kong time</b> on a dealing day will be carried out based on the fund price on the same dealing day.</span>,
    <span>Since funds are traded through a "forward pricing" mechanism (i.e. the unit price of a fund can only be calculated after the close of market when the prices of underlying investments are available), at the time a fund switching instruction is submitted, the fund price on the same dealing day is not available.</span>,
    <span>The as of date for "Latest unit price reference" may be different from the as of date for your fund holdings information due to the time gap between the updates of fund price and your fund holdings information at MPF FUN Game Website.</span>
]

const cnLatestUnitPriceReferenceDescriptions = [
    <span>「最新單位價格參考」只供參考，並非處理此指示的交易價格。交易的單位價格會視乎處理指示的日期而定。於每個交易日<b>香港時間下午4時</b>或之前收到的指示將按同一交易日的基金價格。</span>,
    <span>由於基金買賣是以「未知價」方式進行（即基金單位的價格要待收市後，經過計算基金相關投資的淨資產值後才能釐定），因此在遞交轉換基金指示時，我們未能提供當天交易日的基金價格。</span>,
    <span>由於在MPF積FUN賽網站更新基金價格與您的持有基金資料存在時差，因此「最新單位價格參考」的截至日期可能與您持有基金資料的截至日期不同。</span>
]

const FundContainer = styled(Flex)({
    backgroundColor: 'white',
    width: '100%',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    border: 1,
    borderStyle: 'solid',
    borderColor: '#e0e0e0'
});

const SwitchOutFund = props => {
    const { t, fundType, onClick, asOfDate, asOfDateFormat, locale } = props;
    const { title, subtitle, amount } = fundType || {};
    return (
        <FundContainer marginY="1rem" flexDirection="column" onClick={onClick}>
            <Flex className="black-text" mb="1rem">
                {t("ManageFunds:switchOutFrom")}
            </Flex>
            {subtitle && <Flex>
                {t(`Fund:${subtitle}`)}
            </Flex>}
            <Flex>
                {t(`Fund:${title}`)}
            </Flex>
            <Flex className="small-font-size" mt="2rem">
                {t("ManageFunds:totalBalance")}
            </Flex>
            <Flex flexDirection="row" justifyContent="start" alignItems="center" mt="1rem">
                <Flex className="black-text" mr="1rem">
                    {t("Common:General.hkd")} {amount.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                </Flex>
                <Flex className="small-font-size">
                    {t("ManageFunds:AsOf")} {moment(asOfDate).locale(locale).format(asOfDateFormat)}
                </Flex>
            </Flex>
        </FundContainer>
    )
}

const SwitchInFund = props => {
    const { fund, t, onChange, instructionType, component } = props;
    const { handleOpenDialog, handleOpenRiskClassDialog, handleOpenDISDialog } = component;
    let riskIcon;
    const { title, subtitle, riskClass, reviewDate, fundType, unitPriceReference, switchInPercentage } = fund;
    const error = switchInPercentage && (+switchInPercentage < 5 || +switchInPercentage > 100);
    switch (+riskClass) {
        case 1: {
            riskIcon = riskIndex1;
            break;
        }
        case 2: {
            riskIcon = riskIndex2;
            break;
        }
        case 3: {
            riskIcon = riskIndex3;
            break;
        }
        case 4: {
            riskIcon = riskIndex4;
            break;
        }
        case 5: {
            riskIcon = riskIndex5;
            break;
        }
        case 6: {
            riskIcon = riskIndex6;
            break;
        }
        case 7: {
            riskIcon = riskIndex7;
            break;
        }
        default: {}
    }
    return instructionType === 'existing' ? (
        <Flex flexDirection="column">
            <Flex flexDirection="row" alignItems="center" className="light-black">
                {t(`Fund:${subtitle}`)}
            </Flex>
            <Flex className="light-black">
                {t(`Fund:${title}`)}
                {subtitle.includes('D') && <IconButton onClick={handleOpenDISDialog} style={{ margin: 0, marginLeft: 5, padding: 0, cursor: 'pointer' }}>
                    <InfoOutlinedIcon style={{ fontSize: 15 }} />
                </IconButton>}
            </Flex>
            <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center" mt="1rem" className="small-font-size">
                <Flex flex={1} alignItems="center">
                    {t("ManageFunds:riskClass")}
                    <IconButton onClick={handleOpenRiskClassDialog} style={{ margin: 0, marginLeft: 5, padding: 0, cursor: 'pointer' }}>
                        <InfoOutlinedIcon style={{ fontSize: 15 }} />
                    </IconButton>
                </Flex>
                {reviewDate ? subtitle === 'SHK121' ? t("ManageFunds:n/a") : <Flex flexDirection="column">
                    <img className="risk-index-icon" src={riskIcon} alt="riskIcon" />
                    <Flex className="small-font-size" textAlign="right">
                        {t("ManageFunds:asOf")} {moment(reviewDate).format('DD/MM/YYYY')}
                    </Flex>
                </Flex> :
                <Flex>
                    {subtitle.includes('D') ? <Flex flexDirection="column" alignItems="flex-end">
                            <Flex>{t("ManageFunds:referToRiskClassOf")}</Flex>
                            <Flex>{t("ManageFunds:riskClassOfSHK148SHK149")}</Flex>
                    </Flex> : '-'}
                </Flex>}
            </Flex>

            <LineBreak />

            <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center" className="small-font-size">
                <Flex flex={1}>
                    {t("ManageFunds:fundType")}
                </Flex>
                {fundType ? <Flex flex={1.2} justifyContent="flex-end" textAlign="right">
                    {t(`Fund:${fundType}`)}
                </Flex> :
                <Flex flexDirection="column" alignItems="flex-end">
                    <Flex>{t("ManageFunds:referToFundTypeOf")}</Flex>
                    <Flex>{t("ManageFunds:fundTypeOfSHK148SHK149")}</Flex>
                </Flex>}
            </Flex>

            <LineBreak />

            <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center" className="small-font-size">
                <Flex flexDirection="column">
                    <Flex flexDirection="row" alignItems="center">
                        {t("ManageFunds:unitPriceReference")}
                        <IconButton onClick={handleOpenDialog} style={{ margin: 0, marginLeft: 5, padding: 0, cursor: 'pointer' }}>
                            <InfoOutlinedIcon style={{ fontSize: 15 }} />
                        </IconButton>
                    </Flex>
                    <Flex className="bold">
                        ({t("ManageFunds:notTradingPrice")})
                    </Flex>
                </Flex>
                <Flex className="light-black" justifyContent="flex-end">
                    {unitPriceReference ? `${t("Common:General.hkd")} ${unitPriceReference.toLocaleString('en', {maximumFractionDigits: 3, minimumFractionDigits: 3})}` : '-'}
                </Flex>
            </Flex>

            <LineBreak />

            <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center" className="small-font-size">
                <Flex flex={1.5}>
                    <Flex>
                        {t("ManageFunds:switchIn")} %
                    </Flex>
                </Flex>
                <Flex className="black-text" alignItems="flex-end" flexDirection="column" flex={1}>
                    <Flex justifyContent="flex-end" textAlign="right" alignItems="center">
                        <input type="text" pattern="\d*" id={title} value={switchInPercentage || ''} className="percentage-input" onChange={e => onChange(e, 3)} style={{ color: error ? 'red' : 'inherit', fontSize: '1.2rem' }} /> %
                    </Flex>
                    {error && <Flex flex={1} className="error" fontSize="0.8rem">{t("ManageFunds:switchInPercentageMustBeAtLeastFivePercentAndNotExceedHundredPercent")}</Flex>}
                </Flex>
            </Flex>

            <LineBreak fullWidth />
        </Flex>
    ) : (
        <Flex flex={1} flexDirection="column" fontSize="1.1rem">
            <Flex>
                <Flex flex={2} flexDirection="column">
                    <Flex className="light-black">
                        {t(`Fund:${subtitle}`)}
                    </Flex>
                    <Flex className="light-black">
                        {t(`Fund:${title}`)}
                        {subtitle.includes('D') && <IconButton onClick={handleOpenDISDialog} style={{ margin: 0, marginLeft: 5, padding: 0, cursor: 'pointer' }}>
                            <InfoOutlinedIcon style={{ fontSize: 15 }} />
                        </IconButton>}
                    </Flex>
                    <Flex marginY="1rem" className="small-font-size">
                        {fundType ? <Flex flex={1.2} justifyContent="flex-start">
                            {t(`Fund:${fundType}`)}
                        </Flex> :
                        <Flex flexDirection="column" alignItems="flex-start">
                            <Flex>{t("ManageFunds:referToFundTypeOf")}</Flex>
                            <Flex>{t("ManageFunds:fundTypeOfSHK148SHK149")}</Flex>
                        </Flex>}
                    </Flex>
                    {reviewDate ? <Flex flexDirection="column">
                        <Flex>
                            <img className="risk-index-icon" src={riskIcon} alt="riskIcon" />
                            <IconButton onClick={handleOpenRiskClassDialog} style={{ margin: 0, marginLeft: 5, padding: 0, cursor: 'pointer' }}>
                                <InfoOutlinedIcon style={{ fontSize: 15 }} />
                            </IconButton>
                        </Flex>
                        <Flex className="small-font-size" textAlign="right">
                            {t("ManageFunds:asOf")} {moment(reviewDate).format('DD/MM/YYYY')}
                        </Flex>
                    </Flex> :
                    <Flex>
                        {subtitle.includes('D') ? <Flex flexDirection="column" alignItems="flex-start">
                                <Flex>{t("ManageFunds:referToRiskClassOf")}</Flex>
                                <Flex>{t("ManageFunds:riskClassOfSHK148SHK149")}</Flex>
                        </Flex> : '-'}
                    </Flex>}
                </Flex>
                {/* <Flex flex={1.3} ml={10} className="medium-font-size black-text" alignItems="center">
                    {`0 %`}
                </Flex> */}
                <Flex flex={1} className="black-text medium-font-size" justifyContent="center" flexDirection="column">
                    <Flex justifyContent="flex-start" alignItems="center">
                        <input type="number" id={title} value={switchInPercentage || ''} className="percentage-input" onChange={e => onChange(e, 3)} style={{ width: '100%', color: error ? 'red' : 'inherit' }} /> %
                    </Flex>
                    {error && <Flex className="error" fontSize="0.8rem">{t("ManageFunds:allocationPercentageMustBeAtLeastFivePercentAndNotExceedHundredPercent")}</Flex>}
                </Flex>
            </Flex>
            <LineBreak fullWidth />
        </Flex>
    )
}

const SwitchInFundList = (props) => {
    const { t, onChange, instructionType, funds, component } = props;
    // if (!showAllFund) {
    //     return <SwitchInFund fund={funds[0]} onChange={onChange} t={t} instructionType={instructionType} component={component} />
    // } else {
        return funds.map((fund, index) => <SwitchInFund key={index} fund={fund} onChange={onChange} t={t} instructionType={instructionType} component={component} />)
    // }
}

const LineBreak = (props) => {
    const { text, fullWidth } = props;
    return ( text ?
        <div className="strike" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span>{text}</span>
        </div> :
        <hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} className={fullWidth ? 'full-hr' : ''} />
    )
}

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            paper: {
                maxWidth: '100vw',
                maxHeight: '70vh',
                width: '100vw',
                margin: 0,
                padding: '1rem',
                position: 'absolute',
                bottom: 0,
                fontWeight: "300"
            },
            closeButton: {
                width: 25,
                height: 25,
                position: 'absolute',
                right: 25
            }
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

const riskLevel = [1, 2, 3, 4, 5, 6, 7];
const fundType = ['GuaranteedFund', 'EquityFund', 'BondFund', 'MixedAssetsFund', 'MoneyMarketFund'];

const originRiskLevelSelections = riskLevel.map(level => ({ [level]: { level: level, selected: false, type: 'risk' } }));
const originFundTypeSelections = fundType.map(fundType => ({ [fundType]: { fundType: fundType, selected: false, type: 'fund' } }));

class ManageFunds2 extends Component {
    
    state = {
        instructionType: get(this.props.history, ['location', 'state', 'instructionType'], null),
        switchOutPercentage: 0,
        switchOutFund: get(this.props.history, ['location', 'state', 'fundType'], null),
        funds: [],
        showAllFunds: false,
        fundShown: 0,
        allFunds: get(this.props.history, ['location', 'state', 'allFunds'], false),
        openDialog: false,
        fiterDialogOpen: false,
        riskLevelSelections: cloneDeep(originRiskLevelSelections),
        fundTypeSelections: cloneDeep(originFundTypeSelections),
        filterCount: 0,
        riskClassDialogOpen: false,
        windowWidth: window.innerWidth,
        DISDialogOpen: false
    };

    getUserFutureContributionAllocation = () => {
        const params = {
            user: this.props.auth.userInfo.username,
            fund_type: 'futureInvestment',
            'status[in]': 'success,completed',
            '$orderby': 'createddate desc',
            '$expand': 'instruction_histories/switch_in_fund'
        }
        apiTradeHistories.getUserTradeHistories(params).then(obj => {
            const DISFundHistories = [];
            if (obj && obj.status === 200 && obj.data.length > 0) {
                const instructionHistories = obj.data[0].instruction_histories;
                const filteredHistories = instructionHistories.filter(history => {
                    if (history.switch_in_fund.fund_code.includes('DIS')) {
                        DISFundHistories.push(history);
                    }
                    return !history.switch_in_fund.fund_code.includes('DIS');
                })
                
                const DISFundHistory = {
                    switch_in_percentage: 0,
                    switch_in_fund: {
                        fund_name: 'DefaultInvestmentStrategy',
                        fund_code: 'DIS148 / DIS149'
                    }
                }
                DISFundHistories.forEach(DISHistory => {
                    DISFundHistory['switch_in_percentage'] += DISHistory.switch_in_percentage;
                })
                if (DISFundHistory.switch_in_percentage > 0) {
                    filteredHistories.push(DISFundHistory);
                }

                const allFunds = this.state.funds;
                filteredHistories.forEach(history => {
                    const fundCode = history.switch_in_fund.fund_code;
                    const targetFundIndex = allFunds.findIndex(fund => fund.fundCode === fundCode);
                    const targetFund = allFunds[targetFundIndex];
                    targetFund['switchInPercentage'] = history.switch_in_percentage;
                    allFunds[targetFundIndex] = targetFund;
                    this.setState({ funds: allFunds.sort(this.dynamicSort("switchInPercentage", "desc")) });
                    this.sumPercentage();
                })
            }
        })
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        const { history, i18n } = this.props;
        const { instructionType } = this.state;
        if (instructionType) {
            Promise.resolve(this._getAllFunds()).then(() => {
                if (instructionType === 'new') {
                    this.getUserFutureContributionAllocation();
                }
            });
            this._getAsOfDate();
        } else {
            history.push('/' + i18n.language + '/manage-funds/0');
        }
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleOpenRiskClassDialog = () => {
        this.setState({ riskClassDialogOpen: true });
    }

    handleCloseRiskClassDialog = () => {
        this.setState({ riskClassDialogOpen: false });
    }

    handleOpenDISDialog = () => {
        this.setState({ DISDialogOpen: true });
    }

    handleCloseDISDialog = () => {
        this.setState({ DISDialogOpen: false });
    }
    

    dynamicSort(property, order) {
        const value = order === 'asc' ? 1 : -1;
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            let result = (a[property] < b[property]) ? -value : (a[property] > b[property]) ? value : 0;
            return result * sortOrder;
        }
    }

    handleCloseFilterDialog = () => {
        this.setState({ fiterDialogOpen: false });
    }

    FilterDialog = (props) => {
        const { open, t, classes, riskLevelSelections, fundTypeSelections } = props;
        let filterCount = 0;
        const filteredRisk = [];
        riskLevelSelections.forEach((selection, index) => {
            if (selection[index + 1].selected) {
                filterCount += 1;
                filteredRisk.push(index + 1);
            }
        });

        const filteredFundType = [];
        fundTypeSelections.forEach((selection, index) => {
            if (selection[fundType[index]].selected) {
                filterCount += 1;
                filteredFundType.push(fundType[index]);
            }
        });

        return (
            <Dialog open={open} classes={{ paper: classes.paper }}>
                <IconButton className={classes.closeButton} onClick={this.handleCloseFilterDialog}>
                    <CloseIcon />
                </IconButton>
                <Flex fontSize="1.5rem">
                    {`${t("ManageFunds:filter")} (${filterCount})`}
                </Flex>
                <Flex flexDirection="column" fontSize="1.1rem" mt="2rem">
                    {t("ManageFunds:riskClass")}
                    <Flex flex={1} flexDirection="row" mt="1rem" flexWrap="wrap">
                        {riskLevelSelections.map((selection, index) => {
                            const objectKey = index + 1;
                            const isSelected = selection[objectKey].selected;
                            return <div
                                key={index}
                                className="filter-selections"
                                onClick={()=>this.handleClickSelections(selection, objectKey, 'risk')}
                                style={{ justifyContent: 'center', padding: '0.4rem 1.5rem', borderColor: isSelected ? 'transparent' : '#e0e0e0', backgroundColor: isSelected ? '#08874d' : 'white', color: isSelected ? 'white' : 'black' }}
                            >
                                {selection[objectKey].level}
                            </div>
                        })}
                    </Flex>
                    <LineBreak />

                    {t("ManageFunds:fundType")}
                    <Flex flex={1} flexDirection="row" mt="1rem" flexWrap="wrap">
                        {fundTypeSelections.map((selection, index) => {
                            const objectKey = fundType[index];
                            const isSelected = selection[objectKey].selected;
                            return <div
                                key={index}
                                className="filter-selections"
                                onClick={()=>this.handleClickSelections(selection, objectKey, 'fund')}
                                style={{ padding: '0.4rem 1rem', borderColor: isSelected ? 'transparent' : '#e0e0e0', backgroundColor: isSelected ? '#08874d' : 'white', color: isSelected ? 'white' : 'black' }}
                            >
                                {t(`Fund:${selection[objectKey].fundType}`)}
                            </div>
                        })}
                    </Flex>
                    <LineBreak />
                </Flex>
                <Flex flex={1} justifyContent="center">
                    <Flex flex={1} className="button-wrapper" mt="2rem" maxWidth={700}>
                        <Button className="transparent-button" onClick={this.handleResetFilter} style={{ margin: 0, marginRight: 10 }}>{t("Common:Button.reset")}</Button>
                        <Button className="primary-button" onClick={()=>this.handleFilter(filteredRisk, filteredFundType, filterCount)} style={{ margin: 0, marginLeft: 10 }}>{t("Common:Button.confirm")}</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }

    handleResetFilter = () => {
        this.setState({ riskLevelSelections: cloneDeep(originRiskLevelSelections), fundTypeSelections: cloneDeep(originFundTypeSelections) });
    }

    handleFilter = (riskLevel, fundType, filterCount) => {
        apiFunds.getFundVersion().then(obj => {
            const versionNumber = obj.data.int_value;
            const param = {
                version: versionNumber,
                ...(riskLevel.length > 0 && {'risk_index[in]': riskLevel.toString()}),
                ...(fundType.length > 0 && {'fund_type[in]': fundType})
            }
            apiFunds.getAllFunds(param).then(obj => {
                if (obj && obj.status === 200 && obj.data.length > 0) {
                    const allFunds = [];
                    obj.data.forEach(item => {
                        if (item.sequence) {
                            const fund = {
                                fundId: item.fund_id,
                                title: item.fund_name,
                                subtitle: item.fund_code,
                                date: item.latest_date,
                                reviewDate: item.review_date,
                                riskClass: item.risk_index,
                                unit: item.holding_unit,
                                amount: item.amount,
                                sequence: item.sequence,
                                fundType: item.fund_type,
                                unitPriceReference: item.unit_price_reference,
                                switchInPercentage: null
                            }
                            allFunds.push(fund)
                        }
                    });
                    this.setState({ funds: allFunds.sort(this.dynamicSort("sequence", "asc")), fundShown: allFunds.length });
                } else {
                    this.setState({ funds: [], fundShown: 0 });
                }
            });
            this.setState({ filterCount: filterCount, fiterDialogOpen: false, showAllFunds: true, switchOutPercentage: 0 });
        })
    }

    handleClickSelections = (selection, objectKey, type) => {
        const { riskLevelSelections, fundTypeSelections } = this.state;
        if (type === 'risk') {
            const arrayIndex = objectKey - 1;
            riskLevelSelections[arrayIndex][objectKey].selected = !selection[objectKey].selected;
            this.setState({ riskLevelSelections: riskLevelSelections });
        } else if (type === 'fund') {
            const fundType = selection[objectKey].fundType;
            const selectionToUpdate = fundTypeSelections.find(item => item[fundType]);
            selectionToUpdate[fundType].selected = !selection[fundType].selected;
            this.setState({ fundTypeSelections: fundTypeSelections });
        }
    }

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }

    _getAsOfDate = () => {
        apiFunds.getCurrentAsOfDate().then(obj => {
            const asOfDate = obj.data.as_of_date;
            this.setState({ asOfDate: asOfDate });
        })
    }

    _getAllFunds = () => {
        return apiFunds.getFundVersion().then(obj => {
            const versionNumber = obj.data.int_value;
            const param = {
                version: versionNumber,
                '$orderby': 'sequence'
            }
            return apiFunds.getAllFunds(param).then(obj => {
                const allFunds = [];
                obj.data.forEach(item => {
                    if (item.sequence) {
                        const fund = {
                            fundId: item.fund_id,
                            fundCode: item.fund_code,
                            title: item.fund_name,
                            subtitle: item.fund_code,
                            date: item.latest_date,
                            reviewDate: item.review_date,
                            riskClass: item.risk_index,
                            unit: item.holding_unit,
                            amount: item.amount,
                            sequence: item.sequence,
                            fundType: item.fund_type,
                            unitPriceReference: item.unit_price_reference,
                            switchInPercentage: null
                        }
                        allFunds.push(fund)
                    }
                });
                return this.setState({ funds: allFunds.sort(this.dynamicSort("sequence", "asc")), fundShown: allFunds.length });
            });
        })
    }

    // BUTTON FUNCTION
    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    _checkUserFundCount = async (switchInFunds, switchOutFund) => {
        let userFundCount = 0;
        const { instructionType } = this.state;
        const param = {
            user: this.props.auth.userInfo.username,
        };
        return apiFunds.getUserFunds(param).then(async obj => {
            userFundCount = obj.data.length;
            if (instructionType === 'existing') {
                const fundNotDuplicated = switchInFunds.every(switchInFund => switchInFund.fundCode !== switchOutFund.fundCode);
                if (fundNotDuplicated) {
                    userFundCount--;
                }
            }
            await Promise.all(
                switchInFunds.map(switchInfund => {
                    const userFundBody = {
                        user: this.props.auth.userInfo.username,
                        'fund': switchInfund.fundId,
                        '$expand': 'fund'
                    };
                    return apiFunds.getUserFunds(userFundBody).then(obj => {
                        const previousFund = obj.data[0];
                        if (!previousFund) {
                            return userFundCount++;
                        }
                    });
                })
            );
            return userFundCount;
        })
    }

    handleReview = () => {
        const { i18n, t } = this.props;
        const { switchOutPercentage, switchOutFund, instructionType, funds, allFunds } = this.state;
        if (switchOutPercentage > 100) {
            alert(t("ManageFunds:reviewAlert"))
        } else if (switchOutPercentage < 100) {
            alert(t("ManageFunds:reviewAlert"))
        }
        else {
            const switchInFunds = funds.filter(fund => fund.switchInPercentage)
            if (allFunds) {
                if (switchInFunds.length < 2) {
                    alert(t("ManageFunds:includeAtLeast2DifferentConstituentFunds"))
                } else {
                    this.props.history.push('/' + i18n.language + '/manage-funds/3', { switchInFunds: switchInFunds, switchOutFund: switchOutFund, instructionType: instructionType, allFunds: allFunds });
                }
            } else {
                const userFundCountPromise = Promise.resolve(this._checkUserFundCount(switchInFunds, switchOutFund));
                userFundCountPromise.then(count => {
                    if (instructionType === 'existing' && switchInFunds.length === 1 && switchOutFund.fundCode === switchInFunds[0].fundCode && switchInFunds[0].switchInPercentage === '100' ){
                        alert(t("ManageFunds:inputtedAllocationIsSame"))
                    } else if (count < 2) {
                        alert(t("ManageFunds:includeAtLeast2DifferentConstituentFunds"))
                    } else {
                        this.props.history.push('/' + i18n.language + '/manage-funds/3', { switchInFunds: switchInFunds, switchOutFund: switchOutFund, instructionType: instructionType, allFunds: allFunds });
                    }
                })
            }
        }
    }

    sumPercentage = () => {
        const { funds } = this.state;
        let percentageSum = 0;
        funds.forEach(fund => {
            if(fund.switchInPercentage) percentageSum += +fund.switchInPercentage;
        })
        this.setState({ switchOutPercentage: percentageSum });
    }

    handlePercentageInput = (e, maxLength) => {
        const { funds } = this.state;
        if (e.target.value && !e.target.value.match(/^[0-9]+$/)) {
            alert('Please input valid number value should not contain decimal places')
        } else {
            const newFunds = funds;
            if (e.target.value.length > maxLength) {
                e.target.value = e.target.value.slice(0, maxLength);
            }
            const targetPercentage = e.target.value;
            const targetFundTitle = e.target.id;
            const targetFundIndex = newFunds.findIndex(fund => fund.title === targetFundTitle)
            const targetFund = newFunds[targetFundIndex];
            targetFund.switchInPercentage = targetPercentage;
            this.setState({ funds: newFunds });
    
            this.sumPercentage();
        }
    }

    handleShowAllFunds = () => {
        const {funds} = this.state
        this.setState({ showAllFunds: true, fundShown: funds.length });
    }

    handleClickFundSummaryLink = () => {
        window.open('https://pension.manulife.com/file/mpfree', '_blank');
    }

    render() {
        const { t, classes, i18n } = this.props;
        const { switchOutPercentage, switchOutFund, instructionType, funds, fundShown, asOfDate, openDialog, fiterDialogOpen, riskLevelSelections, fundTypeSelections, filterCount, riskClassDialogOpen, windowWidth, DISDialogOpen } = this.state;
        const voluntaryFund = funds.filter(fund => fund.switchInPercentage > 0);
        const isChinese = i18n.language === 'zh-HK'
        const dialogDescriptions = !isChinese ? engLatestUnitPriceReferenceDescriptions : cnLatestUnitPriceReferenceDescriptions;
        const locale = isChinese === 'zh-HK' ? 'zh-hk' : 'en';
        const asOfDateFormat = isChinese ? 'YYYY年M月D日' : 'D MMM, YYYY';
        const fundShownText = isChinese ? `顯示${funds.length}項基金中的${fundShown}項` : `${t("ManageFunds:showing")} ${fundShown} ${t("ManageFunds:of")} ${funds.length} ${t("ManageFunds:funds")}`
        const footer =
            <AppBar position="fixed">
                <Flex flex={1} justifyContent="center" className="fund-footer-container">
                    <Flex flex={1} flexDirection="column" maxWidth={700}>
                        {instructionType === 'existing' ? <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Flex className="light-black" style={{ fontSize: '1.2rem' }} >
                                {t("ManageFunds:switchOut")}
                            </Flex>
                            <Flex alignItems="center">
                                <Flex className="bold medium-font-size">
                                    {switchOutPercentage}
                                </Flex>
                                <Flex className="small-font-size light-black">
                                    &nbsp;%
                                </Flex>
                            </Flex>
                        </Flex> : 
                        <Flex flex={1} flexDirection="row" alignItems="center">
                            <Flex flex={2} className="black-text" style={{ fontSize: '1.2rem' }} >
                                {`${t("ManageFunds:total")} (${voluntaryFund.length})`}
                            </Flex>
                            {/* <Flex flex={1.3} flexDirection="column" ml={10}>
                                <Flex className="light-black" fontSize="0.85rem">
                                    {t("ManageFunds:mandatory")} %
                                </Flex>
                                <Flex alignItems="center">
                                    <Flex className="black-text medium-font-size">
                                        0
                                    </Flex>
                                    <Flex className="small-font-size light-black">
                                        &nbsp;%
                                    </Flex>
                                </Flex>
                            </Flex> */}
                            <Flex flex={1} flexDirection="column">
                                <Flex className="light-black" fontSize="0.85rem">
                                    {t("ManageFunds:contribution")} %
                                </Flex>
                                <Flex alignItems="center">
                                    <Flex className="black-text medium-font-size">
                                        {switchOutPercentage}
                                    </Flex>
                                    <Flex className="small-font-size light-black">
                                        &nbsp;%
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>}
                        <Flex className="button-wrapper" mt="1rem">
                            <Button className="transparent-button" style={{ margin: 0, marginRight: 10 }} onClick={this.back}>{t("Common:Button.back")}</Button>
                            <Button className="primary-button" style={{ margin: 0, marginLeft: 10 }} onClick={this.handleReview}>{t("Common:Button.review")}</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </AppBar>;

        return (                    
            <div>
                <Flex className="main__container fund-manager" mb="4rem">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title" style={{ paddingBottom: '0'}}>
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("ManageFunds:manageFunds")}
                            </Flex>
                        </Flex>
                        <Flex width="100%" alignItems="center" flexDirection="column">
                            <Flex flex={1} flexDirection="column" justifyContent="start" className="container" marginTop="1rem" width="100%" maxWidth={1000}>
                                <Flex flex={1} justifyContent="start" alignItems="center" marginY="1rem">
                                    <img src={quarterly} className="quarterly" alt="back-icon" onClick={this.handleClickFundSummaryLink}/>
                                    <Flex ml="1rem" fontSize="1.3rem">
                                        {`${t("ManageFunds:monthlyFundSummary")}`}
                                    </Flex>
                                </Flex>
                                {instructionType === 'existing' && <Flex mb="1rem" justifyContent="flex-start" fontSize="1rem">
                                    <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                        <img className="step-icon" src={stepCompleted} alt="step-1-green" />
                                        <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                            {t("ManageFunds:step1Green")}
                                        </Flex>
                                    </Flex>

                                    <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                        <img className="step-icon" src={step2Green} alt="step-2-white" />
                                        <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                            {t("ManageFunds:step2White")}
                                        </Flex>
                                    </Flex>

                                    <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                        <img className="step-icon" src={step3White} alt="step-3-white" />
                                        <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                            {t("ManageFunds:step3White")}
                                        </Flex>
                                    </Flex>
                                </Flex>}
                                <Flex flex={1} justifyContent="start" className="section-title black-text large-font-size">
                                    {instructionType === 'existing' ? `${t("ManageFunds:selectWhichFundToSwitchInto")}` : `${t("ManageFunds:changeYourFutureInvestmentsInstruction")}`}
                                </Flex>
                                <Flex flex={1} justifyContent="start" className="light-black mt10 small-font-size ">
                                    <p style={{ fontSize: 'inherit' }}>
                                        {instructionType === 'existing' ? `${t("ManageFunds:enterSwitchIn%ForTheFundYouWouldLikeToSwitchIn")}` : `${t("ManageFunds:yourCurrentAllocation%ForFutureInvestmentsIsShownBelowPleaseInputYourNewInvestmentAllocation")}`}
                                        {/*<a href={instructionType === 'existing' ? `https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/switch-funds-pf-step-by-step-guide.html` : `https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/change-future-investments-pf.html#guide`} target="_blank" rel="noopener noreferrer" className='hyperlink link'><b>{t("Common:General.learnMore")}</b></a>*/}
                                    </p>
                                </Flex>

                                {instructionType === 'existing' && <SwitchOutFund t={t} fundType={switchOutFund} locale={locale} asOfDate={asOfDate} asOfDateFormat={asOfDateFormat} />}

                                {instructionType === 'existing' && <Flex flexDirection="column" mt="1rem">
                                    <Flex className="black-text medium-font-size">
                                        {t("ManageFunds:switchInto")}
                                    </Flex>
                                    <Flex className="small-font-size">
                                        {t("ManageFunds:AsOf")} {moment(asOfDate).locale(locale).format(asOfDateFormat)}
                                    </Flex>
                                </Flex>}

                                <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center" mt="2rem">
                                    <Flex flex={2} justifyContent="start" className="light-black small-font-size">
                                        {fundShownText}
                                    </Flex>
                                    {/* <Flex flex={1.5} flexDirection="row" justifyContent="flex-end" className="button-wrapper">
                                        <Button className="filter-button m0" onClick={() => { this.setState({ fiterDialogOpen: true })}}>
                                            <img src={filterIcon} width={25} alt="filter-icon" style={{fontWeight: 'bold'}} />
                                            <Flex className="light-black small-font-size">
                                                {t("ManageFunds:filter")} ({filterCount})
                                            </Flex>
                                        </Button>
                                    </Flex> */}
                                </Flex>

                                {instructionType !== 'existing' && <Flex flex={1} className="black-text stripe" fontSize="0.85rem" alignItems="center" marginY="1rem" paddingY="1rem">
                                    <Flex flex={2} flexDirection="column">
                                        <Flex>{t("ManageFunds:fundCode")} / {t("ManageFunds:fundName")}</Flex>
                                        <Flex>{t("ManageFunds:fundType")}</Flex>
                                        <Flex>{t("ManageFunds:riskClass")}</Flex>
                                    </Flex>
                                    {/* <Flex flex={1.3} ml={10}>
                                        {t("ManageFunds:mandatory")} %
                                    </Flex> */}
                                    <Flex flex={1}>
                                        {t("ManageFunds:contribution")} %
                                    </Flex>
                                </Flex>}

                                
                                {funds && funds.length > 0 ? <Flex flexDirection="column">
                                    {instructionType === 'existing' && <LineBreak fullWidth/>}
                                    <SwitchInFundList t={t} onChange={this.handlePercentageInput} component={this} funds={funds} instructionType={instructionType} />
                                    {/* {!showAllFunds && <Flex flex={1} mt="-1.5rem"  className='black stripe small-font-size' paddingY="0.5rem" justifyContent="center" alignItems="center" >
                                        <Flex onClick={this.handleShowAllFunds} style={{ cursor:'pointer' }}>
                                            <KeyboardArrowDown />
                                            <Flex>{t("ManageFunds:showAllFunds")}</Flex>
                                        </Flex>
                                    </Flex>} */}
                                </Flex> :
                                <Flex flexDirection="column" marginY="2rem" fontSize="1.5rem" color="black">
                                    <Flex>{t("ManageFunds:weCouldntFindAnyResults")}</Flex>
                                    <Flex>{t("ManageFunds:wouldYouLikeToRefineYourSearchAndTryAgain")}</Flex>
                                </Flex>
                                }
                                <Flex className="black-text small-font-size" mt="1rem" flexDirection="column" mb="1rem">
                                    {instructionType === 'existing' && <Flex flexDirection="column">
                                        <Flex marginTop="1rem" className="light-black bold">
                                            {t("ManageFunds:latestUnitPriceReferenceNotTradingPrice")} 
                                        </Flex>
                                        <Flex marginTop="1rem" marginBottom="5rem" className="light-black">
                                            {t("ManageFunds:latestUnitPriceReferenceDescription")} 
                                        </Flex>
                                    </Flex>}

                                    {t("ManageFunds:notes")}:
                                    <Flex flexDirection="column" mt="1rem" className="light-black">
                                        <Flex flexDirection="row">
                                            <Flex flex={1/15} maxWidth={30}>1.</Flex>
                                            <Flex flex={1}>{instructionType === 'existing' ? t("ManageFunds:switchInPercentageOfEachSelectedFundMustBeAtLeastFivePercentAndInAWholeNumber") : t("ManageFunds:allocationPercentageOfEachSelectedFundMustBeAtLeastFivePercentAndInAWholeNumber")}</Flex>
                                        </Flex>
                                        <Flex flexDirection="row">
                                            <Flex flex={1/15} maxWidth={30}>2.</Flex>
                                            <Flex flex={1}>{instructionType === 'existing' ? t("ManageFunds:totalSwitchInPercentageMustAddUpToHundredPercent") : t("ManageFunds:totalAllocationPercentageMustAddUpToHundredPercent")}</Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            {fiterDialogOpen && <this.FilterDialog open={fiterDialogOpen} riskLevelSelections={riskLevelSelections} fundTypeSelections={fundTypeSelections} t={t} classes={classes} />}
                            {riskClassDialogOpen && <RiskDialog open={riskClassDialogOpen} i18n={i18n} t={t} handleCloseRiskClassDialog={this.handleCloseRiskClassDialog} windowWidth={windowWidth} />}
                            {DISDialogOpen && <DISDialog open={DISDialogOpen} i18n={i18n} t={t} handleCloseDialog={this.handleCloseDISDialog} />}
                            <BottomDialog open={openDialog} title={t('ManageFunds:unitPriceReference')} descriptions={dialogDescriptions} handleCloseDialog={this.handleCloseDialog} t={t} />
                        </Flex>
                    </Flex>
                </Flex>
                {footer}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(ManageFunds2))));
