import { api } from "./_ApiFactoryWithHeader";


export const apiConfiguration = {
    getConfigurationList: (params, cancelToken) => {
        const url = 'configurations';
        return api.get(url, params, null, cancelToken);
    },
    getConfigurationDetail: (id, params, cancelToken) => {
        const url = 'configurations/' + id;
        return api.get(url, params, null, cancelToken);
    },
    updateConfiguration: (id, body) => {
        const url = 'configurations/' + id;
        return api.put(url, body);
    },
};