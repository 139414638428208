// Essential for all components
import React, { Component, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { setBreadcrumb } from "../../Redux/Action/breadcrumbAction";
import { apiTenant } from "../../Api/_ApiTenant";
import { apiStore } from "../../Api/_ApiStore";
import { apiUsers } from "../../Api/_ApiUsers";
import { apiQrCode } from "../../Api/_ApiQrCode";
import * as sha1 from 'js-sha1';
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { clone, get, map, filter, forEach, some, keyBy, isEqual, flattenDeep } from 'lodash-es';
import Bluebird from 'bluebird';

import ErrorMessage from "../../components/100Include/ErrorMessage";
import { getRoleDisplay, getRoleSelectedDisplay } from "../../utils/Utility";
import moment from "moment";
import Files from 'react-files'
import { IMAGE_UPLOAD_TYPE, Roles } from "../../constants/common";
import { MySnackbarContentWrapper } from "../../components/102MaterialDesign/MySnackbarContentWrapper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const USER_QR_CODE_GROUP_ID = '84efa483-7215-4aa0-9fbe-47310fcc463e';

class TenantMemberUpdate extends Component {

    state = {
        isEdit: false,
        tenantMember: {
            display_name: '',
            qr_code: '',
            activated: null,
            active: null,
            tenant_member_role: null,
            selected_stores: [],
        },
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
        tenantMemberRoles: [],
        stores: [],
        storeMap: {},
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getTenantMemberRoleList();
        this.getStoreList();
        if (this.props.id) {
            this.setState({ isEdit: true })
            this.getTenantMemberDetail();
        } else {
            const { t } = this.props;
            const userItemBreadcrumb = [{
                title: t("UserManagement:addUser"),
                link: null
            }];
            this.props.setBreadcrumbP({ breadcrumbData: userItemBreadcrumb });
        }
    }

    getTenantMemberDetail = () => {
        const { i18n } = this.props;
        apiTenant.getTenantMemberDetail(this.props.id, {
            $expand: 'tenant_member_stores'
        }).then(obj => {
            if (obj && !obj.error) {
                if (obj.data) {
                    const tenantMember = obj.data;
                    const tenantMemberItemBreadcrumb = {
                        title: `Edit [${tenantMember.display_name}]`,
                        link: null
                    };
                    this.setBreadcrumbWithUserNumber(tenantMemberItemBreadcrumb);

                    tenantMember.selected_stores = map(tenantMember.tenant_member_stores, 'store');
                    this.setState({ tenantMember });
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        });
    }

    getTenantMemberRoleList = () => {
        apiTenant.getTenantMemberRoleList().then(obj => {
            if (obj && !obj.error) {
                if (obj.data) {
                    const tenantMemberRoleData = obj.data;
                    this.setState({
                        tenantMemberRoles: tenantMemberRoleData
                    });
                }
            } else {
                this.props.history.push('/' + this.props.i18n.language + '/login');
            }
        });
    }

    getStoreList = () => {
        apiStore.getStoreList({
            tenant: localStorage.getItem('asTenantId'),
            $orderby: 'name'
        }).then(obj => {
            if (obj && !obj.error) {
                if (obj.data) {
                    const stores = obj.data;
                    const storeMap = {};
                    forEach(stores, store => {
                        storeMap[store.store_id] = store.name;
                    });
                    this.setState({
                        stores,
                        storeMap,
                    });
                }
            } else {
                this.props.history.push('/' + this.props.i18n.language + '/login');
            }
        });
    }

    setBreadcrumbWithUserNumber = (userInfoBreadCrumb) => {

        const { t, i18n } = this.props;
        const params = {
            '$top': 1,
            '$select': 'display_name'
        };
        apiTenant.getTenantMemberList(params).then((rs) => {
            if (rs && rs.status === 200) {

                const dataSum = rs.headers['content-range']


                const totalUserBreadcrumb = { title: t('UserManagement:breadcrumb') + ' (' + rs.data.length + ')', link: '/' + i18n.language + '/tenant-member-management' }

                this.props.setBreadcrumbP({ breadcrumbData: [totalUserBreadcrumb, userInfoBreadCrumb] });

            } else {
                console.log("eCb : ", rs);
            }
        })
    }


    // BUTTON FUNCTION

    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/tenant-member-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _addTenantMemberAsync = (value) => {
        const { i18n } = this.props;
        const qrCodeDisplayId = 'User QR Code: ' + value.display_name;
        const body = {
            tenant: localStorage.getItem('asTenantId'),
            display_name: value.display_name,
            qr_code: {
                qr_code_group: USER_QR_CODE_GROUP_ID,
                qr_code_display_id: qrCodeDisplayId,
                qr_code_content: ''
            },
            activated: false,
            active: value.active,
            tenant_member_role: value.tenant_member_role,
            tenant_member_stores: map(value.selected_stores, (storeId) => {
                return {
                    store: storeId
                };
            }),
        };
        apiTenant.addTenantMember(body, {
            $expand: 'qr_code'
        }).then(obj => {
            if (obj.status === 201) {
                let qrCodeId = get(obj, ['data', 'qr_code']);
                if (qrCodeId && qrCodeId.qr_code_id) {
                    qrCodeId = qrCodeId.qr_code_id;
                }
                return apiQrCode.update(qrCodeId, {
                    qr_code_content: JSON.stringify({
                        qr_code_id: qrCodeId,
                        qr_code_display_id: qrCodeDisplayId
                    })
                }).then(obj2 => {
                    if (obj2.status === 200) {
                        this.setState({
                            openSnackbar: true,
                            messageSnackbar: 'Add new tenant member successfully',
                            variantSnackbar: 'success'
                        }, () => {
                            setTimeout(() => this.props.history.push('/' + i18n.language + '/tenant-member-management?previous=true'), 1500);
                        });
                    } else {
                        const error = obj2.data && obj2.data.error ? obj2.data.error : 'Add new tenant member failed';
                        this.setState({
                            openSnackbar: true,
                            messageSnackbar: error,
                            variantSnackbar: 'error'
                        });
                    }
                });
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Add new tenant member failed';
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: error,
                    variantSnackbar: 'error'
                });
            }
        });
    }

    _updateTenantMemberAsync = (value) => {
        const { i18n } = this.props;
        const body = clone(value);
        delete body.selected_stores;
        const existingSelectedStoreMap = keyBy(map(value.tenant_member_stores, 'store'));
        const newSelectedStoreMap = keyBy(value.selected_stores);
        if (!isEqual(existingSelectedStoreMap, newSelectedStoreMap)) {
            body.tenant_member_stores = map(value.selected_stores, storeId => {
                return {
                    store: storeId
                };
            });
        }

        const updateTenantMemberPromise = apiTenant.fullUpdateTenantMember(this.props.id, body);

        let userStoreUpdatePromise;
        const username = body.user;
        if (username) {
            userStoreUpdatePromise = updateTenantMemberPromise.then(obj => {
                if (obj.status === 200) {
                    return Bluebird.all([
                        apiTenant.getTenantMemberStoreList({
                            'tenant_member/user': username
                        }),
                        apiUsers.getUserStoreList({
                            user: username
                        })
                    ]);
                } else {
                    throw obj;
                }
            }).then(responses => {
                const [tenantMemberStoreResponse, userStoreResponse] = responses;
                if (tenantMemberStoreResponse.status != 200) {
                    throw tenantMemberStoreResponse;
                }
                if (userStoreResponse.status != 200) {
                    throw userStoreResponse;
                }
                const tenantMemberStores = tenantMemberStoreResponse.data;
                const userStores = userStoreResponse.data;
                const updatedTenantMemberStores = map(tenantMemberStores, 'store');
                const updatedTenantMemberStoreMap = keyBy(updatedTenantMemberStores);
                const existingUserStores = map(userStores, 'store');
                const existingUserStoreMap = keyBy(existingUserStores);
                if (!isEqual(existingUserStoreMap, updatedTenantMemberStoreMap)) {
                    const deleteUserStoreRequest = Bluebird.map(userStores, userStore => {
                        return apiUsers.deleteUserStore(userStore.user_store_id);
                    }, {
                        concurrency: 4
                    });
                    const createUserStoreRequest = deleteUserStoreRequest.then(() => {
                        return Bluebird.map(updatedTenantMemberStores, storeId => {
                            return apiUsers.addUserStore({
                                user: username,
                                store: storeId,
                            });
                        }, {
                            concurrency: 4
                        });
                    });
                    return Bluebird.all([
                        deleteUserStoreRequest,
                        createUserStoreRequest
                    ]);
                }
            });
        }
        Bluebird.all([
            updateTenantMemberPromise,
            userStoreUpdatePromise,
        ]).then(responses => {
            const flattenResponses = flattenDeep(responses);
            const errorResponses = filter(flattenResponses, response => {
                return response && response.status >= 400;
            });
            if (errorResponses.length == 0) {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Update tenant member successfully',
                    variantSnackbar: 'success'
                }, () => {
                    setTimeout(() => this.props.history.push('/' + i18n.language + '/tenant-member-management?previous=true'), 1500);
                });
            } else {
                const error = get(errorResponses, [0, 'data', 'error']) || 'Update tenant member failed';
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: error,
                    variantSnackbar: 'error'
                });
            }
        });
    }

    _submitUserForm = (value) => {
        if (this.state.isEdit) {
            this._updateTenantMemberAsync(value);
        } else {
            this._addTenantMemberAsync(value);
        }
    }

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        const { tenantMemberRoles, stores, storeMap, isEdit } = this.state;

        return values && (
            <Form id="userForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                        <Grid item xs={12} className="ErrorMessage form-item">
                            <ErrorMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            {t("TenantManagement:displayName")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="display_name" type="text" placeholder="" maxLength="191" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="display_name" />
                            </Grid>
                        </Grid>
                    </Grid>

                    {isEdit && <Fragment>
                        <Grid container direction="row" justify="center" alignItems="flex-start" >
                            <Grid item xs={2} className="form-item">
                                QR Code
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {values.qr_code}
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="flex-start" >
                            <Grid item xs={2} className="form-item">
                                Scanned
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {values.activated ? 'YES' : 'NO'}
                            </Grid>
                        </Grid>
                    </Fragment>}

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <label htmlFor="active_checkbox">Active</label>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field id="active_checkbox" type="checkbox" name="active" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="active" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            {t("TenantManagement:tenantType")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="tenant_member_role">
                                    {({ field, form, meta }) => (
                                        <FormControl className='full-width'>
                                            <Select
                                                required
                                                classes={{
                                                    root: 'custom-select'
                                                }}
                                                disableUnderline={true}
                                                MenuProps={MenuProps}
                                                {...field}
                                                onChange={field.onChange}
                                            >
                                                {tenantMemberRoles.map(item => {
                                                    const value = item.tenant_member_role_id;
                                                    return <MenuItem key={value} value={value}>
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                                
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="tenant_member_role" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            收集點
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="selected_stores">
                                    {({ field, form, meta }) => {
                                        const valueMap = keyBy(field.value);
                                        return <FormControl className='full-width'>
                                            <Select
                                                multiple
                                                classes={{
                                                    root: 'custom-select'
                                                }}
                                                disableUnderline={true}
                                                MenuProps={MenuProps}
                                                {...field}
                                                onChange={(evt, child) => {
                                                    const target = evt.target;
                                                    const values = target.value;
                                                    if (some(values, value => value === 'all')) {
                                                        const newValue = values.length <= stores.length ? map(stores, 'store_id') : [];
                                                        form.setFieldValue(target.name, newValue);
                                                    } else {
                                                        field.onChange(evt, child);
                                                    }
                                                }}
                                                renderValue={(values) => {
                                                    return map(values, value => storeMap[value]).join(', ');
                                                }}
                                            >
                                                <MenuItem value={'all'}>
                                                    <Checkbox checked={field.value.length === stores.length} />
                                                    <ListItemText primary={'All'} />
                                                </MenuItem>
                                                {stores.map(item => {
                                                    const value = item.store_id;
                                                    return <MenuItem key={value} value={value}>
                                                        <Checkbox checked={valueMap.hasOwnProperty(value)} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    }}
                                </Field>
                                
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="tenant_member_role" />
                            </Grid>
                        </Grid>
                    </Grid>

                    {values.isEdit &&
                        <Grid container direction="row" justify="center" alignItems="flex-start" className="pt10">
                            <Grid item xs={2} className="form-item">
                                {t("UserManagement:updatedDate")}
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD') : ''}

                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, tenantMember, openSnackbar, variantSnackbar, messageSnackbar } = this.state;
        const inputTenantMember = { ...tenantMember, isEdit: isEdit };
        const Schema = Yup.object().shape({
            display_name: Yup.string().required("Username is required"),
            active: Yup.boolean().required("Active is required"),
            tenant_member_role: Yup.string().required("Role is required"),
        });

        return (
            tenantMember && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputTenantMember}
                            validationSchema={Schema}
                            onSubmit={this._submitUserForm}
                            component={this.formConfiguration}
                        />

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openSnackbar}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnackbar}
                        >
                            <MySnackbarContentWrapper
                                onClose={this.handleCloseSnackbar}
                                variant={variantSnackbar}
                                message={messageSnackbar}
                            />
                        </Snackbar>
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantMemberUpdate)));
