// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox'
import {connect} from "react-redux";

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";

class Reward2 extends Component {

    state = {
        unlockedRewards: this.props.history.location.state.unlockedRewards,
        rewardTitle: this.props.history.location.state.rewardTitle,
        voucherPage: this.props.history.location.state.url,
        voucherCount: this.props.history.location.state.voucherCount,
    };

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    QRCodeContainer = () => {
        const { voucherCount } = this.state;
        const qrCode = [];
        for (var i = 0; i < voucherCount; i++) {
            qrCode.push(
                <div style={{ position: 'relative', lineHeight: 3 }}>
                    <div>
                        <img src="https://evhk.aswatson.com/evp/webview/qrcode/303138e29ca8bb3c28f60a58f9c98bab87ddcd84cbaa25ae814ce2f288158d8bcdb1f9efacda2483881321abd64f7d2f8b13" alt="eGift Voucher Code" style={{ display: 'block', margin: 'auto', width: '60%' }} />
                        <div style={{ display: 'block', margin: 'auto', textAlign: 'center' }} id="qrTimer"></div>
                        <br />
                    </div>
                    <br />
                    <span><b>Amount:</b> HKD50.00</span>
                    <br />
                    <span><b>Expiry Date:</b> 2024-08-31</span>
                    <br />
                    <span><b>Status:</b> ACTIVATED</span>
                    <br />
                    <span><b>Ref No:</b> 9001762006</span>
                </div>
            )
        };
        return qrCode;
    }

    render() {
        const { t } = this.props;
        const { unlockedRewards, rewardTitle, voucherPage } = this.state;
        return (                    
            <div>
                <div className="main__container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title" fontSize="1.2rem">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {`${t("Reward:myRewards")} (${unlockedRewards}/5): ${rewardTitle}`}
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
                {voucherPage &&
                    <div style={{ display: 'block', padding: '1rem'}}>
                        <div>
                            <img src="https://evhk.aswatson.com/evp/templates/webview/900001/EGP01/logo.png" alt="logo" height="50" width = "160" />
                            <br/>			
                            <div width="100%">
                                <img src="https://evhk.aswatson.com/evp/templates/webview/900001/EGP01/banner.jpg" alt="banner" height="150" width="100%" />
                            </div>
                            <br/>
                            <br/>
                            <this.QRCodeContainer />
                            <br/>
                            <br/>
                            親愛的顧客，
                            <br/>
                            請小心妥善保存及保管你的電子禮券，如有遺失將不獲補發。
                            <br/>
                            <br/>
                            Dear Customer,
                            <br/>
                            Please keep your eGift Voucher properly and confidential. This eGift Voucher is invalid and not replaceable if lost.
                            <br/>
                            <br/>
                            Status:
                            <br/>
                            有效 Activated
                            <br/>
                            已過期Expired
                            <br/>
                            已兌換Redeemed
                            <br/>
                            已註銷Voided
                            <br/>
                            <br/>
                            <b>Terms and Conditions of each eVouchers/電子禮券之條款及條件:</b>
                            <br/>
                            1. Any enquiries regarding redemption of eVouchers at PARKnSHOP’s retail stores, please :
                            <br/>
                            - call the Customer Service Hotline of  PARKnSHOP (HK) Limited (“PARKnSHOP”) at 2606 8658; or
                            <br/>
                            - By email to ParknshopCS@aswatson.com.
                            <br/>
                            任何問題關於在百佳店舖使用此電子禮券, 由百佳超級市場(香港)有限公司 (“百佳”)處理。顧客查詢請:
                            <br/>
                            致電 百佳顧客服務部電話: 2606 8658 或
                            <br/>
                            電郵至: 百佳顧客服務部 ParknshopCS@aswatson.com 。
                            <br/>
                            <br/>
                            2. This eVoucher entitles the bearer to exchange the equivalent amount for goods   on or before the expiry date at the following designated retail stores in Hong Kong and Macau as operated by PARKnSHOP :<br />
                            (i) PARKnSHOP, TASTE, Fusion, Food Le Parc, International, GREAT Foodhall, Gourmet in Hong Kong, and 
                            <br />
                            (ii) PARKnSHOP , TASTE in Macau. This eVoucher shall be invalid after the expiry date.
                            <br/>
                            此電子禮券持有人可在本劵有效期內於以下指定位於香港及澳門由百佳營運之店舖換取同等價值貨品，逾期無效。指定店舖為
                            <br />
                            (i) 於香港之任何百佳超級市場、TASTE、Fusion、Food Le Parc、International、GREAT Foodhall、Gourmet及
                            <br />
                            (ii) 於澳門之任何百佳超級市場、TASTE。
                            <br/>
                            <br/>
                            3. This eVoucher is not exchangeable for cash or PARKnSHOP physical voucher or eVouchers in other denominations. No change will be given either in cash or in other form.<br/>
                            此電子禮券不能用以套換現金或其他面值之百佳禮券，包括紙張禮券和電子禮券。持有人不會獲現金或其他形式找贖。
                            <br/>
                            <br/>
                            4. This eVoucher shall be maintained and presented in its digital format  and any print-out format will not be accepted. It will become void once used, no replacements will be issued if lost.<br/>
                            此電子禮券須以電子方式保存及以數碼格式使用，百佳不接受任何打印出來之副本。電子禮券一經使用即告作廢，如遺失將不會獲補發。
                            <br/>
                            <br/>
                            5. This eVoucher will not be accepted for online/phone/fax order purchases.
                            <br/>
                            此電子禮券不適用於網上/電話/傳真訂購。
                            <br/>
                            <br/>
                            6 PARKnSHOP reserves the right of final decision in case of any dispute arising from this eVoucher.
                            <br/>
                            如有任何爭議，百佳保留最終決定權。
                            <br/>
                            <br/>
                            7. PARKnSHOP is not responsible for all loss or damage caused by any fraudulent or unauthorised use of any eVouchers.
                            <br/>
                            對任何以欺詐或非法手段、或未經授權而使用之任何電子禮卷所引致的損失或損害，百佳不會負責。
                            <br/>
                            <br/>
                            8. PARKnSHOP reserves the right to change these terms and conditions without prior notice.
                            <br/>
                            百佳保留權利對本條款及條件作出更改，不另行通知。
                            <br/>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Reward2)));
