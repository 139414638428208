// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import { Flex } from 'reflexbox';

import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import RankingTable from '../../components/RankingTable';
import '../../css/04Ranking/_ranking.scss';

const imagePaths = {
    'en-US': {
        iconMPF: require('../../images/Landingpg/Registration-period/logo english.png'),
    },

    'zh-HK': {
        iconMPF: require('../../images/Landingpg/Registration-period/MPFgameLogo.png'),
    }

};
class Ranking extends Component {

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/index');
    }

    render() {
        const { t,i18n } = this.props;
        return (                    
            <div>
                <Flex className="main__container bg-ranking-badge head-container">
                    <Flex flex={1} flexDirection="column">
                        {/*<Flex flex={1} justifyContent="start" alignItems="center" className="header-title">*/}
                        {/*    <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>*/}
                        {/*    <Flex ml="1rem">*/}
                        {/*        {t("Ranking:myRanking")}*/}
                        {/*    </Flex>*/}
                        {/*</Flex>*/}
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="0.5rem">
                                    {t("Ranking:ranking")}
                            </Flex>
                            {/* <h3 className="back-icon-content" >{t("Ranking:ranking")}</h3> */}
                        </Flex>
                        <Flex flex={1} justifyContent="center" alignItems="center" mb="2rem">
                            <img src={imagePaths[i18n.language].iconMPF} className="logo" alt="logo"/>
                        </Flex>
                        <RankingTable maxWidth={600} />
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Ranking)));
