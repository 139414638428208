// Essential for all components
import { Button, styled } from '@material-ui/core';
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox';
import Papa from 'papaparse';
import moment from 'moment';

import { apiUsers } from '../../Api/_ApiUsers';
import { apiFunds } from '../../Api/_ApiFunds';
import { apiTask } from '../../Api/_ApiTask';
import {get} from "lodash-es";
import {apiUserAccount} from "../../Api/_ApiUserAccount";

const StyledButton = styled(Button)({
    width: '40%',
    fontSize: '1rem',
    border: 2,
    borderStyle: 'solid',
    backgroundColor: '#ec6453',
    borderColor: '#c0c0c0',
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    marginBottom: '2rem',
    '&:hover': {
        backgroundColor: '#ec6453',
    },
});

const StyledDiv = styled('div')({
    fontSize: '1rem',
    textAlign: 'center',
    borderStyle: 'solid',
    padding: '1rem',
    backgroundColor: '#ec6453',
    color: 'white',
    borderRadius: 5,
    marginRight: '1rem'
});

class Admin extends Component {

    handleExportUser = () => {
        const params = {
            'email[null]': false,
            'policy_number[null]': false,
            'legal_full_name[null]': false,
            '$orderby': 'createddate desc'
        }
        apiUsers.getUserList(params).then(obj => {
            const userList = obj.data.map(item => {
                const user = {};
                user.username = item.username;
                user.nickname = item.nickname;
                user.legal_full_Name = item.legal_full_name;
                user.policy_number = item.policy_number;
                user.email = item.email;
                user.phone = item.phone;
                user.status = item.status;
                return user;
            })
            var csv = Papa.unparse(userList);
            this.downloadCsv(csv, 'pending_user_list');
        })
    }

    downloadCsv = (data, fileName) => {
        var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
        var csvURL =  null;
        if (navigator.msSaveBlob){
            csvURL = navigator.msSaveBlob(csvData, `${fileName}.csv`);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${fileName}.csv`);
        tempLink.click();
    }

    handleImportUsers = (csvData) => {
        const approvedPolicyNos = [];
        const rejectedPolicyNos = [];
        csvData.forEach((data, index) => {
            let status = data[1];
            if (index !== 0 && status) {
                status = status.toLowerCase().replace(/\s/g,'');
                let policyNo = data[0]
                if (status === 'approved') {
                    approvedPolicyNos.push(policyNo);
                } else if (status === 'rejected') {
                    rejectedPolicyNos.push(policyNo)
                }
            }
        })
        const approvedPolicyNosStr = approvedPolicyNos.toString();
        const rejectedPolicyNosStr = rejectedPolicyNos.toString();
        Promise.all([this.updateApprovedUser(approvedPolicyNosStr), this.deleteRejectedUser(rejectedPolicyNosStr)]).then(results => {
            if (results.every(res => res && res.status === 204)) {
                alert("Successfully imported");
                document.getElementById("import-user-button").value = ""
            }
        })
    }


    deleteRejectedUser = (rejectedPolicyNosStr) => {
        const body = {
            policy_numbers: rejectedPolicyNosStr,
        }
        return apiUsers.deleteRejectedUsers(body);
    }
    
    updateApprovedUser = (approvedPolicyNosStr) => {
        const body = {
            policy_numbers: approvedPolicyNosStr,
            send_welcome_email: true,
            status: 'approved'
        }
        return apiUsers.sendWelcomeEmail(body)
    }

    deleteUnversionedFund = () => {
        const body = {
            'version[null]': true
        }
        return apiFunds.deleteFund(body);
    }
        
    handleImportFundPrice = (csvData) => {
        const topFunds = [];
        let internalConfigurationId = 8;
        let topIndex = 1;
        const date = csvData[2][0];
        const dateTimestamp = moment(moment(date).locale('en').format("D MMM YYYY")).endOf('day').valueOf();
        if (dateTimestamp) {
            const funds = [];
            const deleteUnversionedFundPromise = Promise.resolve(this.deleteUnversionedFund());
            apiFunds.setReadyAsOfDate(dateTimestamp).then(() => {
                deleteUnversionedFundPromise.then(() => {
                    csvData.forEach((data, index) => {
                        const fundDetails = {};
                        if (index > 3 && index < 34 && data[2]) {
                            fundDetails.fund_name = data[1].trim();
                            fundDetails.fund_code = data[2];
                            fundDetails.latest_date = dateTimestamp;
                            fundDetails.unit_price_reference = data[3] ? +data[3] : null;
                            fundDetails.sequence = index - 3;
                            fundDetails.interest = data[4] ? data[4] : null;
                            fundDetails.dividend = data[5] ? data[5] : null;
                            fundDetails.fund_type = data[6];
                            fundDetails.risk_index = data[7] ? data[7] : null;
                            fundDetails.review_date = data[8] ? moment(moment(data[8]).locale('en').format("D MMM YYYY")).endOf('day').valueOf() : null;
                            funds.push(fundDetails);
                        }
                        if (['SHK148', 'SHK149'].includes(data[2])) {
                            const DISFundDetails = { ...fundDetails };
                            const fundName = data[1].trim();
                            const fundCode = data[2];
                            DISFundDetails.fund_name = `${fundName}(DIS)`;
                            DISFundDetails.fund_code = fundCode.replace('SHK', 'DIS');
                            DISFundDetails.sequence = null;
                            funds.push(DISFundDetails);
                        }
                        if (index > 33 && index < 37 && data[2]) {
                            const topFund = {
                                internal_configuration_id: internalConfigurationId,
                                name: `Top ${topIndex} Fund`,
                                text_value: data[2]
                            }
                            topFunds.push(topFund);
                            internalConfigurationId++;
                            topIndex++;
                        }
                    })
                    Promise.all(topFunds.map(topFund => {
                        const body = { ...topFund }
                        delete body['internal_configuration_id']
                        return apiFunds.setTopThreeFunds(topFund.internal_configuration_id, body)
                    })).then(promises => {
                        if (promises.every(promise => promise.status === 200)) {
                            this.createFund(funds);
                        } else {
                            alert("Error. Please try again.")
                        }
                    })
                })
            })
        } else {
            alert("Incorrect date");
        }
    }

    createFund = (funds) => {
        Promise.all(
            funds.map(fund => {
                return apiFunds.createFund(fund);
            })
        ).then(promises => {
            if (promises.every(promise => promise.status === 201)) {
                alert("Successfully imported");
            } else {
                alert("Error. Please try again.")
            }
            document.getElementById("import-fund-button").value = ""
        });
    }

    getUnversionedFund = () => {
        const param = {
            'version[null]': true
        }
        return apiFunds.getAllFunds(param).then(obj=> obj.data)
    }

    updateFundPrice = () => {
        const task = {};
        task.task = 'update fund price';
        task.start_date = moment().valueOf();
        task.end_date = moment().add(30, 'minutes').valueOf();
        task.is_task_completed = false;
        this.createTask([task]);
    }

    downloadFundPriceSample = () => {
        const downloadUrl = '/Fund Price.csv';
        window.location.href = downloadUrl;
    }

    exportScheduledList = () => {
        const param = {
            '$orderby': 'start_date'
        }
        apiTask.getAllTasks(param).then(obj => {
            const tasksList = obj.data.map(item => {
                const task = {};
                task.task = item.task;
                task.start_date = moment(item.start_date).format('YYYY-MM-DD HH:mm:ss');
                task.end_date = moment(item.end_date).format('YYYY-MM-DD HH:mm:ss');
                task.is_task_completed = item.is_task_completed;
                return task;
            })
            var csv = Papa.unparse(tasksList);
            this.downloadCsv(csv, 'scheduled_task_list');
        })
    }

    createTask = (tasks) => {
        Promise.all(
            tasks.map(task => {
                return apiTask.createTask(task);
            })
        ).then(() => {
            alert("Successfully imported");
            document.getElementById("import-task-button").value = ""
        });
        
    }

    importScheduledList = (csvData) => {
        apiTask.deleteAllTasks().then(() => {
            const tasks = [];
            csvData.forEach((data, index) => {
                const task = {};
                if (index > 0) {
                    task.task = data[0].trim();
                    task.start_date = moment(data[1], 'YYYY-MM-DD HH:mm:ss').valueOf();
                    task.end_date = moment(data[2], 'YYYY-MM-DD HH:mm:ss').valueOf();
                    task.is_task_completed = data[3];
                    tasks.push(task);
                }
            })
            this.createTask(tasks);
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="main__container" style={{ display: 'flex', flexDirection: 'column' }}>
                <Flex fontSize="1rem" mt="8rem" flexDirection="column">
                    <StyledButton onClick={this.handleExportUser}>
                        Export Pending Users
                    </StyledButton>
                    <Flex alignItems="center" mb="2rem">
                        <StyledDiv>
                            Import Approved Users
                        </StyledDiv>
                        <input
                            accept=".csv"
                            id="import-user-button"
                            type="file"
                            onChange={(event) => {
                                let newCsvFile = event.currentTarget.files[0];
                                if (newCsvFile) {
                                    Papa.parse(newCsvFile, {
                                        complete: result => {
                                            this.handleImportUsers(result.data)
                                        }
                                    })
                                };
                            }}
                        />
                    </Flex>
                    <Flex alignItems="center">
                        <StyledDiv>
                            Import Fund Price
                        </StyledDiv>
                        <input
                            accept=".csv"
                            id="import-fund-button"
                            type="file"
                            onChange={(event) => {
                                let newCsvFile = event.currentTarget.files[0];
                                if (newCsvFile) {
                                    Papa.parse(newCsvFile, {
                                        complete: result => {
                                            this.handleImportFundPrice(result.data)
                                        }
                                    })
                                };
                            }}
                        />
                    </Flex>
                    <StyledButton onClick={this.downloadFundPriceSample} style={{ marginTop: '2rem' }}>
                        Download Fund Price Sample
                    </StyledButton>
                    <StyledButton onClick={this.updateFundPrice}>
                        Update Fund Price
                    </StyledButton>
                    <StyledButton onClick={this.exportScheduledList}>
                        Export Scheduled Task List
                    </StyledButton>
                     <Flex alignItems="center">
                        <StyledDiv>
                            Import Scheduled Task List
                        </StyledDiv>
                        <input
                            accept=".csv"
                            id="import-task-button"
                            type="file"
                            onChange={(event) => {
                                let newCsvFile = event.currentTarget.files[0];
                                if (newCsvFile) {
                                    Papa.parse(newCsvFile, {
                                        complete: result => {
                                            this.importScheduledList(result.data)
                                        }
                                    })
                                };
                            }}
                        />
                    </Flex>
                </Flex>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Admin));