// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Styling
import Grid from '@material-ui/core/Grid';
import { Button, Tab, Tabs } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

// Api
import { apiAuth } from '../../Api/ApiAuth';
// import { apiMenus } from '../../Api/ApiMenus';

// Redux
import { connect } from 'react-redux';
import { login, register, getUserInfo } from '../../Redux/Action/authAction';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getErrorMessage } from '../../utils/CommonUtils';

// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import GeneralMessage from '../../components/100Include/GeneralMessage';

class LoginRegister extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 1,
            MessageContent: ''
        }
    }

    componentDidMount = () => {
        if (this.props.tabIndex === 1) {
            this.setState({
                tabIndex: 1
            })
        } else if (this.props.tabIndex === 2) {
            this.setState({
                tabIndex: 2
            })
        }
    }

    _handleTabChange = (e) => {
        this.setState({
            tabIndex: e
        });
    }

    // register
    _registerAsync = (values) => {
        if (typeof (values) !== 'undefined') {
            apiAuth.getClientCredentials().then((res) => {
                this._register(values, res.access_token);
            })
        }
    }

    _register = (values, access_token) => {

        const cb = (obj) => {
            console.log("cb : ", obj);

            switch (obj.status) {

                case 500:
                    this.setState({
                        MessageContent: getErrorMessage(obj.body)
                    });
                    break;

                case 201:
                    this.setState({
                        MessageContent: 'Register Success, please login'
                    });

                    setTimeout(() => {
                        this.setState({
                            tabIndex: 1
                        });
                        document.getElementById("registerForm").reset();
                    }, 3000);
                    break;

                default: console.log('what');
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const body = {
            username: values.email,
            password: values.password,
            email: values.email,
            display_name: values.name,
            role: 2,
        }

        apiAuth.register(body, access_token, cb, eCb);
    }

    // login
    _signInAsync = (values) => {

        if (typeof (values) !== 'undefined') {

            let submitEmail = values.email;
            let submitPassword = values.password;

            apiAuth.authenticate(submitEmail, submitPassword).then((res) => {

                let data = {
                    token: res.access_token,
                    refreshToken: res.refresh_token,
                }
                this.props.loginP(data);
                this._getUserInformation(res.access_token);
                // this._getAllMenus(res.access_token);
                setTimeout(() => { this.props.close() }, 500);
            }).catch((e) => {
                this.setState({
                    MessageContent: getErrorMessage(e.body || e)
                });
            });
        }
    }

    // getUserInformation after login
    _getUserInformation = (access_token) => {

        const cb = (obj) => {
            console.log("cb : ", obj);
            this.props.getUserInfoP(obj.body);
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;

        apiAuth.getUserInformation(params, access_token, cb, eCb);
    }

    // form register
    formRegister = ({ values, errors, touched, handleChange }) => {
        const { t,
            i18n
        } = this.props;

        return (
            <Form id="registerForm" className="form-wrapper">

                <Grid container>
                    {this.state.MessageContent &&
                        <Grid item xs={12} className="SuccessMessage">
                            <GeneralMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} className="grid">
                        <Field name="full_name">
                            {({
                                field, // { name, value, onChange, onBlur }
                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                            }) => (
                                <TextField
                                    name={field.name}
                                    type="text"
                                    label="Legal Full Name"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="full_name" />
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="display_name">
                            {({
                                field, // { name, value, onChange, onBlur }
                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                            }) => (
                                <TextField
                                    name={field.name}
                                    type="text"
                                    label="Nickname"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="display_name" />
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="phone">
                            {({
                                field, // { name, value, onChange, onBlur }
                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                            }) => (
                                <TextField
                                    name={field.name}
                                    type="text"
                                    label="Mobile No."
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="phone" />
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="email">
                            {({
                                field, // { name, value, onChange, onBlur }
                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                            }) => (
                                <TextField
                                    name={field.name}
                                    type="email"
                                    label="Email"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="email" />
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="manulife_policy_number">
                            {({
                                field, // { name, value, onChange, onBlur }
                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                            }) => (
                                <TextField
                                    name={field.name}
                                    type="text"
                                    label="Manulife Policy Number"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="manulife_policy_number" />
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="referral_code">
                            {({
                                field, // { name, value, onChange, onBlur }
                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                meta,
                            }) => (
                                <TextField
                                    name={field.name}
                                    type="text"
                                    label="Referral Code"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="referral_code" />
                    </Grid>
                    <Grid item xs={12} className="grid" style={{
                        height: '10rem',
                        overflowY: 'auto',
                        overflowWrap: 'break-word',
                    }}>
                        <h6>Terms and Condition</h6>
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                        Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition Terms and Condition
                    </Grid>

                    <Grid item xs={12} className="grid">
                        <Button type="submit">Register</Button>
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Link to={"/" + i18n.language + '/disclaimer'} className="disclaimer" target="_blank" rel="noopener noreferrer"
                            onClick={() => this.props.handleClose()}
                        >{t("Common:Disclaimer.Disclaimer")}</Link>
                        {/* {<div className="DisclaimerWrapper">
                            <h4>{t("Common:Disclaimer.Disclaimer")}</h4>
                            <h5 align = "left">{t("Common:Disclaimer.listItem1")}</h5>
                            <h5 align = "left">{t("Common:Disclaimer.listItem2")}</h5>
                </div>} */}
                    </Grid>
                </Grid>
            </Form >
        )
    }

    // form login
    formLogin = ({ values, errors, touched, handleChange }) => {
        const { t, i18n } = this.props;

        return (
            <Form id="loginForm" className="form-wrapper">
                <Grid container>
                    {this.state.MessageContent &&
                        <Grid item xs={12} className="ErrorMessage">
                            <ErrorMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} className="grid">
                        <Field name="email" type="email" placeholder={t("LoginRegister:placeholder.Email")} maxLength="100" style={{ 'width': '100%' }} />
                        {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="password" type="password" placeholder={t("LoginRegister:placeholder.Password")} maxLength="100" style={{ 'width': '100%' }} />
                        {errors.password && touched.password ? <ErrorMessage message={errors.password} /> : null}
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Button type="submit" onClick={() => { this._signInAsync() }}>{t("LoginRegister:login.Login")}</Button>
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Link to={"/" + i18n.language + '/forget-password'}
                            onClick={() => this.props.handleClose()}
                        >{t("LoginRegister:login.Forgot")}</Link>
                        <div className="sep-10"></div>
                        <Link to={"/" + i18n.language + '/disclaimer'} className="disclaimer" target="_blank" rel="noopener noreferrer"
                            onClick={() => this.props.handleClose()}
                        >{t("Common:Disclaimer.Disclaimer")}</Link>
                    </Grid>
                </Grid>
                {/* <div className="DisclaimerWrapper">
                    <h4>{t("Common:Disclaimer.Disclaimer")}</h4>
                    <h5 align = "left">{t("Common:Disclaimer.listItem3")}</h5>
                </div> */}
            </Form>
        )
    }

    render() {

        const { t } = this.props;

        const Schema = Yup.object().shape({
            email: Yup.string()
                .email(t("LoginRegister:login.Checking1"))
                .required(t("LoginRegister:login.Checking2")),
            password: Yup.string()
                .typeError(t("LoginRegister:login.Checking3"))
                .required(t("LoginRegister:login.Checking4")),
        })

        const Schema1 = Yup.object().shape({
            email: Yup.string()
                .email(t("LoginRegister:register.Checking1"))
                .required(t("LoginRegister:register.Checking2")),
            name: Yup.string()
                .required(t("LoginRegister:register.Checking3")),
            password: Yup.string()
                .typeError(t("LoginRegister:register.Checking4"))
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, t("LoginRegister:register.Checking9"))
                .required(t("LoginRegister:register.Checking5")),
            confirmPassword: Yup.string()
                .typeError(t("LoginRegister:register.Checking6"))
                .oneOf([Yup.ref('password'), null], t("LoginRegister:register.Checking6"))
                .required(t("LoginRegister:register.Checking8")),
        })

        return (
            <div>
                <Grid item xm={12} md={6} xl={4} className="grid">
                    <Formik
                        initialValues={{
                            //email: 'admin@joyaether.test',
                            //password: '123456qwerty',
                            email: '',
                            password: '',
                        }}
                        validationSchema={Schema}
                        onSubmit={this._registerAsync}
                        component={this.formRegister}
                    />
                </Grid>
                <div className="loginRegister">
                    <Tabs defaultActiveKey={1} activeKey={this.state.tabIndex} id="uncontrolled-tab-example" onSelect={(e) => this._handleTabChange(e)}>
                        <Tab eventKey={1} title={t("LoginRegister:login.Title")}>

                        </Tab>
                        <Tab eventKey={2} title={t("LoginRegister:register.Title")}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    name: '',
                                    password: '',
                                    confirmPassword: ''
                                }}
                                validationSchema={Schema1}
                                onSubmit={this._registerAsync}
                                component={this.formRegister}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
    registerP: data => dispatch(register(data))
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginRegister));
