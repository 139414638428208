import {api} from "./_ApiFactoryWithHeader";

export const apiClient = {
    getClients: (params) => {
        return api.get('clients', params, null)
    },
    getClientDetail: (id, params) => {
        return api.get(`clients/${id}`, params, null)
    },
    addClient: (body) => {
        return api.post(`clients`, body)
    },
    updateClient: (id, body) => {
        return api.put(`clients/${id}`, body)
    },
    updateClientWithPost: (id, body) => {
        return api.post(`clients/${id}`, body)
    },
    deleteClient: (id, params) => {
        return api.delete(`clients/${id}`, params);
    },
    getCreditHistories: (params) => {
        return api.get(`credit-histories`, params, null)
    },
    addCredit: (body) => {
        return api.post(`credits`, body)
    },
    exportCredit: (clientId, params) => {
        return api.getXlsxFile(`credits-export/${clientId}`, params, null)
    }

};
