import {api} from './_ApiFactoryWithHeader';

export const apiUserAccount = {
    getUserAccount: (username, versionNumber) => {
        const param = {
            user: username,
            version: versionNumber
        }
        return api.get(`user_accounts`, param, null, null)
    },

    updateUserAccountTotalBalance: (param) => api.put(`user_accounts`, param, null, null),

    getAllUserAccount: (param) => api.get(`user_accounts`, param, null, null),

    getInitialContribution: () => {
        return api.get(`internal_configurations/5`, null, null, null);
    }
};
