// Essential for all components
import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

// Api
import {logout} from "../../Redux/Action/authAction";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close';

import {clearLoginData} from "../../utils/AuthService";
import AppBar from "@material-ui/core/AppBar";
import { Divider, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import homeIcon from '../../images/Landingpg/Icon for menu/Home.png';
import manageFundsIcon from '../../images/Landingpg/Icon for menu/Manage Fund.png';
import miniGameIcon from '../../images/Landingpg/Icon for menu/Mini Game.png';
import fundIcon from '../../images/Landingpg/Icon for menu/Fund Price.png';
import newsEducationIcon from '../../images/Landingpg/Icon for menu/News and education.png';
import rankingIcon from '../../images/Landingpg/Icon for menu/Ranking.png';
import badgeIcon from '../../images/Landingpg/Icon for menu/My Badges.png';
import rewardIcon from '../../images/Landingpg/Icon for menu/My Reward.png';
import tradeHistoryIcon from '../../images/Landingpg/Icon for menu/Transaction Log.png';
import howToPlayIcon from '../../images/Landingpg/Icon for menu/How to play.png';
import prizeIcon from '../../images/Landingpg/Icon for menu/Prize.png';
import referFriendIcon from '../../images/Landingpg/Icon for menu/Referral.png';
import editProfileIcon from '../../images/Landingpg/Icon for menu/Edit Contact Info.png';
import supportIcon from '../../images/Landingpg/Icon for menu/Contact.png';
import languageIcon from '../../images/Landingpg/Icon for menu/Language.png';
import logoutIcon from '../../images/Landingpg/Icon for menu/Sign out.png';
import { apiTask } from '../../Api/_ApiTask';
import { get } from 'lodash-es';
import { handleReferral } from '../../utils/Referral';
import '../../css/00Home/_landingHeader.scss';

const drawerWidth = 260;
const styles = theme => ({
    appBar: {
        // marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        // backgroundImage: 'linear-gradient(to right, white , white)',
        // backgroundColor: '#33A35B',
        height: 70,
        zIndex: 40,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between',
        // fontFamily: 'jf-openhuninn !important',
        backgroundColor: '#33A35B',
        // backgroundImage: "url('../../images/Landingpg/Registration-period/Background 1.png')"
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    typography: {
        flexGrow: 1,
        align: "center"
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    list: {
        width: '100%',
        height: '100vh'
    },
    listItemText:{
        fontSize:'1rem',
    },
    button: {
        backgroundColor: '#ec6453',
        padding: window.innerWidth > 350 ? 'auto' : 0,
        color: 'white',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'white',
        "&:hover": {
            backgroundColor: "#ec6453"
        }
    },
});

const imagePaths = {
    'en-US': {
        iconMPF: require('../../images/Landingpg/Registration-period/logo english.png'),
    },

    'zh-HK': {
        iconMPF: require('../../images/Landingpg/Registration-period/MPFgameLogo.png'),
    }

};

const listIcon = {
    'home': homeIcon,
    'funds': manageFundsIcon,
    'games': miniGameIcon,
    'fund': fundIcon,
    'news': newsEducationIcon,
    'ranking': rankingIcon,
    'badges': badgeIcon,
    'rewards': rewardIcon,
    'history': tradeHistoryIcon,
    'play': howToPlayIcon,
    'prizes': prizeIcon,
    'refer': referFriendIcon,
    'profile': editProfileIcon,
    'support': supportIcon,
    'language': languageIcon,
    'logout': logoutIcon,
}

const menuList = [
    {
        title: 'home',
        icon: 'home',
        page: 'index',
        displayOnEndGame: true,
    },
    {
        title: 'manageFunds',
        icon: 'funds',
        page: 'manage-funds/0',
        displayOnEndGame: false,
    },
    {
        title: 'miniGames',
        icon: 'games',
        page: 'mini-game',
        displayOnEndGame: false,
    },
    {
        title: 'fundPrice',
        icon: 'fund',
        page: 'fund-price',
        displayOnEndGame: false,
    },
    {
        title: 'myRanking',
        icon: 'ranking',
        page: 'ranking',
        displayOnEndGame: true,
    },
    {
        title: 'myBadges',
        icon: 'badges',
        page: 'badge',
        displayOnEndGame: false,
    },
    {
        title: 'myRewards',
        icon: 'rewards',
        page: 'reward/1',
        displayOnEndGame: false,
    },
    {
        title: 'tradeHistory',
        icon: 'history',
        page: 'trade-history/1',
        displayOnEndGame: true,
    },
    {
        title: 'howToPlay',
        icon: 'play',
        page: 'how-to-play',
        displayOnEndGame: false,
    },
    {
        title: 'prizes',
        icon: 'prizes',
        page: 'prize',
        displayOnEndGame: false,
    },
    // {
    //     title: 'referToFriends',
    //     icon: 'refer',
    //     func: 'refer-friends',
    // },
    {
        title: 'editProfile',
        icon: 'profile',
        page: 'edit-profile',
        displayOnEndGame: false,
    },
    {
        title: 'supportEmailForEnquiries',
        icon: 'support',
        page: 'support',
        displayOnEndGame: true,
    },
    {
        title: 'language',
        icon: 'language',
        func: 'change-language',
        displayOnEndGame: true,
    },
    {
        title: 'logout',
        icon: 'logout',
        func: 'logout',
        displayOnEndGame: true,
    },
]

class IndexHeader extends Component {
    state = {
        anchorEl: null,
        openMenu: false
    };

    handleToggle = () => {
        this.setState(state => ({ openMenu: !state.openMenu }));
    };

    handleClose = (event) => {
        this.setState({ openMenu: false });
    };

    goToProfile = () => {
        const { i18n } = this.props;
        this.props.history.push(`/${i18n.language}/edit-profile`);
        this.setState({ openMenu: false });
    };

    logOut = () => {
        this.setState({ openMenu: false, anchorEl: null });
        clearLoginData().then(() => {
            this.props.history.push('/before-login');
        });
        this.props.logoutP();
    };

    handleClick = (item) => {
        const { i18n, history, auth, t } = this.props;
        const { page, func } = item;
        if (page) {
            if (page.includes('manage-fund')) {
                const param = {
                    permission_name: 'switch fund'
                }
                apiTask.getTaskPermission(param).then(obj => {
                    if (obj.data[0].is_permitted) {
                        history.push('/' + i18n.language + `/${page}`)
                    } else {
                        alert(t("ManageFunds:switchFundDisallowMessage"));
                    }
                })
            } else {
                history.push(`/${i18n.language}/${page}`);
            }
        } else {
            if (func === 'logout') {
                this.logOut();
            } else if (func === 'change-language') {
                this.handleChangeLanguage();
            } else if (func === 'refer-friends') {
                const user = get(auth, 'userInfo');
                const policyNumber = get(user, 'policy_number');
                handleReferral(policyNumber, i18n.language);
            }
            this.handleToggle();
        }
    };

    handleChangeLanguage = () => {
        const { i18n, history } = this.props;
        const { pathname, state, search } = get(history, 'location');
        const newPath = pathname.split(i18n.language === 'zh-HK' ? 'zh-HK/' : 'en-US/')[1];
        if (newPath) {
            i18n.changeLanguage(i18n.language === 'zh-HK' ? 'en-US' : 'zh-HK').then(() => {
                this.handleClose();
                this.props.history.push(`/${i18n.language}/${newPath}${search ? search : ''}`, state);
                window.location.reload();
            });
        }
    };

    handleClickTitle = () => {
        const { history, i18n, auth } = this.props;
        const page = auth.auth ? 'index' : 'before-login';
        this.setState({ openMenu: false });
        history.push('/' + i18n.language + `/${page}`);
    }

    list = (t) => {
        const { classes, i18n, isGameEnd } = this.props;
        return (
            <div>
                <Toolbar style={{ width: '100%', backgroundColor: '#33A35B', justifyContent: 'space-between', height: '70px'}}>
                    {/*<Typography component={'span'} style={{ color: 'white' }}>*/}
                    {/*    <div onClick={this.handleClickTitle} style={{cursor: 'pointer'}}>*/}
                    {/*        <div>{t("Common:General.mpfFunGame")}</div>*/}
                    {/*        <div style={{fontSize: '0.8rem'}}>{t("Common:General.empoweredByThinkAHeadLimited")}</div>*/}
                    {/*    </div>*/}
                    {/*</Typography>*/}
                    <Typography>
                        <IconButton>
                            <MenuIcon className='menu-item' />
                        </IconButton>
                    </Typography>
                    <Typography style={{ flexGrow: 0 }}>
                        <IconButton onClick={this.goToProfile}>
                            <PersonIcon className='menu-item' />
                        </IconButton>
                        <IconButton onClick={this.handleToggle}>
                            <CloseIcon className='menu-item' />
                        </IconButton>
                    </Typography>
                </Toolbar>
                <div className={classes.list} role="presentation" onClick={this.handleToggle} onKeyDown={this.handleToggle}>
                    <List>
                        {menuList.map((menuItem, index) => {
                            const Label = () => (menuItem.title === 'language' ?
                                <div>
                                    <span style={{ textDecoration: i18n.language === 'en-US' ? 'underline' : 'none' }}>English</span>&nbsp;/&nbsp;<span style={{ textDecoration: i18n.language === 'zh-HK' ? 'underline' : 'none' }}>中文</span>
                                </div> :
                                t(`Common:MenuItem.${menuItem.title}`));
                            if (!menuItem.displayOnEndGame && isGameEnd) {
                                return;
                            }
                            return (
                                <div key={index} onClick={() => this.handleClick(menuItem)}>
                                    <ListItem button>
                                        <img src={listIcon[menuItem.icon]} alt="item-icon" width={25} height={25} style={{ marginLeft: '1.5rem', marginRight: '1rem' }} />
                                        <ListItemText
                                            className={`${i18n.language === 'en-US' ? 'fontENG' : ''}`}
                                            classes={{ primary: classes.listItemText }}
                                            primary={<Label />}
                                        />
                                    </ListItem>
                                    <Divider />
                                </div>
                            );
                        })}
                    </List>
                </div>
            </div>
        );
    };

    render() {
        const { classes, t, i18n, auth } = this.props;
        const { openMenu } = this.state;

        return (
            <AppBar style={{position: 'absolute'}} className={classes.appBar} elevation={0}>
                <Toolbar style={{ width: '100%', justifyContent: 'space-between' }}>
                    {/*<Typography component={'span'}>*/}
                    {/*    <div onClick={this.handleClickTitle} style={{cursor: 'pointer'}}>*/}
                    {/*        <div>{t("Common:General.mpfFunGame")}</div>*/}
                    {/*        <div style={{fontSize: '0.8rem'}}>{t("Common:General.empoweredByThinkAHeadLimited")}</div>*/}
                    {/*    </div>*/}
                    {/*</Typography>*/}
                    <Typography style={{ display: 'flex', flexDirection: 'row' }}>
                        {/*{auth.auth ? <IconButton onClick={this.goToProfile}>*/}
                        {/*        <PersonIcon style={{ color: 'white' }} />*/}
                        {/*    </IconButton> :*/}
                        {/*    <IconButton onClick={this.handleChangeLanguage} style={{ padding: window.innerWidth > 350 ? 10 : 5 }}>*/}
                        {/*        <LanguageIcon style={{ color: 'white' }} />*/}
                        {/*    </IconButton>*/}
                        {/*}*/}

                        {auth.auth && <IconButton style={{ backgroundColor: 'transparent' }} onClick={this.handleToggle}>
                                <MenuIcon className='menu-item' />
                            </IconButton> 
                            // : <Button
                            //     style={{ fontSize: i18n.language === 'zh-HK' ? 14 : 12 }}
                            //     variant="contained"
                            //     classes={{root:classes.button}}
                            //     onClick={() => {
                            //         this.props.history.push(`/${i18n.language}/login`);
                            //     }}
                            // >
                            //     {t("LoginRegister:login:Title")}
                            // </Button>
                        }
                        <Drawer anchor="top" open={openMenu} onClose={this.handleToggle}>
                            {this.list(t)}
                        </Drawer>
                    </Typography>
                    {/* <Typography component={'span'}>
                        <div id='introduction' className="iconMPF-index" >
                            <img style={{ width: '15vw', maxWidth: 85, height: 'auto', marginRight: '6vw' }} src={imagePaths[i18n.language].iconMPF} alt='Icon MPF' />
                        </div>
                    </Typography> */}
                    <Typography component={'span'}>
                        <div onClick={this.handleClickTitle} style={{cursor: 'pointer'}}>
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }
}

IndexHeader.propTypes = {
    siteType: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles, { withTheme: true })(IndexHeader))));
