import { Button, Dialog, IconButton, makeStyles } from "@material-ui/core";
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Flex } from "reflexbox";

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            paper: {
                maxWidth: '100vw',
                maxHeight: '70vh',
                width: '100vw',
                margin: 0,
                padding: '1rem',
                position: 'absolute',
                bottom: 0,
                color: "#5e5e5e",
                fontWeight: "300"
            },
            closeButton: {
                width: 25,
                height: 25,
                position: 'absolute',
                right: 25
            }
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

const BottomDialog = (props) => {
    const { classes, open, handleCloseDialog, title, descriptions, t } = props;

    return (
        <Dialog classes={{ paper: classes.paper }} open={open}>
            <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
                <CloseIcon />
            </IconButton>
            <Flex marginY="1rem" fontSize="1.5rem">
                {title}
            </Flex>
            <Flex fontSize="1rem" flexDirection="column">
                {descriptions.map((description, index) => (
                    <p key={index} style={{ margin: '0.5rem 0' }}>
                        {description}
                    </p>
                ))}
            </Flex>
            <Flex flex={1} className="button-wrapper" mt="2rem">
                <Button onClick={handleCloseDialog} className="primary-button m0 p1rem" style={{ background: '#ec6453', color: 'white', border: 'none' }}>{t("Common:Button.close")}</Button>
            </Flex>
        </Dialog>
    )
}

export default withStyles(BottomDialog);