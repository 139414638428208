import {api} from './_ApiFactoryWithHeader';
import { apiGeneral } from './_General';

export const apiTask = {
    getAllTasks: (param) => api.get(`scheduled_tasks`, param, null, null),

    deleteAllTasks: (param) => api.delete(`scheduled_tasks`, param, null, null),

    createTask: (param) => api.post(`scheduled_tasks`, param, null, null),

    getTaskPermission: (param) => api.get(`task_permissions`, param, null, null),

    getTaskPermissions: (params, token, cb, eCb, accept, contentType) => apiGeneral.apiFetchCustom(`task_permissions`, params, token, cb, eCb, accept, contentType),
};
