import { AgGridReact } from '@ag-grid-community/react';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { get } from 'lodash-es';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { apiFunds } from '../../Api/_ApiFunds';
import { apiUserAccount } from '../../Api/_ApiUserAccount';
import upArrowIcon from "../../images/blue-arrow.png";
import downArrowIcon from "../../images/red-arrow.png";
import rank1Icon from "../../images/rank1-icon.png";
import rank2Icon from "../../images/rank2-icon.png";
import rank3Icon from "../../images/rank3-icon.png";
import TitleBgCHI from "../../images/Landingpg/Ranking/TitleBG.png";
import TitleBgENG from "../../images/Landingpg/Ranking/TitleBG ENG.png";
import upWard from "../../images/Landingpg/Ranking/Ranking arrow upward.png";
import crown from "../../images/Landingpg/Ranking/crown.png";
import downWard from "../../images/Landingpg/Ranking/Ranking arrow downward.png";
import { apiTask } from '../../Api/_ApiTask';
import Highlight from '../../images/Landingpg/Ranking/Highlight.png';
import '../../css/04Ranking/_ranking.scss';

const CustomTabs = withStyles({
    root: {
        marginTop: '2rem',
        color: 'white',
        width: '100%',
        // borderBottom: '2px solid #0000c1',
        "& .MuiTabs-flexContainer": {
            width: '15%'
        },
        "& .MuiTab-textColorInherit.Mui-disabled": {
            opacity: 0.2
        },
        backgroundImage: `url(${Highlight})`, // Apply the background image for the active tab
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'local',
    },
    indicator: {
        backgroundColor: '#0000c1',
    },

    // indicator: {
    //     backgroundColor: 'transparent',
    // },

    // indicator: (props) => ({
    //     display: 'none', // Hide the default indicator to use the custom background image for active tab
    //     // Define the custom indicator styles based on the selected tab
    //     "& > span": {
    //         backgroundImage: props.tabValue === 0 ? 'none' : `url(${Highlight})`,
    //         backgroundSize: '100% auto',
    //         backgroundRepeat: 'no-repeat',
    //         backgroundAttachment: 'local',
    //         height: 4,
    //         width: `${100 / props.tabs.length}%`, // Divide the width evenly among the tabs
    //         // Calculate the left position of the indicator based on the selected tab
    //         transform: `translateX(${(100 / props.tabs.length) * props.tabValue}%)`,
    //     },
    // }),
})((props) => <Tab {...props} />);

const CustomTab = withStyles({
    root: {
        fontSize: '1rem',
        width: '100%',
    },
})((props) => <Tab {...props} />);

const gridOptions = {
    rowHeight: 45,
    rowStyle: {
        // borderBottom: 'white 3px solid',
        // borderTop: 'white 3px solid'
    }
};

const getColumnDefs = (t) => {
    const columnDefs = [
        {
            field: 'rank',
            flex: 1,
            cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                // if (params.data.updateUserFund) {
                //     return '--';
                // }
                const rank = params.value;
                // if (rank <= 3) {
                //     let icon;
                //     if (rank === 1) {
                //         icon = rank1Icon;
                //     } else if (rank === 2) {
                //         icon = rank2Icon;
                //     } else if (rank === 3) {
                //         icon = rank3Icon;
                //     }
                //     return <Fragment>
                //         <Flex height="100%" alignItems="center" justifyContent="center" ml={-25}>
                //             <img src={icon} style={{width: 20, marginRight: 5 }} alt=""/> {Math.abs(params.value)}
                //         </Flex>
                //     </Fragment>
                // }
                return <Fragment>
                    <Flex height="100%" alignItems="center" justifyContent="flex-start" fontSize="1.5rem" paddingLeft="1.5rem">
                         {Math.abs(params.value)}
                    </Flex>
                </Fragment>;
            },
            headerValueGetter: (params) => {
                return  t(`Ranking:${params.colDef.field.toLowerCase()}`);
            }
        },
        // {
        //     field: 'trend',
        //     flex: 1,
        //     cellStyle: { textAlign: 'center' },
        //     cellRenderer: function (params) {
        //         if (!params.value || params.value === '0' || params.data.isRegistrationPeriod) {
        //             return <Fragment>
        //                 <Flex height="100%" alignItems="center" justifyContent="center">
        //                     --
        //                 </Flex>
        //             </Fragment>;
        //         }
        //         const icon = params.value < 0 ? downWard : upWard ;
        //         return <Fragment>
        //             <Flex height="100%" alignItems="center" justifyContent="center">
        //                 {Math.abs(params.value)} <img src={icon} style={{    width: '0.6rem', margin: '0 0.3rem 0.1rem' }} alt=""/>
        //             </Flex>
        //         </Fragment>;
        //     },
        //     headerValueGetter: (params) => {
        //         return t(`Ranking:${params.colDef.field.toLowerCase()}`);
        //     }
        // },
        {
            field: 'nickname',
            flex: 1,
            cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.isRegistrationPeriod) {
                    return '--';
                }
                return <Fragment>
                    <Flex height="100%" alignItems="center" justifyContent="center">
                        {(params.value)}
                    </Flex>
                </Fragment>;
            },
            headerValueGetter: (params) => {
                return t(`Ranking:${params.colDef.field.toLowerCase()}`);
            }
        },
        {
            field: 'balance',
            flex: 1,
            cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return <Fragment>
                    <Flex height="100%" alignItems="center" justifyContent="flex-end" paddingRight="1.5rem">
                        {params.value.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Flex>
                </Fragment>;
            },
            headerValueGetter: (params) => {
                return t(`Ranking:${params.colDef.field.toLowerCase()}`);
            }
        }
    ]
    return columnDefs;
}

const getRowStyle = (params) => {
    // if (params.data.rank === 1) {
    //     if (params.data.isMe) {
    //         return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', background: '#fef276', borderRadius: 20 };
    //     }
    //     return { background: '#fef276', borderRadius: 20 };
    // }
    // if (params.data.rank === 2) {
    //     if (params.data.isMe) {
    //         return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', background: '#cfdbe3', borderRadius: 20 };
    //     }
    //     return { background: '#cfdbe3', borderRadius: 20 };
    // }
    // if (params.data.rank === 3) {
    //     if (params.data.isMe) {
    //         return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', background: '#fdc97c', borderRadius: 20 };
    //     }
    //     return { background: '#fdc97c', borderRadius: 20 };
    // }
    // if (params.data.isMe) {
    //     return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', borderRadius: 20 };
    // }
    // return { color: 'white', borderBottom: '2px 0', borderStyle: 'solid', borderColor: '#85888F', backgroundColor: '#151D29' };
    if (params.data.rank === 1) {
        return { color: 'white', border: '1px', borderStyle: 'solid', borderColor: '#85888F', backgroundColor: '#151D29', borderWidth: '3px 0 3px 0' };
    }
    return { color: 'white', border: '1px', borderStyle: 'solid', borderColor: '#85888F', backgroundColor: '#151D29', borderWidth: '0 0 3px 0' };
}

class RankingTable extends Component {

    state = {
        myRank: '',
        myTrend: '',
        myBalance: '',
        myIncrement: '',
        rowData: [],
        tabValue: 0,
        updateUserFund: null,
        firstMonthRanking: false,
        secondMonthRanking: false,
        thirdMonthRanking: false,
        topThreeUsers: [],
        asOfDate: null
    }

    getRowStyle = (params) => {
        if (params.data.rank === 1) {
            if (params.data.isMe) {
                return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', background: '#fef276', borderRadius: 20 };
            }
            return { background: '#fef276', borderRadius: 20 };
        }
        if (params.data.rank === 2) {
            if (params.data.isMe) {
                return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', background: '#cfdbe3', borderRadius: 20 };
            }
            return { background: '#cfdbe3', borderRadius: 20 };
        }
        if (params.data.rank === 3) {
            if (params.data.isMe) {
                return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', background: '#fdc97c', borderRadius: 20 };
            }
            return { background: '#fdc97c', borderRadius: 20 };
        }
        if (params.data.isMe) {
            return { border: 2, borderStyle: 'solid', borderColor: '#0000c1', borderRadius: 20 };
        }
        return undefined;
    }

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    dynamicSort(property, sequence) {
        let index = 1;
        if (sequence === 'asc') {
            index = index * -1;
        }
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            let result = (a[property] < b[property]) ? index : (a[property] > b[property]) ? -index : 0;
            return result * sortOrder;
        }
    }

    // this.setState({ funds: allFunds.sort(this.dynamicSort("sequence")) });

    calculateRank = (rowData, initialContribution) => {
        const { updateUserFund } = this.state;
        const { updateUserOverallRanking } = this.props;
        const calculatedData = rowData.map(data => {
            const contributions = [data.contribution1, data.contribution2];
            let contributionSum = 0;
            contributions.forEach(contribution => {
                if (contribution) {
                    contributionSum += contribution;
                }
            })
            const overallReturn = data.balance - initialContribution - contributionSum;
            const dataWithOverallReturn = { ...data, overallReturn: overallReturn };
            return dataWithOverallReturn;
        })
        const rankedData = calculatedData.sort(this.dynamicSort('overallReturn')).map((data, index) => {
            return { ...data, rank: index + 1 };
        });
        const currentUser = rankedData.find(user => get(user, 'isMe'));
        if (currentUser) {
            if (updateUserOverallRanking) {
                updateUserOverallRanking(currentUser.rank, rankedData.length);
            }
        }
        return rankedData;
    }

    calculateUserTrend = (prevRankedUser, rankedUser) => {
        let uniqueUserData;
        let userIndex;

        const finalisedRankedUser = rankedUser.map(user => {
            const finalizedData = { ...user };
            let increment, trend;
            prevRankedUser.forEach(prevUser => {
                if (prevUser.nickname === user.nickname) {
                    trend = prevUser.rank - user.rank; 
                    finalizedData.trend = trend;
                    if (user.isMe) {
                        const prevBalance = prevUser.balance;
                        increment = (user.balance - prevBalance) / prevBalance * 100;                        
                    }
                }
            })
            if (user.isMe) {
                this.setState({ myRank: user.rank, myTrend: trend, myIncrement: increment });
                userIndex = user.rank;
                uniqueUserData = finalizedData;
            }
            return finalizedData;
        })
        const tenRankedUser = [];
        finalisedRankedUser.forEach((data, index) => {
            if (index < 50) {
                tenRankedUser.push(data)
            }
        })
        if (userIndex > 50) {
            tenRankedUser.push(uniqueUserData)
        }
        return tenRankedUser;
    }

    getInitialContribution = () => {
        return apiUserAccount.getInitialContribution().then(obj => obj.data.int_value);
    }

    getOverallRanking = () => {
        const { updateUserFund } = this.state;
        Promise.resolve(this.getInitialContribution()).then(initialContribution => {
            apiFunds.getFundVersion().then(obj => {
                const version = obj.data.int_value;

                // const previousParam = {
                //     version: version - 1,
                //     '$expand': 'user',
                //     // '$orderBy': 'total_fund_balance desc'
                // }
                // apiUserAccount.getAllUserAccount(previousParam).then(prevRes => {
                //     if (prevRes && prevRes.status === 200) {
                //         const prevUsers = prevRes.data.map((user, index) => {
                //             const prevUserData = {
                //                 id: index + 1,
                //                 contribution1: user.contribution_1,
                //                 contribution2: user.contribution_2,
                //                 nickname: user.user.nickname,
                //                 balance: user.total_fund_balance,
                //             }
                //             return prevUserData;
                //         });
                //         const prevRankedUser = this.calculateRank(prevUsers, initialContribution);
            
                        const currentParam = {
                            version: version,
                            '$expand': 'user',
                            // '$orderBy': 'total_fund_balance desc'
                        }
                        apiUserAccount.getAllUserAccount(currentParam).then(res => {
                            const rowData = [];
                            res.data.forEach((user, index) => {
                                const userData = {
                                    id: index + 1,
                                    contribution1: user.contribution_1,
                                    contribution2: user.contribution_2,
                                    nickname: user.user.nickname,
                                    balance: user.total_fund_balance,
                                    isMe: user.user.username === this.props.auth.userInfo.username,
                                    version: user.version,
                                    // updateUserFund: updateUserFund,
                                    userCreatedDate: user.user.createddate
                                }
                                if (userData.isMe) {
                                    this.setState({ myBalance: userData.balance });
                                }
                                rowData.push(userData);

                            })
                            rowData.sort(this.dynamicSort("userCreatedDate", "asc"));
                            const rankedUser = this.calculateRank(rowData, initialContribution);
                            const finalizedRankedUser = this.calculateUserTrend([], rankedUser);
                            this.setState({ rowData: finalizedRankedUser, topThreeUsers: finalizedRankedUser.filter((user, index) => index < 3) });
                        })
                //     }
                // })
            })
        })
    }

    getMonthlyRanking = (month) => {
        // const { updateUserFund } = this.state;
        Promise.resolve(this.getInitialContribution()).then(initialContribution => {
            // const monthDate = this.getMonthDateRange(month);

            // const firstDayTimeStamp = month === 2 ? 1683216000000 : 1683734400000;
            // const lastDayTimeStamp = month === 2 ? 1683734399000 : 1684252799000;

            const firstDayParam = {
                // 'createddate[gte]': moment(monthDate.startDate).startOf('day').valueOf(),
                // 'createddate[lte]': moment(monthDate.startDate).endOf('day').valueOf(),
                // 'createddate[gte]': moment(firstDayTimeStamp).startOf('day').valueOf(),
                // 'createddate[lte]': moment(firstDayTimeStamp).endOf('day').valueOf(),
                '$expand': 'user',
            }
            apiUserAccount.getAllUserAccount(firstDayParam).then(firstDayRes => {
                const firstDayUsers = firstDayRes.data.map((user, index) => {
                    const prevUserData = {
                        id: index + 1,
                        contribution1: user.contribution_1,
                        contribution2: user.contribution_2,
                        nickname: user.user.nickname,
                        balance: user.total_fund_balance,
                    }
                    return prevUserData;
                });
                const firstDayRankedUser = this.calculateRank(firstDayUsers, initialContribution);
    
                const lastDayParam = {
                    // 'createddate[gte]': moment(monthDate.endDate).startOf('day').valueOf(),
                    // 'createddate[lte]': moment(monthDate.endDate).endOf('day').valueOf(),
                    // 'createddate[gte]': moment(lastDayTimeStamp).startOf('day').valueOf(),
                    // 'createddate[lte]': moment(lastDayTimeStamp).endOf('day').valueOf(),
                    '$expand': 'user',
                }
                apiUserAccount.getAllUserAccount(lastDayParam).then(res => {
                    const rowData = [];
                    res.data.forEach((user, index) => {
                        const userData = {
                            id: index + 1,
                            contribution1: user.contribution_1,
                            contribution2: user.contribution_2,
                            nickname: user.user.nickname,
                            balance: user.total_fund_balance,
                            isMe: user.user.username === this.props.auth.userInfo.username,
                            version: user.version,
                            // updateUserFund: updateUserFund
                        }
                        if (userData.isMe) {
                            this.setState({ myBalance: userData.balance });
                        }
                        rowData.push(userData);
                    })
                    const rankedUser = this.calculateRank(rowData, initialContribution);
                    const finalizedRankedUser = this.calculateUserTrend(firstDayRankedUser, rankedUser);
                    this.setState({ rowData: finalizedRankedUser });
                })
            })
        })
    }

    getMonthDateRange(month) {
        const startMonth = moment().month(month - 2).valueOf();
        let endOfStartMonth = moment(startMonth).endOf('month').valueOf();
        let endOfStartMonthDay = moment(endOfStartMonth).day();
        if (endOfStartMonthDay === 6 || endOfStartMonthDay === 7) {
            endOfStartMonth = moment(endOfStartMonth).subtract(endOfStartMonthDay - 5, 'days').valueOf();
        }

        const endMonth = moment().month(month - 1).valueOf();
        let endOfEndMonth = moment(endMonth).endOf('month').valueOf();
        let endOfEndMonthDay = moment(endOfStartMonth).day();
        if (endOfEndMonthDay === 6 || endOfEndMonthDay === 7) {
            endOfEndMonth = moment(endOfEndMonth).subtract(endOfEndMonthDay - 5, 'days').valueOf();
        }

        return { startDate: endOfStartMonth, endDate: endOfEndMonth };
    }

    componentDidMount() {
        this.getAsOfDate().then(() => {
            this.getOverallRanking();
        })
        const { rowData } = this.props;
    }

    getAsOfDate = () => {
        return apiFunds.getCurrentAsOfDate().then(obj => {
            if (obj && obj.status === 200) {
                const asOfDate = obj.data.as_of_date;
                return this.setState({ asOfDate: asOfDate });
            }
        })
    }

    getMonthRankingPermission = () => {
        apiTask.getTaskPermission().then(obj => {
            if (obj && obj.status === 200) {
                obj.data.forEach(permission => {
                    if (permission.permission_name === 'month 1 ranking') {
                        this.setState({ firstMonthRanking: permission.is_permitted });
                    }
                    if (permission.permission_name === 'month 2 ranking') {
                        this.setState({ secondMonthRanking: permission.is_permitted });
                    }
                    if (permission.permission_name === 'month 3 ranking') {
                        this.setState({ thirdMonthRanking: permission.is_permitted });
                    }
                })
            }
        })
    }

    getUpdateUserFundPermission = () => {
        return apiTask.getTaskPermission({permission_name: 'update user fund'}).then(obj => {
            if (obj && obj.status === 200 && obj.data.length > 0) {
                this.setState({ updateUserFund: obj.data[0].is_permitted });
            }
        })
    }

    handleTabChange = (event, value) => {
        this.setState({ rowData: [], myRank: '', myTrend: '', myBalance: '', myIncrement: '' });
        if (value !== 0) {
            this.getMonthlyRanking(value);
        } else {
            this.getOverallRanking();
        }
        // this.setState({ tabValue: value });
    }

    currentMonth = () => {
        const now = moment().valueOf();
        const month = moment(now).month();
        return month + 1;
    }

    render() {
        const { t, maxWidth, i18n, hide } = this.props;
        const { rowData, myRank, myTrend, myBalance, myIncrement, updateUserFund, topThreeUsers, asOfDate } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const locale = isChinese === 'zh-HK' ? 'zh-hk' : 'en';
        const dateFormat = isChinese ? 'YYYY年M月D日' : 'MMM D, YYYY';
        rowData.splice(0, 3);
        return !hide && (                    
            <Flex flex={1} justifyContent="center">
                <Flex className="ranking-container" flex={1} flexDirection="column" maxWidth={maxWidth} alignItems="center">
                    <Flex width="100%" justifyContent="center" marginBottom="1rem" flexWrap="wrap">
                        <Flex width="100%" marginBottom="2vw" >
                            <div>
                                <img className="title-bg" src={`${i18n.language === 'en-US' ? TitleBgENG : TitleBgCHI}`} alt="Logo" />
                            </div>
                        </Flex>

                        {/*<CustomTabs*/}
                        {/*    value={tabValue}*/}
                        {/*    onChange={this.handleTabChange}*/}
                        {/*    aria-label="Date range"*/}
                        {/*>*/}
                        {/*    /!*<CustomTab label={t("Ranking:overall")} value={0} />*!/*/}
                        {/*    /!* <CustomTab label="May 2023" value={2} disabled={this.currentMonth() > 2 ? false : true } />*/}
                        {/*    <CustomTab label="Jun 2023" value={3} disabled={this.currentMonth() > 3 ? false : true } />*/}
                        {/*    <CustomTab label="Jul 2023" value={4} disabled={this.currentMonth() > 4 ? false : true } /> *!/*/}
                        {/*    /!*<CustomTab label={t("Ranking:may")} value={2} disabled={!firstMonthRanking} />*!/*/}
                        {/*    /!*<CustomTab label={t("Ranking:june")} value={3} disabled={!secondMonthRanking} />*!/*/}
                        {/*    /!*<CustomTab label={t("Ranking:july")} value={4} disabled={!thirdMonthRanking} />*!/*/}

                        {/*    /!*<CustomTab label={t("Ranking:overall")} value={0} selected={tabValue === 0} />*!/*/}
                        {/*    /!*<CustomTab label={t("Ranking:may")} value={2} selected={tabValue === 2} />*!/*/}
                        {/*    /!*<CustomTab label={t("Ranking:june")} value={3} selected={tabValue === 3} />*!/*/}
                        {/*    /!*<CustomTab label={t("Ranking:july")} value={4} selected={tabValue === 4} />*!/*/}

                        {/*    <CustomTab label={t("Ranking:overall")} value={0} selected={tabValue === 0} />*/}
                        {/*    <CustomTab label={t("Ranking:may")} value={2} selected={tabValue === 2} />*/}
                        {/*    <CustomTab label={t("Ranking:june")} value={3} selected={tabValue === 3} />*/}
                        {/*    <CustomTab label={t("Ranking:july")} value={4} selected={tabValue === 4} />*/}

                        {/*</CustomTabs>*/}
                    </Flex>
                    <Flex className="acc-balance text-white" width="100%" justifyContent="flex-start" mb="1rem">
                        {`${t("Landing:asOf")} ${moment(asOfDate).locale(locale).format(dateFormat)}`}
                    </Flex>
                    <Flex className="acc-balance text-white">
                        {t("Ranking:accountBalance")}
                    </Flex>
                    <Flex className="acc-money text-white" marginBottom="1rem">
                    {/* <Flex className="acc-money text-white" marginBottom={!updateUserFund && '1rem' }> */}
                        {t("Ranking:hk$")}{myBalance.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Flex>
                    {/* {myBalance && <Flex className="acc-percent text-white" marginBottom="1rem">
                        {`${myIncrement > 0 ? '+' : '-'}${(myIncrement).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}%`} <img className="ranking-ward" alt="increment" src={myIncrement > 0 ? upWard : downWard} />
                    </Flex>} */}
                    <Flex>
                        <hr className="middle-line" />
                    </Flex>
                    <Flex width="100%" justifyContent="space-between" marginBottom="1rem" color="white">
                        {topThreeUsers.map((user, index) => {
                            return <Flex flexDirection="column" justifyContent="flex-end" height="100%">
                                {index === 0 ? <Flex className="flex-content"><img className="ranking-crown" alt="crown" src={crown} /></Flex> :
                                    <Flex className="flex-content number-title" fontSize={index === 1 ? "2rem" : "1.7rem"}>{index + 1}</Flex>}
                                <Flex marginBottom="0.5rem" className="flex-content player-game">{user.nickname}</Flex>
                                    <Flex className="flex-content sub-money">{t("Ranking:hk$")}{user.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Flex>
                                </Flex>
                        })}
                    </Flex>



                    {/*<Flex width="100%" textAlign="center" justifyContent="space-evenly" paddingBottom="1rem" fontSize="1.2rem" style={{ border: '1px solid #e0e0e0', borderWidth: '0 0 1px 0' }}>*/}
                    {/*    /!*<Flex flexDirection="column" flex={1}>*!/*/}
                    {/*    /!*    <Flex justifyContent="center">*!/*/}
                    {/*    /!*        {t("Ranking:myRanking")}*!/*/}
                    {/*    /!*    </Flex>*!/*/}
                    {/*    /!*    <Flex alignItems="center" justifyContent="center">*!/*/}
                    {/*    /!*        <div style={{ fontSize: '2rem' }}>*!/*/}
                    {/*    /!*            {isRegistrationPeriod ? '--' : myRank}*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*        {!isRegistrationPeriod && <Flex height="100%" fontSize="1.2em" alignItems="center">*!/*/}
                    {/*    /!*            <img src={myTrend ? (myTrend > 0 ? upArrowIcon : downArrowIcon) : null} style={{ width: '20px'}} alt="" /> {myTrend ? Math.abs(myTrend) : null}*!/*/}
                    {/*    /!*        </Flex>}*!/*/}
                    {/*    /!*    </Flex>*!/*/}
                    {/*    /!*</Flex>*!/*/}
                    {/*    <Flex flexDirection="column" alignItems="center" flex={1}>*/}
                    {/*        <Flex justifyContent="center">*/}
                    {/*            {t("Ranking:myBalance")}*/}
                    {/*        </Flex>*/}
                    {/*        {rowData.length > 0 && <Flex flexDirection="column" alignItems="flex-end">*/}
                    {/*            <Flex>*/}
                    {/*                {myBalance.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}*/}
                    {/*            </Flex>*/}
                    {/*            {myBalance && !isRegistrationPeriod && <Flex color={myIncrement > 0 ? '#0000c1' : myIncrement === 0 ? 'black' : 'red'}>*/}
                    {/*                {`${myIncrement > 0 ? '+' : ''}${myIncrement.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}%`}*/}
                    {/*            </Flex>}*/}
                    {/*        </Flex>}*/}
                    {/*    </Flex>*/}
                    {/*</Flex>*/}
                    {rowData.length> 0 && <div className="table-content" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={getColumnDefs(t)}
                            getRowStyle={getRowStyle}
                            gridOptions={gridOptions}
                        />
                    </div>}
                </Flex>
            </Flex>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({

});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(RankingTable)));
