import { Dialog, IconButton, DialogContent, Typography } from "@material-ui/core";
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Flex } from "reflexbox";

const riskClassColors = ['#76b1ff', '#2d69ff', '#1e1ee5', '#0000c1', '#00009a', '#000082', '#000060'];

const cnRiskClassDescriptions = [
    `每個成分基金均須根據該成分基金的最新⾵險指標，劃分為以下七個⾵險級別的其中⼀個⾵險級別。風險指標是以年度標準差表示，數據是根據過往三年之按月回報率計算，並準確至小數後兩個位。一般來說，年度標準差數值越大，成分基金的風險╱波幅也將相對較高。成分基金的風險指標會於該基金推出三年後提供。`,
    `⾵險級別由強制性公積金計劃管理局（「積金局」）根據強積金投資基金披露守則制定，而該⾵險級別並未經香港證券及期貨事務監察委員會（「證監會」）審閱或認許。`
]

const engRiskClassDescriptions = [
    `A risk class is to be assigned to each constituent fund according to the seven-point risk classification below based on the latest risk indicator of the constituent fund. The Risk Indicator shows the annualized standard deviation based on the monthly rates of return of the fund over the past 3 years, and correct to 2 decimal places. Generally, the greater the annualized standard deviation, the more volatile/risky the constituent fund. The Risk Indicator for the constituent fund will be available 3 years after its launch date.`,
    `The risk class is prescribed by the Mandatory Provident Fund Schemes Authority ("MPFA") according to the Code on Disclosure for MPF Investment Funds and the risk class has not been reviewed or endorsed by the Securities and Futures Commission ("SFC").`
]

const LargeWidthTable = (props) => {
    const { t } = props;
    return <table>
        <thead>
            <tr>
                <th colSpan={2}>{t("ManageFunds:riskClass")}</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td rowSpan={2} className="bold">{t("ManageFunds:riskIndicator")}</td>
                <td>{t("ManageFunds:equalOrAbove")}</td>
                <td>0.0%</td>
                <td>0.5%</td>
                <td>2.0%</td>
                <td>5.0%</td>
                <td>10.0%</td>
                <td>15.0%</td>
                <td>25.0%</td>
            </tr>
            <tr>
                <td>{t("ManageFunds:lessThan")}</td>
                <td>0.5%</td>
                <td>2.0%</td>
                <td>5.0%</td>
                <td>10.0%</td>
                <td>15.0%</td>
                <td>25.0%</td>
                <td></td>
            </tr>
        </tbody>
    </table>
}

const SmallWidthTable = (props) => {
    const { t } = props;
    return <table>
        <tbody>
            <tr>
                <td rowSpan={2} className="bold" style={{ backgroundColor: 'white' }}>{t("ManageFunds:riskClass")}</td>
                <td colSpan={2} className="bold">{t("ManageFunds:riskIndicator")}</td>
            </tr>
            <tr>
                <td>{t("ManageFunds:equalOrAbove")}</td>
                <td>{t("ManageFunds:lessThan")}</td>
            </tr>
            <tr>
                <td>1</td>
                <td>0.0%</td>
                <td>0.5%</td>
            </tr>
            <tr>
                <td>2</td>
                <td>0.5%</td>
                <td>2.0%</td>
            </tr>
            <tr>
                <td>3</td>
                <td>2.0%</td>
                <td>5.0%</td>
            </tr>
            <tr>
                <td>4</td>
                <td>5.0%</td>
                <td>10.0%</td>
            </tr>
            <tr>
                <td>5</td>
                <td>10.0%</td>
                <td>15.0%</td>
            </tr>
            <tr>
                <td>6</td>
                <td>15.0%</td>
                <td>25.0%</td>
            </tr>
            <tr>
                <td>7</td>
                <td>25.0%</td>
                <td></td>
            </tr>
        </tbody>
    </table>
}

const RiskDialog = (props) => {
    const { t, handleCloseRiskClassDialog, windowWidth, open, i18n } = props;
    const riskClassDescription = i18n.language === 'zh-HK' ? cnRiskClassDescriptions : engRiskClassDescriptions;
    return (
        <Dialog open={open}>
            <Flex mt="1.5rem" mb="1rem" justifyContent="center" alignItems="center" fontWeight="bold" fontSize="1.25rem">
                {t("ManageFunds:riskClass")}
                <IconButton onClick={handleCloseRiskClassDialog} style={{ color: 'black', position: 'absolute', right: 15, padding: 0 }}>
                    <CloseIcon style={{ width: 20, height: 20 }} />
                </IconButton>
            </Flex>
            <DialogContent style={{ marginBottom: '1rem' }}>
                <Flex width="100%" justifyContent="center" marginBottom="1rem">
                    {riskClassColors.map((color, index) => <Flex key={index} backgroundColor={color} color="white" width="20%" maxWidth={70} height={50} justifyContent="center" alignItems="center" fontWeight="bold" fontSize={18} >
                        {index}
                    </Flex>)}
                </Flex>
                <Typography style={{ marginBottom: '1rem', textAlign: 'justify' }}>
                    {riskClassDescription[0]}
                </Typography>
                <Typography style={{ marginBottom: '1.5rem', textAlign: 'justify' }}>
                    {riskClassDescription[1]}
                </Typography>
                <Flex className="risk-class-table">
                    {windowWidth < 539 ? <SmallWidthTable t={t} /> : <LargeWidthTable t={t} />}                   
                </Flex>
            </DialogContent>
        </Dialog>
    )
}

export default RiskDialog;