import { API_CENTRAL_URL_BASE } from '../../config';
// import btoa from 'btoa';
// const WEB_CLIENT_ID = 'T7n7y3TXMy6lRd6';
// const WEB_CLIENT_SECRET = 'dj67Mlj26lT7Tdyy6l66R6lj666TC36n3n7TnjddjyT7T6R6Ty6yRnXMylM23y3';

export const BASE_URL = API_CENTRAL_URL_BASE;

// export const WEB_CLIENT_CREDENTIAL_TOKEN = btoa(`${WEB_CLIENT_ID}:${WEB_CLIENT_SECRET}`);
export const WEB_CLIENT_CREDENTIAL_TOKEN = 'SEw0OVphQjZCVThPeHVKOkQ4OHNKbWo4d1VMY0NyOXBZVXE0TzhKY0pwTHNyWGhi';

export const AUDIENCE = 'mpf.joyaether.com';
