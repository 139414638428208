// Essential for all components
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Styling
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { Flex } from 'reflexbox';

// Api
import { apiAuth } from '../../Api/ApiAuth';

// Redux
import { connect } from 'react-redux';
import { login, forgetPasswordClear } from '../../Redux/Action/authAction';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import GeneralMessage from '../../components/100Include/GeneralMessage';
import backIcon from "../../images/back.png";


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: ''
        }
    }

    _resetPasswordAsync = (values) => {
        if (typeof (values) !== 'undefined') {
            var promise = Promise.resolve();
            apiAuth.getClientCredentials().then((res) => {
                promise.then(() => {
                    this._resetPassword(values, res.data.access_token);
                });
            })
        }
    }

    _resetPassword = (values, token) => {
        const { i18n, t } = this.props;
        let params = (new URL(document.location)).searchParams;
        let username = params.get('username');
        let tempPassword = params.get('tempPassword');
        const cb = (obj) => {
            if(obj && obj.status ===204 && obj.ok){
                alert(`${t("LoginRegister:resetPassword.successDescription1")} ${t("LoginRegister:resetPassword.successDescription2")}`)
                
                // clear the reset password username in redux
                this.props.forgetPasswordClearP();

                this.props.history.push('/' + i18n.language + '/login');
            } else {
                const jsonObjPromise = obj.json();
                jsonObjPromise.then(jsonObj => {
                    if (jsonObj.error) {
                        this.setState({ MessageContent: jsonObj.error})
                    }
                })
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const body = {
            old: {
                password: tempPassword
            },
            new: {
                password: values.password
            }
        };
        apiAuth.resetPassword(username, body, token, cb, eCb);
    }

    back = () => {
        const { i18n, history } = this.props;
        history.push('/' + i18n.language + `/login`);
    }

    formResetPassword = ({ values, errors, touched, handleChange }) => {
        const { t, i18n } = this.props;
        let errorMessage = this.state.MessageContent;
        if (errorMessage.includes('same as old password')) {
            errorMessage = t("LoginRegister:resetPassword.samePasswordError");
        }

        return (
            <Grid item xm={12} md={6} xl={4} className="grid">
                <Form id="resetPasswordForm" className="form-wrapper forgot-form">
                    <Grid container>
                        <Grid item xs={12} className="grid">
                            <div style={{ fontSize: 18, color: '#717171' }}>{t("LoginRegister:placeholder.newPassword")}</div>
                            <Field name="password" type="password" maxLength="100" style={{ 'width': '100%' }} />
                            {errors.password && touched.password && <ErrorMessage message={errors.password} />}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <div style={{ fontSize: 18, color: '#717171' }}>{t("LoginRegister:placeholder.confirmNewPassword")}</div>
                            <Field name="confirmPassword" type="password"  maxLength="100" style={{ 'width': '100%' }} />
                            {errors.confirmPassword && touched.confirmPassword ? <ErrorMessage message={errors.confirmPassword} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Button type="submit">{t("Common:General.Reset")}</Button>
                        </Grid>
                    
                        <Grid item xs={12} className="SuccessMessage">
                            {this.state.MessageContent !== ''?
                            <GeneralMessage
                                message={errorMessage}
                            />:null}
                            
                        </Grid>
                        <Grid item xs={12} className="grid align-center">
                            <Link to={"/" + i18n.language + '/login'}>{t("LoginRegister:forgetPassword.backTo")}</Link>
                        </Grid>
                    </Grid>
                </Form>
            </Grid>
        )
    }

    render() {
        const { t, history, i18n } = this.props
        // Redirect to home
        if (this.props.auth.auth) {
            history.push('/' + i18n.language + `/index`);
        }

        const Schema = Yup.object().shape({
            password: Yup.string()
                // .typeError('New Password must be a valid string')
                .typeError(t("LoginRegister:resetPassword.passwordNotValidError"))
                // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Does not match Password requirements!")
                .required(t("LoginRegister:resetPassword.passwordNotValidError")),
            confirmPassword: Yup.string()
                .typeError(t("LoginRegister:resetPassword.passwordNotValidError"))
                .oneOf([Yup.ref('password'), null], t("LoginRegister:resetPassword.passwordNotMatchError"))
                .required(t("LoginRegister:resetPassword.passwordNotValidError")),
        })

        return (
            <div className='forgot-page'>
                <div className="main__container" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                        <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                        <Flex ml="1rem">
                            {t("Common:General.ResetPassword")}
                        </Flex>
                    </Flex>
                    <Flex justifyContent="center">
                        <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        validationSchema={Schema}
                        onSubmit={this._resetPasswordAsync}
                        component={this.formResetPassword}
                    />
                    </Flex>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    forgetPasswordClearP: data => dispatch(forgetPasswordClear(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword)));
