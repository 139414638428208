// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox';

import backIcon from "../../images/back.png";
import { apiFunds } from '../../Api/_ApiFunds';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import riskIndex1 from "../../images/risk-index-1.png";
import riskIndex2 from "../../images/risk-index-2.png";
import riskIndex3 from "../../images/risk-index-3.png";
import riskIndex4 from "../../images/risk-index-4.png";
import riskIndex5 from "../../images/risk-index-5.png";
import riskIndex6 from "../../images/risk-index-6.png";
import riskIndex7 from "../../images/risk-index-7.png";
import RiskDialog from '../../components/RiskClassDialog';

const FundPriceList = (props) => {
    const { t, asOfDate, funds, handleOpenRiskClassDialog } = props;
    return (
        <Flex flexDirection="column" width="100%" maxWidth={1000}>
            <Flex backgroundColor="#ededed" alignItems="flex-start" padding="1rem" fontWeight="bold">
                <Flex flex={2} flexDirection="column">
                    <Flex>{t("FundPrice:constituentFundName")}</Flex>
                    <Flex className="sub-text" fontWeight="normal">{t("FundPrice:currency")}</Flex>
                </Flex>
                <Flex flex={1} justifyContent="center">
                    {t("FundPrice:riskClass")}
                    <IconButton onClick={handleOpenRiskClassDialog} style={{ margin: 0, marginLeft: 5, padding: 0, cursor: 'pointer' }}>
                        <InfoOutlinedIcon style={{ fontSize: 15 }} />
                    </IconButton>
                </Flex>
                <Flex flex={1} justifyContent="flex-end">{t("FundPrice:navUnit")}</Flex>
            </Flex>
            <Flex flex={1} flexDirection="row" mt="2rem" paddingX="1rem">
                <Flex flex={1} flexDirection="column" alignItems="flex-start" paddingRight="1rem">
                    <Flex style={{ textDecoration: 'underline', textUnderlineOffset: '5px', textDecorationThickness: '1.5px', textDecorationColor: '#33A35B'}}>
                        D148 / D149 - {t(`Fund:DefaultInvestmentStrategy`)}
                    </Flex>
                    <Flex className="sub-text">
                        {t("FundPrice:hkd")}
                    </Flex>
                </Flex>
                <Flex flex={1} justifyContent="center" className="sub-text" alignItems="center !important" textAlign="center">
                    {t(`FundPrice:referToTheRiskClassAndFundPriceOf148/149`)}
                </Flex>
            </Flex>
            <hr style={{ color: '#e0e0e0', margin: '1rem 0px' }} />
            {funds.map(fund => {
                let riskIcon;
                switch (fund.risk_index) {
                    case 1: {
                        riskIcon = riskIndex1;
                        break;
                    }
                    case 2: {
                        riskIcon = riskIndex2;
                        break;
                    }
                    case 3: {
                        riskIcon = riskIndex3;
                        break;
                    }
                    case 4: {
                        riskIcon = riskIndex4;
                        break;
                    }
                    case 5: {
                        riskIcon = riskIndex5;
                        break;
                    }
                    case 6: {
                        riskIcon = riskIndex6;
                        break;
                    }
                    case 7: {
                        riskIcon = riskIndex7;
                        break;
                    }
                    default: {}
                }
                return !['DIS148 / DIS149', 'DIS148', 'DIS149'].includes(fund.fund_code) && (
                    <Flex flexDirection="column">
                        <Flex key={fund.fund_code} flex={1} flexDirection="row" justifyContent="space-between" mt="2rem" paddingX="1rem">
                            <Flex flex={2} flexDirection="column" alignItems="flex-start" paddingRight="1rem">
                                <Flex style={{ textDecoration: 'underline', textUnderlineOffset: '5px', textDecorationThickness: '1.5px', textDecorationColor: '#33A35B'}}>
                                    {t(`Fund:${fund.fund_code}`)} {t(`Fund:${fund.fund_name}`)}
                                </Flex>
                                <Flex className="sub-text">
                                    {t("FundPrice:hkd")}
                                </Flex>
                            </Flex>
                            {riskIcon && <Flex flex={1} flexDirection="column" alignItems="center" height="100%" paddingRight="1rem">
                                <Flex alignItems="center" height="100%">
                                    <img className="risk-icon" src={riskIcon} alt="riskIcon" />
                                </Flex >
                                <Flex textAlign="right" height="100%" className="sub-text">{t("FundPrice:asOf")} {moment(fund.review_date).format('DD/MM/YYYY')}</Flex>
                            </Flex>}
                            <Flex flex={1} flexDirection="column" alignItems="flex-end" height="100%">
                                <Flex alignItems="center" height="100%">{fund.unit_price_reference}</Flex>
                                {fund.unit_price_reference && <Flex textAlign="right" height="100%" className="sub-text">{t("FundPrice:asOf")} {moment(asOfDate).format('DD/MM/YYYY')}</Flex>}
                            </Flex>
                        </Flex>
                        <hr style={{ color: '#e0e0e0', margin: '1rem 0px' }} />
                    </Flex>
                )
            })}
        </Flex>
    )
}

class FundPrice extends Component {

    state = {
        funds: [],
        asOfDate: null,
        riskClassDialogOpen: false,
        windowWidth: null
    }

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/index');
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this._getAllFunds();
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    _getAllFunds = () => {
        apiFunds.getCurrentAsOfDate().then(obj => {
            if (obj && obj.status === 200) {
                const asOfDate = obj.data.as_of_date;
                this.setState({ asOfDate: asOfDate });

                const param = {
                    latest_date: moment(asOfDate).utc().format('YYYY-MM-DD HH:mm:ss'),
                    '$orderby': 'sequence'
                }
                apiFunds.getAllFunds(param).then(res => {
                    if (res && res.status === 200) {
                        this.setState({ funds: res.data });
                    }
                });
            }
        })
    }

    handleCloseRiskClassDialog = () => {
        this.setState({ riskClassDialogOpen: false });
    }

    handleOpenRiskClassDialog = () => {
        this.setState({ riskClassDialogOpen: true });
    }

    render() {
        const { t, i18n } = this.props;
        const { asOfDate, funds, riskClassDialogOpen, windowWidth } = this.state;
        return (
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("Common:MenuItem.fundPrice")}
                            </Flex>
                        </Flex>
                        {funds.length > 0 && <Flex flexDirection="column" className="fund-price-page" flex={1} alignItems="center">
                            <FundPriceList t={t} i18n={i18n} asOfDate={asOfDate} funds={funds} handleOpenRiskClassDialog={this.handleOpenRiskClassDialog} />
                        </Flex>}
                        <RiskDialog open={riskClassDialogOpen} i18n={i18n} t={t} handleCloseRiskClassDialog={this.handleCloseRiskClassDialog} windowWidth={windowWidth} />
                    </Flex>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(withRouter(FundPrice));
