// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox';

import backIcon from "../../images/back.png";

const imagePaths = {
    'en-US': {
        prizeSmall: require("../../images/NewLandingPage/icon_prize_es.png"),
        prizeMedium: require("../../images/NewLandingPage/icon_prize_em.png"),
        prizeLarge: require("../../images/NewLandingPage/icon_prize_el.png"),
        luckyDrawSmall: require("../../images/NewLandingPage/icon_draw_es.png"),
        luckyDrawMedium: require("../../images/NewLandingPage/icon_draw_em.png"),
        luckyDrawLarge: require("../../images/NewLandingPage/icon_draw_el.png"),
        luckyDrawButtonSmall: require("../../images/NewLandingPage/btn3_es.png"),
        luckyDrawButtonMedium: require("../../images/NewLandingPage/btn3_em.png"),
        luckyDrawButtonLarge: require("../../images/NewLandingPage/btn3_el.png"),
    },

    'zh-HK': {
        prizeSmall: require("../../images/NewLandingPage/icon_prize_s.png"),
        prizeMedium: require("../../images/NewLandingPage/icon_prize_m.png"),
        prizeLarge: require("../../images/NewLandingPage/icon_prize_l.png"),
        luckyDrawSmall: require("../../images/NewLandingPage/icon_draw_s.png"),
        luckyDrawMedium: require("../../images/NewLandingPage/icon_draw_m.png"),
        luckyDrawLarge: require("../../images/NewLandingPage/icon_draw_l.png"),
        luckyDrawButtonSmall: require("../../images/NewLandingPage/btn3_s.png"),
        luckyDrawButtonMedium: require("../../images/NewLandingPage/btn3_m.png"),
        luckyDrawButtonLarge: require("../../images/NewLandingPage/btn3_l.png"),
    }
};

class Prize extends Component {

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/index');
    }

    handleClickLuckyDraw = () => {
        window.open(`/`, '_blank');
    }

    render() {
        const { t, i18n } = this.props;
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("Prize:prize")}
                            </Flex>
                        </Flex>
                        <Flex className="page-landing" flexDirection="column" style={{ zIndex: 0 }}>
                            <div id="prizeDesk" class="container prize" style={{ margin: 0, paddingTop: 50 }}>
                                <img class="media-s" src={imagePaths[i18n.language].prizeSmall} alt="prize-s" />
                                <img class="media-m" src={imagePaths[i18n.language].prizeMedium} alt="prize-m" />
                                <img class="media-l" src={imagePaths[i18n.language].prizeLarge} alt="prize-l" />
                            </div>

                            <div class="container draw">
                                <img class="media-s" src={imagePaths[i18n.language].luckyDrawSmall} alt="lucky-draw-s" />
                                <img class="media-m" src={imagePaths[i18n.language].luckyDrawMedium} alt="lucky-draw-m" />
                                <img class="media-l" src={imagePaths[i18n.language].luckyDrawLarge} alt="lucky-draw-l" />
                                <div class="btn3" onClick={this.handleClickLuckyDraw}>
                                    <img class="media-s" src={imagePaths[i18n.language].luckyDrawButtonSmall} alt="lucky-draw-button-s" />
                                    <img class="media-m" src={imagePaths[i18n.language].luckyDrawButtonMedium} alt="lucky-draw-button-m" />
                                    <img class="media-l" src={imagePaths[i18n.language].luckyDrawButtonLarge} alt="lucky-draw-button-l" />
                                </div>
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(withRouter(Prize));