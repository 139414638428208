import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import en_us_Common from "./en-us/Common.json";
import en_us_LoginRegister from "./en-us/LoginRegister.json";
import en_us_Upload from "./en-us/Upload.json";
import en_us_Sidebar from "./en-us/Sidebar.json";
import en_us_UserManagement from "./en-us/UserManagement.json";
import en_us_TenantManagement from "./en-us/TenantManagement.json";
import en_us_AuctionManagement from "./en-us/AuctionManagement.json";
import en_us_ClientManagement from "./en-us/ClientManagement.json";
import en_us_Reward from "./en-us/Reward.json";
import en_us_ManageFunds from "./en-us/ManageFunds.json";
import en_us_MiniGame from "./en-us/MiniGame.json";
import en_us_TradeHistory from "./en-us/TradeHistory.json";
import en_us_Badge from "./en-us/Badge.json";
import en_us_HowToPlay from "./en-us/HowToPlay.json";
import en_us_Prize from "./en-us/Prize.json";
import en_us_BeforeLogin from "./en-us/BeforeLogin.json";
import en_us_Landing from "./en-us/Landing.json";
import en_us_Ranking from "./en-us/Ranking.json";
import en_us_Fund from "./en-us/Fund.json";
import en_us_EmailSupport from "./en-us/EmailSupport.json";
import en_us_Langdingpg from "./en-us/Landingpg.json";
import en_us_FundPrice from "./en-us/FundPrice.json";

import zh_hk_Common from "./zh-hk/Common.json";
import zh_hk_LoginRegister from "./zh-hk/LoginRegister.json";
import zh_hk_Upload from "./zh-hk/Upload.json";
import zh_hk_Sidebar from "./zh-hk/Sidebar.json";
import zh_hk_UserManagement from "./zh-hk/UserManagement.json";
import zh_hk_TenantManagement from "./zh-hk/TenantManagement.json";
import zh_hk_AuctionManagement from "./zh-hk/AuctionManagement.json";
import zh_hk_ClientManagement from "./zh-hk/ClientManagement.json";
import zh_hk_Reward from "./zh-hk/Reward.json";
import zh_hk_ManageFunds from "./zh-hk/ManageFunds.json";
import zh_hk_MiniGame from "./zh-hk/MiniGame.json";
import zh_hk_TradeHistory from "./zh-hk/TradeHistory.json";
import zh_hk_Badge from "./zh-hk/Badge.json";
import zh_hk_HowToPlay from "./zh-hk/HowToPlay.json";
import zh_hk_Prize from "./zh-hk/Prize.json";
import zh_hk_BeforeLogin from "./zh-hk/BeforeLogin.json";
import zh_hk_Landing from "./zh-hk/Landing.json";
import zh_hk_Ranking from "./zh-hk/Ranking.json";
import zh_hk_Fund from "./zh-hk/Fund.json";
import zh_hk_EmailSupport from "./zh-hk/EmailSupport.json";
import zh_hk_Langdingpg from "./zh-hk/Landingpg.json";
import zh_hk_FundPrice from "./zh-hk/FundPrice.json";

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'zh-HK',
        fallbackLng: 'en-US',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        ns: ['common', 'contact'],
        defaultNS: 'common',
        resources: {
            'en-US': {
                Common: en_us_Common,
                LoginRegister: en_us_LoginRegister,
                Sidebar: en_us_Sidebar,
                UserManagement: en_us_UserManagement,
                TenantManagement: en_us_TenantManagement,
                AuctionManagement: en_us_AuctionManagement,
                ClientManagement: en_us_ClientManagement,
                Upload: en_us_Upload,
                Reward: en_us_Reward,
                ManageFunds: en_us_ManageFunds,
                MiniGame: en_us_MiniGame,
                TradeHistory: en_us_TradeHistory,
                Badge: en_us_Badge,
                HowToPlay: en_us_HowToPlay,
                Prize: en_us_Prize,
                BeforeLogin: en_us_BeforeLogin,
                Landing: en_us_Landing,
                Ranking: en_us_Ranking,
                Fund: en_us_Fund,
                EmailSupport: en_us_EmailSupport,
                Landingpg: en_us_Langdingpg,
                FundPrice: en_us_FundPrice,
            },
            'zh-HK': {
                Common: zh_hk_Common,
                LoginRegister: zh_hk_LoginRegister,
                UserManagement: zh_hk_UserManagement,
                TenantManagement: zh_hk_TenantManagement,
                AuctionManagement: zh_hk_AuctionManagement,
                ClientManagement: zh_hk_ClientManagement,
                Upload: zh_hk_Upload,
                Sidebar: zh_hk_Sidebar,
                Reward: zh_hk_Reward,
                ManageFunds: zh_hk_ManageFunds,
                MiniGame: zh_hk_MiniGame,
                TradeHistory: zh_hk_TradeHistory,
                Badge: zh_hk_Badge,
                HowToPlay: zh_hk_HowToPlay,
                Prize: zh_hk_Prize,
                BeforeLogin: zh_hk_BeforeLogin,
                Landing: zh_hk_Landing,
                Ranking: zh_hk_Ranking,
                Fund: zh_hk_Fund,
                EmailSupport: zh_hk_EmailSupport,
                Landingpg: zh_hk_Langdingpg,
                FundPrice: zh_hk_FundPrice,
            }
        }
    });

export default i18n;
