// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox';
import { CnTandC, EngTandC } from '../../components/TandC';


export const CnLuckyDrawTandC = (props) => {
    return <Flex style={props.style}>
        <ol>
            <li>MPF積FUN賽大抽獎（「抽獎活動」）由諗頭有限公司（「諗頭」）舉辦。</li>
            <li>此抽獎活動之推廣期為2023年10月1日至2023年12月31日，包括首尾兩天（「推廣期」）。</li>
            <li>MPF積FUN賽的帳戶登記期由2023年10月1日開始至2023年10月26日，包括首尾兩天(「登記期」)。</li>
            <li>抽獎活動只限宏利人壽保險(國際)有限公司(於百慕達註冊成立之有限責任公司)(「宏利」)特選客戶參加，其必須持有有效的宏利強積金供款帳戶。</li>
            <li>MPF積FUN賽參賽者須符合以下所有條件方可於推廣期內獲得抽獎機會一次(「合資格參賽者」)：</li>
            <ol type="i" style={{ marginLeft: -20 }}>
                <li>參賽者必須年滿18歲或以上及持有有效香港身份證/護照；及</li>
                <li>於登記期內成功登記成為MPF積FUN賽參賽者；及</li>
                <li>於2023年11月1日至2023年12月31日(包括首尾兩天)內透過MPF積FUN賽成功完成首四個任務。</li>
            </ol>
            <li>合資格參賽者將會自動參加抽獎活動。</li>
            <li>如合資格參賽者登記參加MPF積分賽，則代表其已閱讀、理解並同意是次抽獎活動之條款及細則、諗頭的個人資料收集聲明和同意接收抽獎活動的相關資訊，包括得獎通知。合資格參賽者的個人資料將僅用於得獎者通知、通信及其他與抽獎活動有關的目的。</li>
            <li>每位合資格參賽者只限參加本抽獎活動一次。若諗頭發現重覆登記並有理由相信參加者違反是次抽獎活動之細則及條款以獲取不公平的優勢，有權取消其參加資格。</li>
            <li>受僱於諗頭的董事、高級職員及僱員均不得參加本抽獎活動，以示公允。</li>
            <li>抽獎活動獎品及得獎名額</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>頭獎：HK$15,000 Apple Store禮品卡（1名）</li>
                <li>二獎：HK$10,000 Apple Store禮品卡（1名）</li>
                <li>三獎：HK$5,000 city’super購物禮券（1名）</li>
                <li>四獎：HK$3,000 city’super購物禮券（1名）</li>
                <li>五獎：HK$2,000 city’super購物禮券（1名）</li>
                <li>六獎至二百獎：HK$1,000 百佳超級市場購物禮券（195名）</li>
            </ol>
            <li>得獎名單將於2024年2月7日由諗頭安排的電腦系統隨機抽出，諗頭的抽獎結果記錄為最終結果及不可推翻。</li>
            <li>得獎名單（包括得獎者全名及手機號碼尾4個數字）將於2024年2月16日（ 香港時間） 於MPF積分賽網頁內公佈，並於2024年2月16日（香港時間）刊登於《英文虎報》及《星島日報》。諗頭將於2024年2月16日（ 香港時間 ）透過得獎者登記MPF積分賽時所提供的電郵地址，以電郵個別通知得獎者。如寄往得獎者的電郵因任何原因被退回或無法寄出，或未能在電郵所示的指定時間內領取獎品，得獎者之領獎資格將被視為自動作廢論，該名得獎者將不能領取獎品。得獎者若無法收到領獎通知或領獎詳情，或因任何原因未能領取獎品，包括（ 但不限於） 由於在抽獎登記過程中提供任何虛假、不完整或不正確訊息，諗頭概不負責。</li>
            <li>得獎者必須同意其全名及部份手機號碼將經得獎名單向公眾人士披露。</li>
            <li>於領獎時，得獎者需出示得獎通知電郵及其有效之香港身份證/護照以作核對，並簽署確認信以作換領及記錄用途。否則，其得獎資格將會被取消而不作另行通知。</li>
            <li>所有抽獎獎品均不得兌換現金或要求以其他優惠取代。</li>
            <li>不論任何原因，得獎者如沒有在電郵所示的指定領獎限期內領取獎品，其得獎資格將會被取消而不作另行通知，獎品亦將被撤回。</li>
            <li>得獎者有責任遵守任何有關授予獎品的稅收，關稅，徵稅或類似罰款的法律（自費），諗頭對此不承擔任何責任。</li>
            <li>諗頭之推廣生意的競賽牌照號碼：57523-24。</li>
            <li>根據此條款及細則適用條文，諗頭擁有唯⼀和最終決定權以確定獲得本次抽獎活動提供的獎品的資格。如果諗頭在任何時候（無論是在推廣期還是推廣期之後）發現任何人未能遵守這些條款及細則，諗頭有權取消該人士參加抽獎活動和領取獎品的資格。</li>
            <li>如諗頭發現任何合資格參賽者不遵守本條款及細則、涉及任何舞弊、濫用及／或欺詐成分、虛報資料或違反適用的法律或法規，諗頭有權取消其參加本抽獎活動的資格。如果合資格參賽者被取消資格，獎品可能會被撤銷和收回。</li>
            <li>諗頭並非本抽獎之獎品製造/供應商，故不會對獎品之質素、供應、派送及與使用獎品有關事宜之任何後果負責。所有獎品之使用須受個別製造/供應商所訂定之所有條款及條件規限。諗頭毋須就獎品的任何問題（包括但不限於其品質、供應、派送、及使用有關事宜）負上任何責任。對任何因參加此抽獎活動、使用或不當使用該獎品而直接或間接引致的任何損失或損害，諗頭概不責任或承擔任何法律責任。</li>
            <li>如有任何爭議，諗頭所作的決定為最終及不可推翻。</li>
            <li>本條款及細則之中英文文本如有歧異，概以英文本為準。</li>
            <li>如有任何查詢，請電郵至info@mpfgame.com與諗頭聯絡。</li>
            {/* <li>宏利將不承擔任何與抽獎活動有關的責任。</li> */}
        </ol>
    </Flex>
}

export const EngLuckyDrawTandC = (props) => {
    return <Flex style={props.style}>
        <ol>
            <li>MPF Fun Game Grand Lucky Draw ("Lucky Draw") is organized by Think A Head Limited ("Think A Head").</li>
            <li>The promotion period of the Lucky Draw is from 1 October 2023 to 31 December 2023, both dates inclusive (the "Promotion Period").</li>
            <li>The registration period for this Game is from 1 October 2023 to 26 October 2023, both dates inclusive ("Registration Period").</li>
            <li>Lucky Draw is only available to Manulife (International) Limited (Incorporated in Bermuda with limited liability) ("Manulife") selected customers who must hold a valid Manulife MPF contribution account.</li>
            <li>MPF Fun Game participants must fulfill all of the following requirements to be entitled to Lucky Draw for once during the Promotion Period ("Eligible Participants").</li>
            <ol type="i" style={{ marginLeft: -20 }}>
                <li>Participants must be aged 18 or above and a holder of a valid Hong Kong Identity Card ("HKID")/passport; and</li>
                <li>Successfully registered as a participant in MPF Fun Game within the Registration Period ; and</li>
                <li>Completed the first 4 missions in MPF Fun Game from 1 November 2023 to 31 December 2023, both dates inclusive.</li>
            </ol>
            <li>Eligible Participants will be automatically participated in the Lucky Draw.</li>
            <li>By participating in the Lucky Draw shall serve as Eligible Participants' consent and acceptance of the terms and conditions hereof, personal information collection statement of Think A Head, and agreement to receive communication relating to the Lucky Draw. Eligible Participants' personal data will be used for contacting Eligible Participants on winner notification, identify verification for prize redemption and communication relating to the Lucky Draw.</li>
            <li>Each Eligible Participant can only participate once in this Lucky Draw. Think A Head reserves the right to remove any multiple entries that they believe, in accordance with this terms and conditions, have been created by an individual trying to gain an unfair advantage in the Lucky Draw. </li>
            <li>Directors, officers and employees of Think A Head are not allowed to participate in this Lucky Draw for fairness' sake.</li>
            <li>Prize list of the Lucky Draw and number of winner(s)</li>
            <ol type="a" style={{ marginLeft: -20 }}>
                <li>Grand prize: HK$15,000 Apple Store Gift Card (1 winner)</li>
                <li>2nd prize: HK$10,000 Apple Store Gift Card  (1 winner)</li>
                <li>3rd prize: HK$5,000 city’super Voucher (1 winner)</li>
                <li>4th prize: HK$3,000 city’super Voucher (1 winner)</li>
                <li>5th prize: HK$2,000 city’super Voucher (1 winner)</li>
                <li>6th – 200th prizes: HK$1,000 PARKnSHOP eGift Voucher (195 winners)</li>
            </ol>
            <li>A computer system arranged by Think A Head will be used to randomly generate a list of winners on 7 February 2024. Think A Head's records regarding the results of the Lucky Draw shall be final and conclusive.</li>
            <li>The list of winners (including winners' full name and the last 4 digits of their mobile number) will be announced on the MPF FUN Game's website on 16 February 2024 (Hong Kong Time), and will be published in The Standard and Sing Tao Daily newspapers on 16 February 2024 (Hong Kong Time). Notification will also be given to the individual winners via the email provided at registration of MPF Fun Game on 16 February 2024(Hong Kong Time). If, for any reason, the email to a winner bounces back or is undeliverable, or the prize is not collected within the period of time stated in the email, eligibility to receive the prize may be forfeited. Think A Head will not be liable for winners' inability to receive winning notifications or redemption details, or failure to redeem their prize for any reason, including (without limitation) any falsified, incomplete, or incorrect information provided during registration.</li>
            <li>Winners must consent that their full name and partial mobile number will be disclosed to the general public via the winner list.</li>
            <li>Upon prize redemption, winner is required to show the winning notification email and his/her valid HKID/passport for verification purpose and to sign an acknowledgement for redemption and record purposes. Otherwise, the respective winner will be disqualified without further notice.</li>
            <li>All prizes of the Lucky Draw cannot be redeemed for cash or exchanged for any other offers.</li>
            <li>If a winner does not collect the prize according to the instructions indicated in the email within a designated period for any reason, he/she will be disqualified without further notice and the prize will be considered as forfeited. </li>
            <li>It is the winners' responsibility to comply (at their own expense) with any laws requiring payment of any tax, duty, levy or similar impost relating to the award of the prize, and Think A Head shall have no responsibility in respect thereof.</li>
            <li>Think A Head's trade promotion competition licence No.: 57523-24.</li>
            <li>Think A Head has the sole and absolute discretion in determining a person's eligibility to participate in the Lucky Draw or receive the prize. If Think A Head discovers at any time, whether after or during the Promotional Period, that any person has failed to comply with these terms and conditions, Think A Head is entitled to disqualify the person from participating in the Lucky Draw and receiving the prize.</li>
            <li>Think A Head reserves the right to exclude an Eligible Participant who violates these terms and conditions, tampers with the Lucky Draw, engages in abusive, deceit or fraudulent behaviour in relation to the Lucky Draw or makes false representations or statements or violates applicable law or regulations. If an Eligible Participant is excluded, his/her prize may be subsequently revoked and reclaimed.</li>
            <li>Think A Head is not the manufacturer/supplier of the prizes under the Lucky Draw. Think A Head will not be responsible or liable for any consequence relating to the quality, supply, delivery and utilization of the prizes. Utilization of the prizes is subject to the terms and conditions imposed thereon by the individual manufacturer/supplier. Think A Head shall not be responsible or liable for whatever aspects related to the prize (including but not limited to its quality, supply, delivery and utilization). Think A Head will not be responsible or liable for any loss or damage directly or indirectly resulting from their participation of the Lucky Draw, any use or misuse of the prize.</li>
            <li>In the event of any disputes, Think A Head's decision shall be final and conclusive.</li>
            <li>In case of any discrepancy between the English and Chinese versions of the terms and conditions, the English version shall prevail.</li>
            <li>If you have any question, please email Think A Head at info@mpfgame.com.</li>
            {/* <li>Manulife shall not be responsible or liable for whatever aspects related to the Lucky Draw.</li> */}
        </ol>
    </Flex>
}

class LuckyDrawTandC extends Component {

    render() {
        const { i18n, t } = this.props;
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column" mt="1rem">
                        <Flex fontWeight={"bold"} fontSize={16} marginBottom="0.5rem">{t("UserManagement:mpfTermsAndCondition")}</Flex>
                        {i18n.language === 'en-US' ? <EngTandC t={t} /> : <CnTandC t={t} />}
                        <Flex fontSize={16} mt="0.5rem" fontWeight="bold">{t("UserManagement:luckyDrawTermsAndConditions")}</Flex>
                        {i18n.language === 'en-US' ? <EngLuckyDrawTandC /> : <CnLuckyDrawTandC />}
                    </Flex>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(withRouter(LuckyDrawTandC));